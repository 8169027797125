import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';

import { FuseSearchBarModule, FuseShortcutsModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';

import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';
import { UIIconsModule } from 'app/main/ui/icons/icons.module';
import { TranslateModule } from '@ngx-translate/core';
import { MessageIconDirective } from './message-icon.directive';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    declarations: [
        ToolbarComponent,
        MessageIconDirective
    ],
    imports     : [
        RouterModule,
        MatButtonModule,
        MatTooltipModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,
        UIIconsModule,
        FuseSharedModule,
        FuseSearchBarModule,
        FuseShortcutsModule,
        TranslateModule.forChild()
    ],
    exports     : [
        ToolbarComponent
    ]
})
export class ToolbarModule
{
}
