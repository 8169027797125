import { Injectable, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import  Swal from 'sweetalert2';
import { LoginSessionService } from '../../services/global/LoginSession.service';
import { OfflineService } from '../../services/global/Offline.service';
import { FakeDbService } from '../../fake-db/fake-db.service';


@Injectable({
  providedIn: 'root'
})
export class SessionUserService implements OnInit {
 
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  public currentUserLogged: BehaviorSubject<boolean>;
  token : string = '';
  Prefijo : string = 'Login.'
  confirmDialogRef: any;
  public messageSessionTranslate : string = '¿Desea extender su sesion?';
  public confirmButtonText: string = 'Extender sesion';
  public cancelButtonText: string = 'Cerrar sesion';
  constructor( private idle: Idle, 
               private loginSession:LoginSessionService,
               private offlineService: OfflineService,
               private fakeDbService: FakeDbService,
               private translate: TranslateService,
               private router: Router,
               private keepalive: Keepalive ) { 
    let userActive = JSON.parse(sessionStorage.getItem('userLogged')) || false;
    this.currentUserLogged = new BehaviorSubject<boolean>(userActive);
    this.token = this.loginSession.GetTokenTemp();
     // sets an idle timeout of 20 minutes, for testing purposes.
     idle.setIdle(1200);
     // sets a timeout period of 5 minutes. after 25 minutes of inactivity, the user will be considered timed out.
     idle.setTimeout(300);
     // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
     idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
 
     idle.onIdleEnd.subscribe(() => { 
       this.idleState = 'No longer idle.'
       console.log(this.idleState);
       this.reset();
     });
     
     idle.onTimeout.subscribe(() => {
       this.idleState = 'Timed out!';
       this.timedOut = true;
       console.log(this.idleState);
       this.openDailogSession();
     });
     
     idle.onIdleStart.subscribe(() => {
         this.idleState = 'You\'ve gone idle!'
         console.log(this.idleState);
         
     });
     
     idle.onTimeoutWarning.subscribe((countdown) => {
       this.idleState = 'You will time out in ' + countdown + ' seconds!'
       console.log(this.idleState);
     });
 
     // sets the ping interval to 15 seconds
     keepalive.interval(15);
 
     keepalive.onPing.subscribe(() => this.lastPing = new Date());

     this.getUserLoggedIn().subscribe(res => {
       if ( res ) {
        idle.watch()
        this.timedOut = false;
       } else {
        idle.stop();
       }
     });   
  }
  
  ngOnInit() {
    this.translateMessage();
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  stopIdle() {
    this.idle.stop();
  }

  openDailogSession() {
    let timerInterval
    Swal.fire({
      title: this.messageSessionTranslate,
      html: '<b></b>',
      timer: 21000,
      timerProgressBar: true,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: this.confirmButtonText,
      cancelButtonText: this.cancelButtonText ,
      confirmButtonColor: '#039be5',
      cancelButtonColor: '#3c4252',
      width: '46rem',
      didOpen: () => {
        const b = Swal.getHtmlContainer().querySelector('b')
        timerInterval = setInterval(() => {
          let int = Swal.getTimerLeft() / 1000;
          int = Math.round(int);
          b.textContent =  int.toString()
        }, 1000)
      },
      willClose: () => {
        clearInterval(timerInterval)
      }
    }).then((result) => {
      if ( result.isConfirmed === true) {
        console.log('extender sesion')
        this.reset();
      }
      else if( result.dismiss === Swal.DismissReason.cancel ) {
        console.log('no extender y cerrar de manera forzosa')
        this.closeSessionUser();
      }
      else if (result.dismiss === Swal.DismissReason.timer) {
        console.log('Paso el tiempo, cerrar sesion')
        this.closeSessionUser();
      }
    }).catch((Error) => { console.error(Error) })
  }

  getUserLoggedIn() {
    return this.currentUserLogged.asObservable();
  }

  closeSessionUser() {
    this.offlineService.Offline(this.token);
    sessionStorage.setItem('LogOut', 'true')
    localStorage.clear()
    sessionStorage.clear()
    this.fakeDbService.ClearAllAPI()
    this.stopIdle();
    this.router.navigateByUrl('/pages/auth/login');
  }
  
  translateMessage() {
    this.translate.get('Login.ExtenderSeccion').subscribe(res => {
      this.messageSessionTranslate = res;
    });

    this.translate.get('Login.SessionExtend').subscribe(res => {
      this.confirmButtonText = res;
    });

    this.translate.get('Login.CerrarSesion').subscribe(res => {
      this.cancelButtonText = res;
    });
  }

}
