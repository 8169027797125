import { HttpClient } from "@angular/common/http";
import { stringify } from "@angular/compiler/src/util";
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";
import { EventEmitter } from '@angular/core';

export class TecnicosFakeDB {

    public static Counting: EventEmitter<Number> = new EventEmitter();

    // Si se edita alguno de los campos aqui presente sobre todo menú
    // asi sea una mayúscula puede dañar el sistema, NO TOCAR MEJOR
    public static tecnicos = [
        {
            idCliente: '1',
            nombre: 'Jose',
            apellido: 'jose',
            cedula: '11111111',
            telef1: '424',
            telef2: '414',
            idVehiculo: '909090',
            email: 'email@email.com',
            direccion: 'avenida',
            avatar: '',
            idApp: ''
        },
        {
            idCliente: '3',
            nombre: 'Andres',
            apellido: 'andres',
            cedula: '73267632',
            telef1: '416',
            telef2: '414',
            idVehiculo: '343fgf',
            email: 'email2@gmail.com',
            direccion: 'caracas',
            avatar: '',
            idApp: ''
        },
        {
            idCliente: '5',
            nombre: 'Carlos',
            apellido: 'carlos',
            cedula: '9356726',
            telef1: '412',
            telef2: '414',
            idVehiculo: 'dff37yf',
            email: 'email4@gmail.com',
            direccion: 'caricuao',
            avatar: '',
            idApp: ''
        },
        {
            idCliente: '5',
            nombre: 'lapiz',
            apellido: 'lapiz',
            cedula: '9237892',
            telef1: '424',
            telef2: '414',
            idVehiculo: 'm347yf',
            email: 'gmail@gmail.com',
            direccion: 'zoologico',
            avatar: '',
            idApp: ''
        },
    ];

    public static vehiculos = [];

    public static contratistas = [];

    public static paramTecnicos = []
    
    public static cargarTecnicos(Token: string) {
        // Aqui se debe hacer la consulta de graphql
        /*try {
            this.Counting.emit(this.paramTecnicos.Data.length)
        } catch (error) {
            this.Counting.emit(0)
        }*/

        //return this.tecnicos
        return this.paramTecnicos
    }

    public static GetTecnicos(apollo: Apollo, Token: string, _httpClient: HttpClient) {

        let data = apollo.watchQuery<any>({
            query: ConsultaTecnicos,
            variables: {
                token: Token
            }
        })
            .valueChanges
            .subscribe((result) => {
                let DataTecnicos = result.data.Tecnicos //Guardo la data obtenida
                //console.log('DataTecnicos')

                const Datos = {

                    item: { id: 1 },
                    id: '1',
                    Config: {
                        title: 'Técnicos',
                        BottonTitle: 'Agregar Técnico'
                    },
                    tabs: false,
                    dataTabs:[],
                    displayedColumn: ['avatar','nombre', 'apellido', 'telef1', 'menu'],
                    typeData: ['avatar','string', 'string', 'string', 'menu'],
                    headShow: ['Avatar','Nombre', 'Apellido', 'Teléfono', 'Menú'],
                    menu: ["Editar/edit", "Eliminar/delete"],
                    Data: DataTecnicos

                }

                _httpClient.post('api/tecnicos/1', Datos).subscribe((response: any) => { }, (Error) => { console.error('tecnicos'); console.error(Error) });

                try {
                    this.Counting.emit(DataTecnicos.length)
                } catch (error) {
                    this.Counting.emit(0)
                }

                //Voy a llamer los vehiculos
                this.GetVehiculos(apollo, Token, _httpClient)
                //Voy a llamer los contratistas
                this.GetContratistas(apollo, Token, _httpClient)

            });

        return this.paramTecnicos

    }

    public static GetVehiculos(apollo: Apollo, Token: string, _httpClient: HttpClient) {

        let data = apollo.watchQuery<any>({
            query: ConsultaVehiculos,
            variables: {
                token: Token
            }
        })
            .valueChanges
            .subscribe((result) => {
                let DataVehiculos = result.data.Vehiculos //Guardo la data obtenida
                // console.log('DataVehiculos')
                //console.log(DataVehiculos)

                const Datos = {
                    item: { id: 1 },
                    id: '1',
                    Data: DataVehiculos
                }
                _httpClient.post('api/tecnicos-vehiculos/1', Datos).subscribe((response: any) => { }, (Error) => { console.error('tecnicos'); console.error(Error) });

            });

    }

    public static GetContratistas(apollo: Apollo, Token: string, _httpClient: HttpClient) {

        let data = apollo.watchQuery<any>({
            query: ConsultaContratistas,
            variables: {
                token: Token
            }
        })
            .valueChanges
            .subscribe((result) => {
                let DataContratistas = result.data.Contratistas //Guardo la data obtenida
                // console.log('DataContratistas')
                //console.log(DataContratistas)

                const Datos = {
                    item: { id: 1 },
                    id: '1',
                    Data: DataContratistas
                }
                _httpClient.post('api/tecnicos-contratista/1', Datos).subscribe((response: any) => { }, (Error) => { console.error('tecnicos'); console.error(Error) });
            });

    }


}

export const ConsultaTecnicos = gql`
query Tecnicos($token:String) {
    Tecnicos( token: $token )
    {
        idTecnico
        idContratista
        idCliente
        idEmpresa
        idApp
        nombre
        apellido
        avatar
        cedula
        telef1
        telef2
        idVehiculo
        email
        direccion
        VencimientoLic
    }
  }
`;

export const ConsultaVehiculos = gql`
query Vehiculos($token:String) {
    Vehiculos( token: $token )
    {
        idVehiculo
        idContratista
        idCliente
        idEmpresa
        vehiculo
        marca
        modelo
        color
        placa
        Tipo
        PolizaSeguro
    }
  }
`;

export const ConsultaContratistas = gql`
query Contratistas($token:String) {
    Contratistas( token:$token )
    {
        idContratista
        idCliente
        idEmpresa
        nombre
        rif
        telef
        personaContacto
        email
        Direccion
    }
  }
`;