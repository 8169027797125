export const locale = {
    lang: 'pr',
    data: {
        'NAV': {
            'APLICACIÓN': 'Aplicativo',
            'DASHBOARDS'  : 'Começar',
            'Control' : 'Controle',
            'ADMINISTRACION' : 'Administração',
            'INTEGRADORES' : 'Integradores',
            'CUSTOMFUNCTION' : 'Função Personalizada',
            'CUSTOMIZE' : 'Personalizar',
            'CALENDAR'    : 'Campaña',
            'ECOMMERCE'   : 'E-Commerce',
            'MAIL'        : {
                'TITLE': 'Mail',
                'BADGE': '25'
            },
            'MAIL_NGRX'        : {
                'TITLE': 'Mail Ngrx',
                'BADGE': '13'
            },

            'CHAT'        : 'Chat',
            'Cliente' : 'Cliente',
            'Agentes' : 'Agentes',
            'FILE_MANAGER': 'Galeria',
            'Configuracion' : 'Configuração',
            'Preguntas' : 'Perguntas',
            'CONTACTS'    : 'Contatos',
            'Novedades' : 'Novidades',
            'Entrenamiento' : 'Treinamento',
            'TODO'        : 'Tudo ',
            'REPORTES'  : 'Relatórios'
        }, 
        Reportes: {
            'Clientes': 'Clientes',
            'Contactos': 'Contatos',
            'Search':'Procure por um',
            'Nombre': 'Nome',
            'Avater': 'Logotipo',
            'Agentes': 'Agentes',
            'Estatus': 'Status',
            'Cola': 'Em espera',
            'Menú': 'Cardápio',
            'Agregar':'Adicionar',


            'avatar': 'Foto',
            'Avatar': 'Foto',
            'name': 'Nome',
            'phone': 'Telefone',
            'Canal': 'Canal',
            'menú': 'Cardápio',

            'Roles': 'Função',
            'Rol': 'Permissões',
            'Descripcion': 'Descrição',
            'Acción': 'Açao',

            'url' : 'Url',
            'title' : 'Título',
            'owner' : 'Proprietário',
            'modified' : 'Modificado',
            'menu' : 'Cardápio',

            'Galeria' : 'Galeria',
            'Enter': 'Press intro',
            'Exportar' : 'Exportar',
        
            'Técnicos' : 'Técnicos',
            'Apellido' : 'Sobrenome',
            'Teléfono' : 'Telefone',

            'BusquedaAvanzada': 'Pesquisa Avançada',
            'Contacto': 'Contato',
            'TextoDelMensaje': 'Texto da mensagem',
            'TipoDeMensaje': 'Tipo de mensagem',
            'Telefono': 'Telefone',
            'Etiqueta': 'Rótulo',
            'FechaDeIncio': 'Data de início',
            'FechaDeFin': 'End Date',
            'Entrada': 'Entrada',
            'Salida': 'Saída',
            'Todos' : 'Tudo',
            'Generador': 'Gerador',
            'Reportes': 'Relatórios',
            'Buscar' : 'Procurar',
            'Realizar nueva búsqueda':'Realizar uma nova pesquisa',
            'Encuestas' : 'Pesquisas',
            'TipoDeEncuesta' : 'Tipo de pesquisa',
            'EncuestasAgentes' : 'Pesquisas conduzidas por bot e agentes.',
            'ReporteAgente': 'Relatório filtrado de todos os clientes por agente',

            'Texto': 'Texto',
            'Imagen': 'Imagem',
            'Video': 'Vídeo',
            'Audio': 'Áudio',  
            'PDF': 'PDF',  
            'Cierre': 'Fechamento',
            'Transferir': 'Transferir',
            'Sticker': 'Adesivo',

            'Consulta' : 'A consulta deve durar no máximo 2 meses',
            'CanalBusqueda' : 'Você deve fornecer um canal com o qual você deve pesquisar',

            'Ciudad': 'Ciudad',
            'Email': 'Email',
            'Bravery Rating':'Bravery Rating',
            'Fecha': 'Fecha',
            'First name': 'First name',
            'Michael':'Michael',

            'Indicadores':'Indicadores',
            'Rendimiento':'Desempenho',
            'ConteRendi':'Por meio de indicadores você pode realizar um controle do desempenho dos Agentes',
            'Tiempos':'Horas',
            'ConteTiempos':'Você poderá ver o tempo de conexão dos agentes.',
            'Bots':'FibexApp',
            'ConteBots':'Relatório dos usuários Fibex que representam o aplicativo móvel.',
            'Fecha Inicial':'Data de início',
            'Fecha Final':'Data de término',
            'Licencias':'Licenças',
            'Etiquetas': 'Rótulos',
            'Totalmensajes': 'Total de mensagens'
        },
        ReportList:  {
            'Agentes': 'Agentes',
            'Atajos': 'Atalhos',
            'Abreviado': 'Abreviado',
            'Texto': 'Texto',
            'Canal': 'Canal',
            'Menú': 'Cardápio',
            'Fecha' :'Date',
            'Móvil' : 'Telefone',

            'Etiqueta':'Etiqueta',
            'Operador':'Operador',
            'Total':'Total',

            'Campos': 'Campos',
            'Campo': 'Campo',
            'Tipo': 'Gentil',
            'Requerido': 'Obrigatório',
            'Ubicación': 'localização',

            'Etiquetas': 'Rótulos',

            'Horarios': 'Cronograma',
            'Descripcion': 'Descrição',
            'Jornadas': 'Viagens',
            'DiaInicio': 'Começo',
            'DiaFin': 'Fim',
            'Excepciones': 'Exceções',

            'Contactos': 'Contatos',

            'Bot': 'Robô',


            'Avatar': 'Foto',
            'Nombre': 'Nome',
            'Cola': 'Cola',
            'Online': 'Conectado',
            

            'Mensajes': 'Mensagens',
            'Contacto': 'Contato',
            'Cuenta': 'Conta',
            'Fecha Inicio': 'Date / Começo ',
            'FechaDeFin': 'Data final',
            'Entrante': 'Entrada',
            'Saliente': 'Saída',
            'Requerimiento': 'Requisito',
            'AgentesExclusivos': 'Agentes exclusivos',

            'Transferir': 'Transferir',
            'Ultima conexion': 'Última conexão',

            'Exportar' : 'Exportar',
            'Agentes exclusivos' :  'Agentes exclusivos',
            'Telefono':'Telefone',
            'Mensaje':'mensagem',

            'Reporte': 'Relatórios',
            'TipoContenido': 'Tipo de conteúdo',
            'Contenido': 'Conteúdo',

            'nombre': 'nome',
            'tipoDeFalla': 'Tipo de falha',
            'banco': 'Banco',
            'tipoDeUsuario': 'Tipo de usuário',
            'imagenes': 'Imagens',
            'procesado': 'Processado',
            'phone': 'Telefone',
            'Filtro' : 'Filtro',
            'Mesajes masivos' : 'Mensagens em massa',
            'Totalmensajes': 'Total de mensagens'


        },
         Generico: {
            'Siguiente': 'Segue',
            'SIGUIENTE': 'SEGUE',
            'Seleccionar': 'Selecionar',
            'Atras': 'Atrás',
            'CANCELAR': 'CANCELAR',
            'AGREGAR': 'ADICIONAR',
            'Aceptar': 'Aceitar',
            'GUARDAR': 'SALVE',
            'Cargandoo' : 'CARREGANDO',
            'CrearHorario' : 'CRIAR CRONOGRAMA',
            'Anterior': 'Anterior',
            'CrearUsuario': 'Criar Usuário',
            'GuardarCambios' : 'Guardar Mudanças',
            'Mensajes': 'Mensagens',
            'Agentes': 'Agentes',
            'WelcomeBack': 'Bem vindo de volta,',
            'Home': 'Começar',
            'Delete' : 'Excluir',
            'Add': 'Adicionar',
            'Save' : 'Salve ',
            'Guardar': 'Salve',
            'Agregar' : 'Adicionar',
            'Vincular' : 'Link',
            'Desvincular' : 'Desvincular',
            'ActualizarDatos' : 'Dados de atualização',
            'Eliminar' : 'Livrar-se de',
            'Back' : 'Voltar',
            'Reset' : 'Redefinir',
            'Cancelar': 'Cancelar',
            'Details' : 'Detalhes',
            'Asignar' : 'Atribuir',
            'Cargando': ' CARREGANDO...',
            'NoEncontroDatos' : 'Nenhum dado encontrado.',
            'RemoveList' : 'Apagar lista',
            'Cerrar' : 'Encerrado',
            'SelectAll' : 'Selecionar tudo',
            'DeselectAll' : 'Desmarcar todos',
            'De' : ' de ',
            'Confirmar' : 'Confirme',
            'Days' : 'Dias',
            'Hours' : 'Horas',
            'Minutes' : 'Minutos',
            'Seconds' : 'Segundos',
            'Ayer' : 'Ontem',
            'Hoy' : 'Hoje ',
            
            'Editar' : 'Editar',
            'Chat ' : 'Chat',
            'Chat' : 'Chat',
            'Assign' : 'ATRIBUIR',
            'CambiarClave' : 'Alterar senha',

            'Ver' : 'Olhe ',
            'Cambiar Estado' : 'Alterar status',
            'Atendiendo' : 'Atendendo', 
            'Reasignar' : 'Reatribuir',
            'edit' : 'Editar',
            'delete' : 'Excluir',
            'vpn_key' : 'Mudar senha',
            'ConoceMas' : 'Saiba mais',
            'Ver conversacion' : 'Ver conversa',
            'Ver contenido' : 'Exibir conteúdo',
            'Visualizar' : 'Ver',
            'Escanear' : 'Scan QR'

        },
        DatosClientes: {
            'AjustesDeLaCuenta' : 'Configurações da conta',
            'NuevaCliente' : 'Novo cliente',
            'BasicInfo' : ' Informação Básica',
            'Canales' : 'Canais',
            'Galeria' : 'Galeria',
            'Contactos' : 'Contatos',
            'Horarios' : 'Horários',
            'Etiquetas' : 'Rótulos',
            'Campos' : 'Campos',
            'Atajos' : 'Atalhos',
            'NameEmp': 'Nome de Empresa',
            'Rif': 'Rif',
            'Email': 'Correio eletrônico',
            'Phone': 'Telefone',
            'Prefijo' : 'Prefixo',
            'Code' : 'Código',
            'Direction': 'Direção',
            'PaymetMethod': 'Forma de Pagamento',
            'MensajeAdicional' : 'Mensagem Adicional',

            'Datos' : 'Dados salvos!',
            'DatosGuardados': 'Dados salvos, saia e entre novamente para atualizar as alterações',
            'ClienteAgregado': 'Cliente Adicionado',
            'Error' : 'Erro',
            'ArchivoGuardado' : 'Arquivo salvo',
            'ErrorArchivo' : 'Erro de upload de arquivo',
            'Eliminar' : 'Você deseja excluir ha',

        },
        Canales: {
            'ChannelType':'Tipos de Canais',
            'MultiOperador' : 'Multi-operador',
            'CtrlWhatsApp':'Controle de Multi Agentes WhatsApp',
            'CtrlFacebook':'Tipo de Canal',
            'NoDisponible':'Não disponível',
            'SignalAgen':'Sinal Multi Agents',
            'TelegAgen':'Telegram Multi Agents',
            'ScannQR': 'Scanner',

            'Telegram' : 'Telegram',
            'Facebook' : 'Facebook',
            'Whatsapp' : 'Whatsapp',
            'Signal' : 'Signal',
            
            'ConfDeMensaj':'Configurações de Mensagem',
            'LastnameFirstname' :'Nome, Sobrenome',
            'MensajDeBienve': 'Mensagem de Boas-vindas',
            'MensajDelContacto': 'Mensagem para solicitar nome e sobrenome do contato', 
            'MensajCorreo':'Mensagem para solicitar e-mail de contato',
            'MensajDatos' :'Mensagem para indicar que os dados completos foram salvos',
            'MensajAgentes': 'Mensagem para indicar a atribuição de um agente à conversa',
            'MensajConvers': 'Mensagem para indicar o fim da conversa',
            'MensajDisponi' : ' Mensagem para indicar que não há Agentes Disponíveis',
            'MensajAlerta' : 'Mensagem de alerta para contato quando for detectada inatividade na conversa',
            'MensajFinaliz': 'Mensagem para indicar que a conversa será encerrada devido à inatividade do contato',
            'MensajArchivo': 'Mensagem para avisar ao contato que não é possível receber o arquivo',
            'MensajLlamada':'Mensagem em caso de chamadas',

            'PermiDelCanal' : 'Autorizações de canal',
            'PermiConvers'  : 'Permitir iniciar uma conversa com contatos desconhecidos',
            'PermiNotif' : 'Permitir envio de notificações',
            'PermiDifusiones' : 'Permitir o envio de transmissões (Saída)',
            'EnviarMensaj': 'Send unavailability message after each message',
            'RecibirConvers' : 'Receba conversas em grupo',
            'PermiActuali' : 'Permitir atualização de status de mensagem',
            'PermiArchivos': 'Permitir arquivos',
            'BloqueVideos' : 'Bloquear vídeos',
            'MensajVideo' : 'Mensagem para indicar que não é possível receber os arquivos de Vídeo',
            'PermitirAudios' : 'Permitir áudios            ',
            'PermitirImágenes' : 'Permitir imagens',

            'Done' : 'Finalizado',
            'YouAreNowDone' : 'Acabou.',


        },
        ListCanales: {
            'Vincular' : 'Emparelhar ',
            'Desvincular' : 'Desligar',
            'ActualizarDatos' : 'Atualizar Dados',
            'Eliminar' : 'Livrar-se de',
            'Bateria' : 'Bateria',
            "NroDeTeléfono" : "Número de Telefone",
            'VerWhatsapp': 'Versão Whatsapp',
            'TotalDeMensaje' : 'Mensagem Total',
            'Online' : 'Conectado',
            'Status' : 'Status',
            'UltimaConexion': 'Última Conexão',  
        },
        SelectCanales : {
            'Canal' : 'Canal', 
            'Canales' : 'Canais',
            'Todos' : '-- TUDO --',
        },
        Campos: {
            'Campo' : 'Campo',
            'Tipo' : 'Gentil',
            'Ubicacion' : 'Localização',
            'DeseasEliminar' : 'Você deseja excluir ',
            'PerderDatosCapturados' : ' Os dados já capturados serão perdidos!',
            'Nuevo_Campo' :   'Novo Campo',
            'Editar_Campo': 'Campo De Edição',


            
            'Cadenas de Caracteres': 'Cadeia de caracteres',
            'Correo': 'E-mail',
            "Número" : 'Número',
            'Teléfono': 'Telefone',
            'Fecha' :'Data',
            'Nombre' : 'Nome',
            "Cédula/DNI" : "Cédula/DNI",
            "Tarjeta de Credito" : "Tarjeta de Crédito",
            'Entrada': 'Entrada',
            'Texto' : 'Texto',
            'Texto largo' : 'Texto longo',
            'Numerico': 'Numérico',


            'Contactos' : 'Contatos',
            'Chats' : 'Chats',
            'Citas' :  'Compromissos',
            'Cierre' : 'Fechamento',

            'Requerido' : 'É obrigatório.',
            'ON' : 'LIGADO',
            'OFF' : 'DESLIGADO',

            'ErrorCampo01:' : 'Erro ao salvar Field01: ', 
            'ErrorCampo02:' : 'Erro ao salvar Field02: ',
            'Agregado' : 'Campo Adicionado',
            'ErrorAgreganCampo01' : 'Erro ao adicionar Field01',
            'ErrorAgreganCampo02' : 'Erro ao adicionar Field01',
            'Otro':'Outro',
           
        },
        Atajos: {
            'Abreviado' : 'Abreviado',
            'texto' : 'Texto',
            'Canales' : 'Canais',
            'Ubicacion' : 'Localização',
            'Seleccione' : 'Selecione...',
            'Duplicado': 'Item duplicado, você deve usar outro atalho para abreviar',
            'DeseasEliminar' : 'Você deseja excluir ',
            'Chat' : 'Chat',
            'CierreDeCaso' : 'Encerramento de Caso',
            'NuevoAtajo' : 'Novo Atalho',
            'EditarAtajo' : 'Editar atalho',
            'AtajoCreado' : 'Atalho Criado',
            'GuardarCambios': 'As alterações foram salvas, mas não foi possível atualizar a saída do sistema e entrar novamente',
            'AtajoEditado' : 'Atalho editado',
            'ErrorEditando' : 'EDIÇÃO DE ERRO:',

            

        },
        Etiquetas : {
            'Etiquetas': 'Rótulos',
            'EditLabel' : 'Editar rótulo ',
            'AddLabel' : 'Adicionar rótulo' ,
            'AddNew':'ADDICIONAR NOVO RÓTULO',

            'RemoveDueDate':'Excluir data de validade',
            'AddChecklist' : 'Adicionar lista de verificação',
            'Unsubscribe' : 'Cancelar inscrição',
            'Subscribe' : 'Inscrever-se',
            'RemoveCard' : 'Remover cartão',
            'Title' : 'Título',
            'DueDate' : 'Data de vencimento',
            'Description' : 'Descrição',
            'Members' : 'Membros',
            'Attachments' : 'Anexos',
            'MakeCover' : 'Faça a cobertura',
            'RemoveCover' : 'Remover a tampa',
            'RemoveAttachment' : 'Excluir anexo',
            'Link' : 'LINK',
            'AddAttachment' : 'Adicionar um anexo',
            'RemoveChecklist' : 'Excluir lista de verificação',
            'Comments' : 'Comentários',
            'Comment' : 'Comentário',
            'Activity' : 'Atividade',

            'AddCard' : 'Adicionar um cartão',

            'EtiquetaGuardada' : 'Tag salva',
            'ErrorEditando' : 'Erro de edição:',
            'EtiquetaAgregada' : 'Etiqueta adicionada',
            'GuardarCambios': 'As alterações foram salvas, mas não foi possível atualizar a saída do sistema e entrar novamente',
            'ErrorGuardando' : 'Erro ao salvar',
            'DeseasEliminar' : 'Você deseja excluir ',

            'NuevaEtiqueta' : 'Novo Rótulo',
            'EditarEtiqueta' : 'Editar Rótulo',
            'EliminarTarjeta' : 'Tem certeza de que deseja excluir o cartão?',
            'EliminarLista' : 'Tem certeza de que deseja excluir a lista e todos os cartões?'

        },
         Horarios: {
            'Horarios' : 'Horários',
            'Excepciones' : 'Exceções',
            'DescripJorna' : 'Descrição do dia',
            'DiaInicio' : 'Dia de início',
            'DiaFin' : 'Dia de Fim', 
            'HoraInicio' : 'Hora de início',
            'HoraFin' : 'Fim do tempo',
            'Descripcion' : 'Descrição',
            'SeleccioneDia' : 'Selecione um dia',
            'SeleccioneLaFecha' : 'Selecione um data',
            'DescripcionEx': 'Insira uma descrição da exceção',

            'HorarioCreado' : 'Schedule Criada',
            'GuardarCambios' : 'Foi possível salvar as alterações no Banco de Dados, mas não foi possível atualizar a saída do sistema e entrar novamente',
            'ErrorHorario': 'ERRO CRIANDO CALENDÁRIO:',
            'HorarioActualizado' : 'Cronograma atualizado',
            'ErrorActualizando': 'ERRO ATUALIZANDO CALENDÁRIO:',
            'Error' : 'Erro',

            'DeseasEliminar' : 'Você deseja excluir ha',
            'HorarioEliminado' : 'Programação excluída',
            'HorariosElimidado': 'Agendamento excluído do banco de dados, mas não pode ser excluído da memória, saia e insira novamente ERROR:',

            "Lunes" : "Segunda-feira",
            "Martes" :  "Terça-feira",
            "Miercoles":  "Quarta-feira",
            "Jueves": "Quinta-feira",
            "Viernes":  "Sexta-feira",
            "Sábado": "Sábado",
            "Domingo" : "Domingo",

            'Enero' : 'Janeiro',
            'Febrero' : 'Fevereiro',
            'Marzo' : 'Março',
            'Abril' : 'Abril',
            'Mayo' : 'maio',
            'Junio' : 'Junho',
            'Julio' : 'Julho',
            'Agosto' : 'Agosto',
            'Septiembre' : 'Setembro',
            'Octubre' : 'Outubro',
            'Noviembre' : 'Novembro',
            'Diciembre' : 'Dezembro',

            'HoraDeInicio' : 'A hora de início é igual à hora de término, insira uma hora correta.',
            'HoraFinal' : 'A hora de início é maior que a hora de término, digite uma hora correta.',

           
        },
        Agent : {
            'DatosPersonales' : 'Dados Pessoais',
            'Nombre' : 'Nome',
            'Apellido' : 'Sobrenome',
            'Email' : 'Email',
            'Contrasena' : 'Senha',
            'Telefono' : 'Telefone',
            'Direccion' : 'Direção',
            'HorariosPermisos' : 'Horários / Permissões',
            'Canales' : 'Canais',
            'Horarios' : 'Horários',
            'TipoDeUsuario' : 'Tipo de usuário',
            'RolDelUsuario' : 'Papel do usuário',
            'AnadirNuevoRol' : 'Adicionar nova função',
            'Agentes' : 'Agentes',
            'Ajustes' : 'Definições',
            'Agente':'Agente',
            'Clientes':'Clientes',
            'Administrador':'Administrador',
            'EmailDuplicado':'Email duplicado, você deve usar outro email',
            'Master' : 'Master',

            'CambioDeClave' : 'Mudança de chave',
            'ElPassword': 'A senha atual está incorreta',
            'PasswordActual' : 'Senha real',
            'NuevoPassword' : 'Nova senha',
            'PasswordRequired' :  'Senha requerida',
            'ConfirmarPassword' : 'Confirmar senha', 
            'PasswordCoinciden' : 'Senha não corresponde',
            'data.Nombre' : 'Agente',

            'AsignacionHorario' : 'Atribuição de agenda do (s) agente (s)',

            'EmailExistente':'O e-mail já existe, por favor adicione outro',
            'UserAgregado' : 'Usuário dicionado',
            'UserEditado' : 'Usuário editado',

            'CambioClave' : 'A alteração da senha foi realizada com sucesso',
            'ErrorClave': 'ERRO AO ALTERAR A SENHA',
            'Eliminar' : 'Você quer excluir ha',

            'DeseasColocar': 'Você quer colocar',
            'EstadoActualizado': 'Status do agente atualizado',
            'Error' : 'Erro',
            'Seleccione' : 'Selecione...',
            'SeleccioneRol' : 'Selecionar função ...',
            'SeccionRequerida': 'Esta seção é obrigatória.',
            'TransferirConversaciones': 'Você pode transferir conversas?',
            'Todos' : 'Tudo',
            'HorarioAgregado': 'Programação adicionada ao (s) agente (s)',
            'SeleccioneAgente' : 'Selecione um agente que não tenha o agendamento',

            'BuscandoMensajes': 'Procurando por mensagens',
            'SinMensajes' : ' você está sem mensagens',
            'ChatAsignado' : 'Chat Atribuído',
            'SinMensajess' : 'Sem mensagens',
            'EnviarMensajes' : 'Enviar mensagens',
            'MostrarAgentes':'Mostrar Agentes'
        },
        tRoles : {
            'Rol' : 'Função',
            'domain' : 'Domínio',
            'Descripcion' : 'Descrição',
            'Permisos' : 'Permissões',
            'Usuarios' : 'Usuários',
            
        },
        ContactoForm : {
            'Name' : 'Nome',
            'Lastname' : 'Sobrenome',
            'CodeID' : 'Código /ID',
            'PhoneNumber' : 'Número de Telefone',
            'Email' : 'Correio eletrônico',
            'Company' : 'Empresa',
            'Birthday' : 'Aniversário',
            'Address' : 'Direção',
            'Notes' : 'Notas',
            'Contacto' : 'Contato',
            'Fecha' : 'Data',
            'Telefono' : 'Telefono',
            'Code' : 'Código',
            'Extra' : 'Extra',
            'Categoria' : 'Categoria',
            'Informacion' : 'Em formação',
            'CamposAdic' : 'Campo Adicional.',
            'JobTitle' : 'Titulo profissional',
            'Remove' : 'Remover',

            'ContactInfo2' : 'Dados de contato',
            'Mood' : 'Humor',
 
            'EliminarContacto': 'Tem certeza de que deseja excluir todos os contatos selecionados?',
            'Eliminar' : 'Você quer excluir ha',
            'ListaNegra' : 'Você deseja enviar para a lista negra ha',
            'ContactoAgregado' : 'Contato adicionado',
            'ErrorContacto': 'Erro ao criar contato ',
            'NuevoContacto' : 'Novo contato',
            'EditarContacto' : 'Editar contato',
            'ContactoEditado' : 'Contato editado',

            'ContactoEliminado' : 'Contato excluído',
            'NoEliminarContacto': 'Não é possível excluir o contato',
            
            'EditarImagen' : 'Editar imagem',
            'GuardadoExito' : 'Salvo com sucesso',
        },
        Chat : {
            'Chat' : 'Chat',
            'Select' : 'Selecione um contato para iniciar um bate-papo!',
            'ContactInfo' : 'Informação de contato',
            'YourBrowser':'Seu navegador não suporta a tag de vídeo.',

            'Mood' : 'Humor',
            'Online' : 'Conectados',
            'Away' : 'Longe',
            'DoNotDisturb' : 'Não perturbe',
            'Offline' : 'Desligada',
            'Profile' : 'Perfil',
            'Logout' : 'Fechar sessão',
            'Chats' : 'Conversar',
            'SearchChat' : 'Pesquise ou inicie um novo bate-papo',
            'Contacts' : 'Contatos',
            'NoResults' : 'Não há resultados..',

            'InfoContacto' : 'Contato de Informação',
            'CerrarCaso'  :  'Caso encerrado',
            'ConsultarSaldo' : 'Verifique o saldo',
            
            'CierreDelCaso' : 'Encerramento de caso ',
            'MotivoDelCaso' : 'Razão do caso',
            'ConsultaDeSaldo' : 'Consulta de Saldo',
            'Reclamo' : 'Reivindicar',
            'ReporteDePago' : 'Relatório de pagamento',
            'Otro' : 'Outro',
            'Resumen' : 'Resumo',
            'ClienteProspecto' : 'Cliente em potencial',
            'PromocionesEspeciales' : 'Vendas especiais',
            'ReporteProblemaAdministrativo' : 'Relatório de Problema Administrativo',
            'ReporteServicioTécnico' : 'Relatório de serviço técnico',
            'SolicitaInformacionDeServicio' : 'Solicitar informações sobre o serviço',
        

            'Consulta' : 'Consulta',
            'Respuesta' : 'Responder',
            'EnviarMensaje' : 'Enviar mensagem',

            'Cargar' : 'Carregar histórico...',
            'Buscar' : 'Procurar...',
            'AudioElement': 'Seu navegador não suporta o elemento de áudio.',
            'Type' : 'Escreva sua mensagem',
            'SeleccionarEtiqueta' : 'Selecionar Rótulo',
            'Emoji' : 'Emoji selecionado',

            'MensajesAtender' : 'Mensagens para Atender: ',
            'MensajesCargados' : 'Mensagens Carregadas  7 / ',
            'Servidor' :  'Servidor 7',

            'EnviarEncuestaRapida' : 'Enviar Pesquisa Rápida',
            'Titulo' : 'Título',
            'Descripcion' : 'Descrição',
            'MensajeAdic' : 'Mensagem Adicional',
            'VistaPrevia' : 'Pré-visualizar',

            'Seleccione' : 'Selecione...',
            'CasoExito ' : 'Caso encerrado com sucesso',
            'CambiandoEtiqueta' : 'O rótulo foi alterado com sucesso',
            'ErrorEtiqueta' : 'Erro ao alterar o rótulo',
            'HistorialDeContacto' : 'Nenhum histórico encontrado para este contato',

            'EnAtencion' : 'En atención:',
            'ClientesEspera' : 'Clientes em espera',
        },
        Galeria : {
            'Name' : 'Nome',
            'Type' : 'Tipo',
            'Owner' : 'Proprietário',
            'Size' : 'Tamanho',
            'Created' : 'Criado',
            'Info' : 'Información',
            'Asignar' : 'Atribuir a:',

            'EnviarImagen' : 'Enviar imagem',
            'Descripcion' : 'Descrição',
            'Galeria' : 'Galeria',

            'Ninguna' : 'Nenhum',
            'Enviar' : 'Mandar',
            'AdjuntarImagen' : 'Anexar imagem',
            'Descargar' : 'Descargar',

            'ImagenDisponible' : 'Imagem disponível:',
            'Titulo' : 'Titulo',
            'Informacion' : 'Informação:',
            'Tamaño' : 'Tamanho',
            'Asignado' : 'Atribuído',
            'Creado' : 'Criado',
            'Modificado' : 'Modificado',
            'MensajeAdicional' : 'Mensagem Adicional',

            'AddList':'Adicionar uma lista',
            'NombreDeImagen' : 'Nome da imagem',

            'NuevaImagen': 'Adicionar: Nova Imagem',
            'IngreseImagen' : 'Digite o nome da imagem...',
            'Seleccione' : 'Selecione...',
            'Cambiar': 'Alterar',
          

        },
        Configuracion: {
            'Administración' : 'Administración',

            'Settings' : 'Configurações',
            'BoardColor' : 'Cor da placa',
            'CardCoverImages' : 'Imagens da capa do cartão',
            'Subscribe' : 'Inscrever-se',
            'CopyBoard' : 'Placa de cópia',
            'DeleteBoard' : 'Excluir placa',
            'Background Color' : 'Cor de fundo',
            
            'Boards' : 'PRANCHAS',
            'ProyectosApp' : 'Projetos de aplicativos',
            'AddNewBoard':'Adicionar nova placa ',

        },
        Bot : {
            'NuevaRespuestaDelBOT' : 'Nova Resposta BOT',
         },
         tPreguntas : {

            'question': 'Como criar rótulos?',
            'answer': '<p>Entrar Clientes >> Selecione a empresa >> na barra superior escolha a opção de rótulos >> você clicará no ícone azul (+) >> uma janela chamada Novo rótulo se abrirá conforme mostrado na imagem você digitará o nome que e o canal e, em seguida, prossiga para ADICIONAR.\n</p> <img src="/assets/img/examples/Etiqueta.PNG" alt="">',
          
            'question0': 'Como adiciono imagens à galeria?',
            'answer0': '<p>Você entra na Galeria >> No canto superior direito você encontrará um botão com o nome de ADICIONAR, clique nele e será exibida uma janela conforme mostrado na imagem, no campo Atribuir você seleciona a Empresa, em seguida, clique em Adicionar e Você vai abrir a janela do seu computador e você seleciona uma imagem, uma vez selecionada clica para salvar as alterações.\n</p> <img src="/assets/img/examples/Galeria.jpg" alt="">',
          
            'question1': 'Como adicionar uma agenda de agente?',
            'answer1': '<p>Entrar Clientes >> Selecione a empresa, na barra superior escolha a opção Horário, você clicará no ícone com o símbolo (+) e abrirá uma janela conforme mostrado na imagem, em Horário>> Descrição do dia útil, coloque uma breve descrição >> Em Dia de início deve-se selecionar o dia da semana em que o agente iniciou seu dia de trabalho e em horário de início e término deve-se inserir a programação do Agente em horário militar exemplo (08:00 da manhã / 20: 00h). \n </p> <img src="/assets/img/examples/horarios.jpg" alt="">',
          
           'question2': 'Como adicionar e editar agentes?',
           'answer2' : '<p> Selecione Agentes >> No canto superior direito você encontrará um botão (ADICIONAR) que mostrará uma janela onde aparecerá:</p> \n <p> 1 - Dados pessoais: Nome >> Sobrenome >> Email >> Senha >> Telefone >> Endereço. Conforme mostrado na primeira foto. Preencha todos os campos com os dados corretos e pressione PRÓXIMO. </p> <img src="/assets/img/examples/agentes1.jpg" alt="">\n <p> Basta preencher os campos conforme aparecerão na segunda imagem </p> \n <p>2- Programações / Permissões: Clientes >> Canais >> Programações >> Tipo de usuário >> Função do usuário. Em seguida prossiga para CRIAR USUÁRIO.</p>\n <img src="/assets/img/examples/agentes2.jpg" alt=""> \n <p>Para Editar o Agente vá até os 3 pontos (...) que ficam ao lado de cada agente, várias opções irão aparecer, clique em Editar então aparecerá a mesma janela mostrada na imagem, mas com o informações do Agente que você edita, dá PRÓXIMO e Salve as alterações.</p>',
          
            'question3': 'Como criar atalhos ou respostas rápidas?',
            'answer3': '<p>Entrar Clientes >> Selecione a empresa, na barra superior escolha a opção Atalhos, você clicará no ícone (+) abrirá uma janela chamada Novo Atalho conforme mostrado na imagem, você digitará a Abreviatura , o Texto, os Canais e a localização. Em seguida, prossiga para WAIT. \n<p> <img src="/assets/img/examples/Atajos.jpg" alt="">',
          
            'question4': 'Como alterar a senha de um Agente?',
            'answer4': '<p> Selecione Agentes >> Ao lado de cada Agente você encontrará 3 pontos (...) aparecerá a opção de Alterar Senha, entre e será exibida uma janela conforme mostrado na imagem onde você pode alterar a senha de o agente selecionado. \n</p> <img src="/assets/img/examples/CambioClave.jpg" alt="">',
          
            'question5': 'Como adicionar e editar contato?',
            'answer5': '<p>1 - Para adicionar: Contatos >> No canto superior direito você encontrará um botão (ADICIONAR) ao clicar nele irá exibir uma janela chamada Novo Contato, como você pode ver na imagem, preencha todos os campos e Clique no botão (ADICIONAR) no final dos campos.\n</p> <img src="/assets/img/examples/Contactos1.jpg" alt=""> \n<p>2 - Editar: conforme mostrado na imagem você vai aos 3 pontos (...) que fica ao lado de cada contato, a opção de Editar vai aparecer, você a seleciona, modifica o campo e clica no botão editar. \n</p><img src="/assets/img/examples/Contactos2.jpg.png" alt="">',
          
            'question6': 'Como adicionar e editar informações básicas do cliente?',
            'answer6': '<p> 1 - Para adicionar: Você vai para Clientes >> No canto superior direito você encontrará o botão adicionar, clique nele e será exibida uma tela na qual irá pedir as informações básicas do cliente que deseja adicionar . \n</p> <img src="/assets/img/examples/Cliente1.jpg" alt=""> <p> 2 - Editar: Clientes >> como mostrado na imagem você vai para os 3 pontos (...) que está localizado ao lado de cada empresa, a opção de Editar aparecerá, você seleciona, modifica o campo e clica no botão SALVAR. \n </p> <img src="/assets/img/examples/Cliente2.jpg" alt="" >',
          
            'question7': 'Como adicionar e editar um novo contato do cliente?',
            'answer7': '<p>1 - Para Adicionar: Vá para Clientes >> Selecione a Empresa >> Na barra superior escolha a opção Contatos, você irá clicar no ícone com o símbolo azul (+), uma janela semelhante se abrirá conforme mostrado na imagem, Novos Contatos preenchem todos os campos e no final você encontrará um botão Adicionar ou Cancelar, ao adicionar seu contato será salvo.\n</p> <img src="/assets/img/examples/CienteContacto1.jpg.png" alt=""> \n<p>2 - Editar: Como mostra a segunda imagem, no Menu você encontrará 3 linhas que fica ao lado de cada contato, aparecerá a opção Editar, selecione, modifique o campo e clique no botão Salvar. \n</p> <img src="/assets/img/examples/ClienteContacto2.jpg.png" alt="">',
          
            'question8': 'Como adicionar e editar a função de um agente?',
            'answer8': '<p> 1 - Para adicionar: Vá para Funções >> No canto superior direito você encontrará o botão Adicionar, clique e aparecerá uma visualização como pode ser visto na imagem com os campos de nome, a descrição, permissões e Comercial. Em seguida, ele apenas clica em SALVAR.\n</p> <img src="/assets/img/examples/Roles1.jpg " alt=""> \n<p> 2 - Editar: conforme mostrado na imagem você vai aos 3 pontos (...) que ficam ao lado de cada Função do Agente, aparecerá a opção Editar, você a seleciona, modifica o campo e clica no botão SALVAR. \n </p> <img src="/assets/img/examples/Roles2.jpg " alt=""> ' ,
          
            'question9': 'Como usar os atalhos de bate-papo?',
            'answer9': '<p>Você entra na opção de chat, seleciona uma conversa e na barra abaixo conforme mostrado na imagem você coloca o símbolo (/) e então alguns atalhos irão aparecer, selecione-o e prossiga para enviá-lo.\n</p> <img src="/assets/img/examples/Atajoss.jpg" alt="">',
          
            'question10': 'Como posso enviar imagens ou arquivos para um cliente?',
            'answer10': '<p>Você entra na opção Bate-papo, seleciona uma conversa e no canto inferior direito você encontrará um ícone de um clipe, selecione-o e ele abrirá uma venda conforme mostrado na imagem, você clica no botão para anexar o arquivo você selecione o documento ou imagem e clique no botão enviar. \n</p> <img src="/assets/img/examples/AdjuntarImagen.jpg" alt="">',
          
            'question11 ': 'Como reatribuir mensagens a outro agente? ',
            'answer11': ' <p>Você entra na opção de chat, seleciona uma conversa e na barra abaixo conforme mostrado na imagem que você coloca o @, os nomes dos agentes que você selecionar e enviar irão aparecer.\n</p> <img src="/assets/img/examples/ReasignarMensajes.jpg" alt="">',

            'question12': 'Como posso verificar as informações de um cliente?',
            'answer12': '<p>Você entra na opção de bate-papo, seleciona uma conversa no canto superior direito, você encontrará 3 pontos, clique nele e será exibida uma caixa como aparece na primeira imagem, selecione Informações de contato e abrirá uma janela como mostrado na segunda imagem.\n</p> <img src="/assets/img/examples/InfoCliente.jpg " alt=""> <img src="/assets/img/examples/InfoCliente2.jpg" alt="">',

            'question13': 'Como verificar o saldo de um cliente?',
            'answer13': '<p>Você entra na opção de bate-papo, selecione uma conversa na parte superior direita você encontrará 3 pontos, clique nele e será exibida uma caixa como aparece na primeira imagem, selecione Verificar saldo ou no teclado (Ctrl + y) E irá abrir uma janela conforme mostra a segunda imagem no campo Consulta você coloca a identificação ou ID do cliente e no campo Resposta o seu saldo e passa a enviar a mensagem. \n</p> <img src="/assets/img/examples/InfoCliente.jpg " alt=""> <img src="/assets/img/examples/ConsulaSaldo.jpg" alt="">',

            'question14': 'Onde posso encerrar o caso de um cliente?',
            'answer14': '<p>Você entra na opção Bate-papo, selecione uma conversa na parte superior direita você encontrará 3 pontos, clique nele e ele exibirá uma caixa como aparece na primeira imagem, selecione Fechar caso ou no teclado (Ctrl + b) e uma janela se abrirá conforme a imagem da segunda imagem, 2 campos aparecerão, o Motivo do caso e o Resumo, preencha ambos e clique em Fechar caso.\n</p> <img src="/assets/img/examples/InfoCliente.jpg " alt=""> <img src="/assets/img/examples/CierreCaso.jpg" alt=""> ',
           
            'question15': 'Como acessar a Pesquisa Rápida?',
            'answer15': '<p>Você entra na opção de bate-papo, seleciona uma conversa no canto superior direito, você encontrará 3 pontos, clique nele e será exibida uma caixa como aparece na primeira imagem, selecione Enviar pesquisa rápida e ela será aberta uma janela como mostrado Na segunda imagem, você tem vários campos Título, descrição, mensagem adicional então você encontrará 3 quadrados onde irá colocar (Ahem: Sim ou Não) e ao preencher os campos, abaixo você tem uma prévia onde você pode ver como ficará sua pesquisa antes de enviá-la. \n</p> <img src="/assets/img/examples/InfoCliente.jpg " alt=""> <img src="/assets/img/examples/EncuestaRapida.jpg" alt="">',

            'question16': 'Como marcar conversas?',
            'answer16': '<p>Entre no bate-papo >> Selecione uma conversa e na parte superior direita do bate-papo você encontrará o ícone de um marcador conforme mostrado na imagem, clique e selecione o correspondente.\n</p> <img src="assets/img/examples/EtiquetarConvers.jpg" alt="">',

            'question17': 'Como adicionar e deletar imagens na galeria de clientes? ',
            'answer17': '<p> 1 - Para Adicionar: Vá em Clientes >> Selecione a Empresa >> Na barra superior escolha a opção galeria, clique no botão azul com o sinal (+) que está na parte superior esquerda. Uma janela se abrirá para que você possa adicionar a imagem que está no seu computador conforme mostrado na imagem, você seleciona a imagem e ela será salva para você.\n</p><img src="assets/img/examples/ImagenGaleria.jpg" alt=""> \n<p> 2- Excluir: Clique na imagem e no canto inferior direito você encontrará um botão vermelho DELETE. \n</p> <img src="/assets/img/examples/ImagenGaleria1.jpg" alt="">',

           /*  'question18': 'Bloquear funcionalidade?',
            'answer18': '<p>Na parte superior direita do nome de usuário que você clica conforme mostrado na primeira imagem que você seleciona O bloqueio irá abrir uma janela como você pode ver na segunda imagem, você tem um campo Motivo onde você pode selecionar a opção de Descansar , Em reunião ou outro. Você também pode DESBLOQUEAR ou em Não é você? Você pode alterar o usuário. \n</p> <img src="/assets/img/examples/Bloqueo.jpg" alt=""> <img src="/assets/img/examples/Bloqueo2.jpg" alt="">', */

            'FrequentlyAskedQuestion' : 'Perguntas Frequentes',
            'SearchInFAQs' : 'Pesquise nas perguntas mais frequentes ...',
        },
        tNovedades : {
            'HowCanWehelp' : 'Como podemos ajudar?',
            'WelcomeToOurknowledgeBase' : 'Bem vindo a nossa base do conhecimento',
            'SeeallArticles': 'Veja todos os artigos',

            'Your Account' : 'Personalizar',
            'Account limits' : 'Aparência do sistema',
            'Contenido_Account_limits' : '<p> <b> Mudança de aparência da página. </b></p>\n<p> Nosso sistema agora tem a opção de alterar a aparência da página com diferentes opções de Temas e tonalidades, bem como personalizar a barra de navegação, o posicionamento da página, a barra de ferramentas e rodapé escolhendo seu próprio estilo e preferência de cor. \n </p> <div class = "ImgKnow"> <img src="/assets/img/examples/Personalizar.jpg" alt=""></div>',
            'Chat' : 'Chat',
            'Chat1' : 'Atrações do bate-papo',
            'Content_Chat1':'<p><b> Atualização do bate-papo. </b></p>\n<p> Agora, com a nova versão do ThomasV11 na seção de bate-papo, podemos responder às mensagens de forma rápida e eficiente com os atalhos ou respostas rápidas que podemos visualizar colocando o símbolo da barra (/).\n </p> <div class = "ImgKnow"><img src="/assets/img/examples/Atajoss.jpg" alt=""> </div>',
            'Chat2': 'Número de mensagens.',
            'Content_Chat2' : '<p><b>Total de conversas</b></p>\n<p> Outra das novas melhorias do chat é a exibição do número de mensagens que as operadoras e clientes estão atendendo e que estão em espera.\n</p> <p>Atenção >> O número total de bate-papos que você tem em atenção é exibido. \n</p> <p> Clientes em espera >> Mostra o número total de clientes esperando para serem atendidos. \n</p><div class = "ImgKnow"><img src="/assets/img/examples/Chat.JPG" alt=""> </div>',
            'Chat4': 'Reordenar bate-papo',
            'Content_Chat4' : '<p><b> Reorganização das mensagens.</b> </p> \n <p> Os agentes agora podem reorganizar as conversas usando "Arrastar e Soltar" (Arrastar e Soltar qualquer chat). \n </p><div class = "ImgKnow"><img src="/assets/img/examples/Reordenar chat.jpg" alt=""> </div>',

            'Chat3': 'Rótulos',
            'Content_Chat3': '<p> <b> Marque uma conversa. </b></p>\n<p> Com esta nova atualização do chat, você pode identificar conversas usando tags de relatório de falhas, pagamentos ou consulta de saldo, para agrupar mensagens e ser capaz de obter estatísticas sobre o número de conversas ou casos, obrigado ao agrupamento dessas conversas, relatórios de gestão eficientes são obtidos. \n </p> <div class = "ImgKnow"> <img src = "/assets/img/examples/EtiquetarConvers.jpg" alt = ""></div> ',
            'Inicio': 'Começo',
            'Idioma': 'línguas',
            'content_Idioma': '<p> <b> Mudança de idioma. </b></p>\n<p> Com esta nova atualização do chat, você pode identificar conversas usando tags de relatório de falha, pagamentos ou consulta de saldo, para agrupar mensagens e ser capaz de obter estatísticas sobre o número de conversas ou casos, graças ao agrupamento dessas conversas, relatórios de gestão eficientes são obtidos. \n </p> <div class = "ImgKnow"><img src="/assets/img/examples/Idioma.JPG" alt="" ></div> ',
            'Bloqueo' : 'Bloquear',
            'content_Bloqueo': '<p><b>Bloqueio de sessão.</b></p> \n <p>Nosso sistema também tem uma opção de bloqueio de sessão onde você pode ver um contador que indica há quanto tempo você está desconectado e você pode indicar o motivo. \n</p> <div class = "ImgKnow"> <img src="/assets/img/examples/Bloqueo2.jpg" alt="" ></div>',
            'Galeria': 'Galeria',
            'GaleriaDeImagen' : 'Galeria de imagens',
            'content_Imagen' : '<p><b> Gallery Section. </b> </p> \ n <p> Our system has a gallery section where the design or marketing department will be able to have a full administrator to The graphic part that the agents are going to use when serving customers in this way makes it easier and more efficient to have all the tools for good service. \ n </p> <p> In this tool we can add, delete, consult images and PDF that will be sent to our clients. \ n </p><div class = "ImgKnow"><img src="/assets/img/examples/Galeria1.jpg" alt="" ></div>',
            
            
            'Agentess' : 'Lista de agentes',
            'Agentes' : 'Agentes',
            'content_Agentes' : '<p><b> Lista de agentes. </b></p>\n<p> Com a nova atualização do Thomas, esta nova seção foi adicionada onde você pode ver uma lista de todos os agentes e aqueles que pertencem a cada empresa, também tem um menu com uma série de opções para editar os dados do agente, alterar sua senha ou removê-lo da lista.\n </p><div class = "ImgKnow"><img src="/assets/img/examples/Agentes.jpg" alt="" ></div>',
            'AgregarAgent' : 'Novos agentes',
            'content_AgregarAgent' : '<p><b> Mudança de chave de agentes. </b></p>\n<p> No mesmo sistema, você pode adicionar os agentes com os dados pessoais correspondentes, você também pode adicionar o horário de trabalho e as permissões ou acessos colocados pelo Administrador ou Mestre.\n </p><div class = "ImgKnow"><img src="/assets/img/examples/agentes2.jpg" alt="" ></div>',
            'CambioClave' : 'Alterar senha',
            'content_CambioClave' : '<p><b>Alteração de senha do agente.</b></p>\n<p> De uma forma simples e rápida você pode alterar a senha ou senha do agente, colocando a senha atual, a nova senha e a confirmação da senha. \n </p> <div class = "ImgKnow"><img src="/assets/img/examples/AgenteClave.jpg" alt="" ></div>',
            'Roles': 'Função',
            'Roles1' : 'Funções ou permissões',
            'content_Roles' : '<p><b>Permissões do agente.</b></p>\n<p> Temos uma seção chamada Funções, onde administradores e mestres têm a capacidade de adicionar, editar e excluir as funções, que determinam quem tem permissão para entrar e visualizar no sistema. \n </p> <div class = "ImgKnow"><img src="/assets/img/examples/Roles2.jpg" alt="" ></div>',
            'Roles2' : 'Opções de funções',
            'content_Roles2' : '<p><b>Opções para adicionar uma função. </b> </p> \n <p> Para definir as permissões para um agente, temos uma série de opções, como o nome, se for um agente ou administrador, uma breve descrição da função, as permissões para as diferentes seções que você pode ver no sistema e, finalmente, temos uma lista de usuários ou operadores a quem a função será atribuída. \n </p> <div class = "ImgKnow"><img src="/assets/img/examples/Roles3.jpg" alt="" ></div>',
            'Contacto' : 'Contatos',
            'Contacto1' : 'Lista de contatos',
            'content_Contacto' : '<p><b>Contato do cliente. </b> </p> \n <p> Na plataforma, temos uma opção de contato onde podemos visualizar, consultar, adicionar e editar todos os números de telefone de nossos Clientes. \n </p> <div class = "ImgKnow"><img src="/assets/img/examples/Contactos1.jpg.png" alt="" ></div>',
            'Chat5' : 'Total de Chat',
            'Content_Chat5' : '<p><b>Total de conversas. </b> </p> \n <p> Outra das novas melhorias no chat é a exibição do número de mensagens atendidas por operadoras e clientes que são em espera. \n </p> <p> Atenção: 50 >> O número total de bate-papos que você tem em mente é exibido. \n </p> <p> Clientes em espera >> Mostra o número total de clientes esperando para serem atendidos. \n </p><div class = "ImgKnow"><img src="/assets/img/examples/TotalChat.jpg" alt="" ></div>',
            'Chat6' : 'História',
            'Content_Chat6' : '<p><b>Histórico de pesquisa. </b> </p> \n <p> Você pode pesquisar o histórico de uma conversa existente digitando o número de telefone e clicando em pesquisar conversa. \n </p><div class = "ImgKnow"><img src="/assets/img/examples/Historial.jpg" alt="" ></div>',
            'Chat7' : 'Arquivos Multimídia de Chat',
            'Content_Chat7' : '<p><b>Atualização do bate-papo. </b> </p> \n <p> Outras novas atualizações feitas no bate-papo são a possibilidade de enviar e receber vídeos, documentos PDF, emoji , adesivos e até gif ... Para que a interação com nossos clientes seja mais agradável. \n </p><div class = "ImgKnow"><img src="/assets/img/examples/multimedia.JPG" alt="" ></div>',
            'Chat8' : 'Enquetes de bate-papo',
            'Content_Chat8' : '<p><b>Enquetes rápidas. </b> </p> \ n <p> Agora você pode enviar enquetes com respostas fechadas, é editado na parte superior e na parte inferior você tem a visualização de como o cliente receberá a pesquisa (você deve enviar no mínimo 2 opções e no máximo 3).\n </p><div class = "ImgKnow"><img src="/assets/img/examples/EncuestaRapida.jpg" alt="" ></div>',
            'Chat9' : 'Informações de contato',
            'Content_Chat9' : '<p><b> Informações de contato.<b> </p> \n <p> Também podemos visualizar as informações pessoais do contato armazenadas no banco de dados e também podem ser adicionadas "Campos adicionais" (adicionados do usuário). Esses campos serão carregados na guia de campos adicionais para que possam armazenar informações extras. \n </p><div class = "ImgKnow"><img src="/assets/img/examples/infoContacto.JPG" alt="" ></div>',
            'Tecnicos' : 'Técnicos',
            'Tecnicoss' : 'Registros de Técnicos',
            'content_Tecnicos' : '<p><b>Registros de técnicos </b></p> \n <p> Atualmente temos a seção de técnicos, os usuários com acesso a esta seção podem adicionar novos técnicos correspondentes às suas empresas ou empresas, foi criada principalmente para poder gerir cada um dos técnicos, empreiteiros e veículos pelas empresas a que têm acesso, permitindo adicionar, consultar e eliminar cada um destes registos.\n</p><div class = "ImgKnow"><img src="/assets/img/examples/Tecnicos.JPG" alt="" ></div>',
            'Reportess' : 'Busca avançada',
            'Reportes' : 'Relatórios',
            'content_Reportes' : '<p> <b>Relatórios de pesquisa avançada </b></p> \n <p> Os usuários com acesso a esta seção poderão realizar pesquisas específicas sobre o histórico de conversas com os clientes da empresa, por meio de palavras-chave, nome do cliente, telefone número, ID, o canal pelo qual o cliente foi atendido, o tipo de mensagem, seja só texto, imagens, áudios, vídeos, PDF, etc., podem colocar etiquetas e um intervalo de datas para realizar a busca de tudo isso em formação. \n </p> <div class = "ImgKnow"><img src="/assets/img/examples/Reportes.JPG" alt="" ></div>',
            'ResultadoBusqueda' : 'Resultado da pesquisa',
            'content_ResultadoBusqueda' : '<p> <b> Resultado da pesquisa </b> </p> \n <p> Eles terão colunas diferentes dependendo da pesquisa realizada, neste caso, o número do telefone do cliente é exibido no celular por qual a mensagem recebida, o tipo de conteúdo, o conteúdo da mensagem, a data, também terão um filtro para adicionar mais colunas e poderão exportar o resultado final para o Excel. \n </p> <div class = "ImgKnow"><img src="/assets/img/examples/Reporte1.JPG" alt="" ></div>',

        },
        tCustomize : {
            'ThemeOptions' : 'Opções de temas',
            'ColorThemes' : 'Temas de cores',
            'DefaultLight' : 'Luz Padrão',
            'YellowLight' : 'Luz amarela',
            'BlueGrayDark' : 'Azul-Cinzento Escuro',
            'PinkDark' : 'Rosa escuro',

            'LayoutStyles' : 'Estilos de design',
            'VerticalLayout1' : 'Layout vertical # 1',
            'VerticalLayout2' : 'Layout vertical # 2',
            'VerticalLayout3' : 'Layout vertical # 3',
            'HorizontalLayout1' : 'Layout horizontal # 1',

            'LayoutWidth' : 'Largura do layout',
            'FullWidth' : 'Largura completa',
            'Boxed' : 'Na caixa',

            'Navbar' : 'Barra de navegação',
            'Hide' : 'Esconder',
            'Folded' : 'Dobrado',
            'Position' : 'Posição:',
            'Left' : 'Deixou',
            'Right' : 'Direito',
            'Variant' : 'Variante:',
            'Style1' : 'Estilo 1',
            'Style2' : 'Estilo 2',
            'PrimaryBackground' : 'Antecedentes primários:',
            'SecondaryBackground' : 'Antecedentes Secundários:',

            'Toolbar' : 'Barra de ferramentas',
            'Above' : 'Sobre',
            'BelowStatic' : 'Abaixo estático',
            'BelowFixed' : 'Fixado',
            'UseCustomBackgroundColor' : 'Use uma cor de fundo personalizada',
            'BackgroundColor' : 'Cor de fundo:',

            'Footer' : 'Pie de página',
            'Color' : 'Cor:',

            'SidePanel' : 'Painel lateral',
            'CustomScrollbars' : 'Barras de rolagem personalizadas',
            'EnableCustomScrollbars' : 'Habilitar barras de rolagem personalizadas',
        },
        Perfil: {
            'MyProfile' :'Meu Perfil',
            'Bloqueo' : 'bloqueio',
            'Logout' : 'Fechar sessão',

            'Follow' : 'Siga ',
            'SendMessage' : 'Enviar mensagem',
        },
        Today :{
            'Today' : 'Hoje',
            'Events' : 'Eventos',
            'Notes' : 'Notas',
            'QuickSettings' : 'Configurações rápidas',
            'Notifications' : 'Notificações',
            'CloudSync' : 'Sincronização na nuvem',
            'RetroThrusters' : 'Propulsores retro',
            'Version / Servidor' : 'Versão / Servidor',
            'note.detail' : 'Data de corte:',
    
        },
        Login: {
            'Welcome':'Welcome to the Thomas!',
            'Lorem' :'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ullamcorper nisl erat,vel convallis elit fermentum pellentesque. Sed mollis velit facilisis facilisis viverra.',
            'LoginTo' : 'ENTRE NA SUA CONTA',
            'Email' : 'Correio eletrônico',
            'EmailIsRequired' : 'E-mail é obrigatório',  
            'CorreoNoValido' : 'O email não é válido',
            'Password' :  'Senha',
            'PasswordNoCorrecto' : 'A senha não está correta.',
            'NoReconoceClave' : 'Usuário ou senha não é reconhecido',
            'ExcedioMaximo' :'Você excedeu o número máximo de tentativas, tente novamente mais tarde.',
            'SuCuentaBloqueada' : 'Sua conta foi bloqueada por razões de segurança, contate seu supervisor para mais informações.',
            'RememberMe' : 'Lembre de mim',
            'ForgotPassword' : 'Esqueceu sua senha?',
            'Login' : 'Iniciar sessão',
            'LogInWithGoogle' : 'Faça login no Google',
            'LogInWithFacebook' : 'Entrar com o Facebook',
            'HaveAnAccount' : 'Você não tem uma conta?',
            'CreateAccount' : 'Criar uma conta',
            'OR' : 'OU',
            'Accept' : 'Aceitar',

            'Create':'CRIE A SUA CONTA AQUI',
            'NombreEmpresa' : 'Nombre de la Empresa',
            'NameEmpresaRequired' :'O nome da empresa é obrigatório',
            'NameRequired':'O nome é obrigatório',
            'UbicacionEmpresa':'Ubicacion de la Empresa',
            'locationRequired':'Localização é necessária',
            'TelefonoContacto' : 'Telefono de Contato',
            'PhoneRequired':'O telefone é obrigatório',
            'Name' : 'Nome',
            'NameRequire':'O nome é obrigatório',
            'PleaseAddress':'Por favor insira um endereço de E-mail válido',
            'PasswordConfirm' : 'Confirmação de (senha)',
            'PasswordRequired':'A confirmação da senha é necessária',
            'Creread' : 'Aceito',
            'termsConditions': 'Termos e Condições',
            'AlreadyAccount':'já tem uma conta?',

            'RecoverPassword': 'RECUPERE SUA SENHA',
            'Send': 'ENVIAR RESET LINK',
            'Gologin' :'Volte para fazer o login',
            'ResetPassword' : 'RESET SUA SENHA',
            'ResetMyPassword' : 'RESETAR MINHA SENHA',

            'YourSession' :'SUA SESSÃO ESTÁ BLOQUEADA',
            'DueInactivity' : 'Devido à inatividade, sua sessão está bloqueada. Por favor, digite sua senha para continuar.',
            'Username' : 'Nome de usuário',
            'Unlock' : 'Desbloquear',
            'AreYouNot' : "Não é você?",
            'InvalidPassword' : 'Senha inválida',
            'Motivo' : 'Razão',
            'lock' : 'bloquear',
            'Descanso': "Descansar",
            'EnReunion' : "Em uma reunião",
            'Otro' : "Outro",


            'ConfirmEmail' : 'Confirme o seu endereço de e-mail!',
            'ConfirmationEmail' : 'Um email de confirmação foi enviado para',
            'ConfirmMyEmail' :'Verifique sua caixa de entrada e clique no link "Confirmar meu e-mail" para confirmar seu endereço de e-mail.',
            'GoBackToLoginPage' :'Retornar à página de login',

            
            'Post' : 'CORRESPONDÊNCIA',
            'Posted': 'Publicado em sua biografia',
            'Shared' : 'Compartilhado algo com você',
            'SharedVideo': 'Compartió un video contigo',
            'SharedArticle': 'Compartilhou um artigo com você',
            'Like' : 'Gosto',
            'Share' : 'compartilhe ',
            'Reply' : 'Responder',
            'PostComment': 'Postar comentário',
            'LatestActivity': 'Última atividade',
            'SeeAll' : 'Ver tudo',
           
        
            'ExtenderSesion' : 'Deseja estender sua sessão?',
            'SessionExtend' : 'Estender Sessão',
            'CerrarSesion' : 'Fechar Sessão',
            'Cuenta' : 'SUA CONTA JÁ FOI VERIFICADA COM SUCESSO'


        }, 
        Event: {
            'Title' : 'Titulo',
            'PrimaryColor' : 'Cor primária',
            'SecondaryColor' : 'Cor Secundária',
            'AllDay' : 'Dia todo',
            'StartDate' : 'Data de início',
            'StartTime' : 'Hora de início',
            'EndDate' : 'Data final',
            'EndTime' : 'Fim do tempo',
            'Location': 'Localização',
            'Notes' : 'Notas',
        },
        Calendar: {
            'Campañas' : 'Campanhas',
            'Eliminar': 'Tem certeza de que deseja excluir?',
            'ClickAdd':'Clique para adicionar / remover atalho',
            'Search' : 'Pesquisar um aplicativo ou uma página',
            'Shortcuts': 'Nenhum atalho ainda!' 
        },
        Error :{
            'Sorry' : 'Desculpe, mas não foi possível encontrar a página que você está procurando',
            'GoBackToDashboard' : 'Voltar ao painel',

            'WellInternet' : 'Bem, você danificou a internet!',
            'Justkidding' : 'Brincadeira, parece que temos um problema interno, tente novamente em alguns minutos',
            'ReportThisProblem': 'Comunicar este problema',

            'Closed': 'Fechado para manutenção programada!',
            'SorryFor': 'Lamentamos o transtorno.',
            'Please':'Verifique novamente mais tarde.',
        },
        Factura: {
            'Invoice' : 'Fatura',
            'DueDate' : 'DATA DE VENCIMENTO',
            'Service' : 'SERVIÇO',
            'Unit' : 'UNIDADE',
            'UnitPrice' : 'PREÇO UNITÁRIO',
            'Quantity' : 'QUANTITY',
            'Total' : 'TOTAL',
            'Subtotal' : 'SUBTOTAL',
            'Tax' : 'TAX',
            'Discount' : 'DESCONTO',
            'Please' : "Por favor ':' Pague em 15 dias. Obrigado por fazer negócios ..",
            'InCondimentum ':'In condimentum malesuada efficitur. Mauris volutpat placerat auctor. Ut ac congue dolor. Quisquescelerisque lacus sed feugiat fermentum. Cras aliquet facilisis pellentesque. Nunc hendreritquam at leo commodo, a suscipit tellus dapibus. Etiam at felis volutpat est mollis lacinia.Mauris placerat sem sit amet velit mollis, in porttitor ex finibus. Proin eu nibh id liberotincidunt lacinia et eget eros.',

            'Phone':'Telefone:',
            'Email' : 'Email:',
            'Web' : 'Web:',
            'Cliente' : 'CLIENTE',
            'InvoiceDate' : 'DATA DA FATURA',
            'TotalDue': 'TOTAL DEVIDO',

        },
        Citas: {
            'Citas' : 'Citações',
            'Eliminar': 'Tem certeza de que deseja excluir?',
        },
        Inicio : {
            'Estadistica' : 'Estatisticas',
            'Cola' : 'Cauda',
            'Clientes' : 'Clientes',
            'Cerrados' : 'Fechado',
            'Mensajes' : 'Mensagens',
            'Nuevos' : 'Novo ',
            'Casos' : 'Casos',
            'Atendidos' : 'Frequentou:',
            'Ayerr' : 'Ontem:',

            'Total' : 'Total na fila:',
            'ThomasBOT' : ' Thomas BOT:',
            'Reasignados' : 'Reatribuído:',
            'CasosCerrados' : 'Casos Fechados',

            'EnviosMasivos' : 'Remessas em massa',
            'SeleccionaCanal' : 'Selecionar um canal',
            'SeleccionarArchivo' : 'Selecionar arquivo',
            'DetenerProceso' : 'Parar processo',
            'EnviarMensajes': 'Mandar',
            'CancelarMasivo': 'Cancelar',
            'ImagenMasivo': 'Adicionar imagem',
            'ImagenMasivoVer': 'Ver imagem'
            
        },
        Tecnicos : {
            'Contratistas' : 'Empreiteiros',
            'SeleccionaContratistas': 'Selecione os contratantes',
            'Nombre' : 'Nome ',
            'Rif' : 'Rif',
            'Telefono' : 'Telefone',
            'PersonaDecontacto' : 'Pessoa de contato',
            'Email' : 'Correio eletrônico',
            'Direccion' : 'Direção ',
            'GuardarInformacion' : 'Salvar informações',
            'AgregarTécnico' : 'Adicionar novo técnico',
            'AgregarContratista' : '--- Adicionar empreiteiro ---',
            'Apellido' : 'Sobrenome',
            'Cédula' : 'Carteira de identidade',
            'Telefono1' : 'Telefone 1',
            'Telefono2' : 'Telefone 2',
            'Birthday' : 'Aniversário',
            'Vehiculos' : 'Veículos',
            'AgregarVehiculo' : '--- Adicionar veículo ---',
            'Contacto' : 'Contato',
            'Marca' : 'Marca',
            'Modelo' : 'Modelo',
            'Color' : 'Cor',
            'Placa' : 'Placa',
            'Tipo' : 'Gentil',
            'TipoDeVehiculo' : 'Tipo de Veículo',
            'PolizaDeSeguro' : 'Apólice de seguro',

            'DeseasEliminar' : 'Você quer eliminar a ',
            'InformacionAgregada' : 'Informações adicionadas com sucesso',
            'InformacionEditada': 'Informação editada com sucesso',

            'SeleccEmpresa': 'Você deve selecionar uma empresa',
            'Cerrar': 'Fechar',

            'ModificarContratista': 'Modificar Empreiteiro',
            'EliminarContratista': 'Excluir contratado',
            'ModificarVehiculo': 'Modificar Veículo', 
            'EliminarVehiculo': 'Excluir Veículo',
            'Archivo': 'O arquivo não deve ter mais de 4 MB',
            'ErrorArchivo': 'Erro de upload de arquivo:',
            'ErrorGuardarlo' : 'Salvar erro',
            'ExpiraciónLicencia' : 'Vencimento da licença'
        }, 
        Home : {
            'Beneficios': 'Benefícios',
            'Soluciones': 'Soluciones',
            'Nosotros':'Nós',
            'Contacto': 'Contato',
            'IniciarSesion': 'Iniciar sessão',
            'SoftwareChat': 'SOFTWARE DE CHAT MULTICANNEL',
            'PotenciaNegocio': 'Potencialize o seu negócio, oferecendo soluções rápidas via WhatsApp.',
            'OptimiceComunicacion': 'Otimize sua comunicação, gerencie seus dados e tenha sua própria plataforma para melhorar a interação de seus clientes através de agentes humanos e',
            'SolicitarDemo': 'Solicitar demonstração',
            'BeneficiosThomas': 'Benefícios de usar o Thomas para participar de todos os seus chats',
            'MultiCanal': 'MultiCanal',
            'multiplesAgentes': 'Você pode ter vários agentes participando de conversas de',
            'Metricas': 'Métricas em tempo real',
            'flujoDeConversaciones': 'Conheça o fluxo das conversas, tempo médio destas e das',
            'CalidadRespuesta': 'qualidade de resposta',
            'Agentes': 'por seus agentes.',
            'Thomas': 'Agora Thomas vai com você',
            'AppThomas': 'Com nosso Thomas App, sua equipe terá a capacidade de se mover sem negligenciar o atendimento ao cliente.',
            'AutomatizaThomas': 'Automatizar com Thomas',
            'BrindaAtencion': 'Prestar atenção 24 horas nos sete dias da semana',
            'ChatDiseñados': 'bate-papo personalizado',
            'Necesidades': 'de suas necessidades.',
            'CasosDeUso': 'Casos de uso',
            'Ventas': 'Vendas',
            'AumentaConversiones': 'Aumentar as conversões e impulsionar as vendas por meio de conversas em tempo real.',
            'AtencionCliente': 'Atendimento e suporte ao cliente',
            'BrindaSoporte': 'Fornece suporte oportuno aos seus clientes através de seus canais preferidos.',
            'LogisticaDitribucion': 'Logística e distribuição de encomendas',
            'GestionaPedidos': 'Gerenciar pedidos com seus agentes ou com nosso chat de design personalizado.',
            'HechoPor': 'Feito por:', 

            'Plantillas' : 'Modelos para WhatsApp',
            
            'PlantilaConte':"Quando se trata de vendas, velocidade é tudo. Acelere sua velocidade de resposta com nossos modelos integrados do WhatsApp. Você pode enviar mensagens automaticamente com base em gatilhos, como chegar a um determinado estágio do processo. A melhor parte? Você pode personalizar os modelos com informações do perfil do cliente potencial, para que nenhuma mensagem apareça como um robô.",
            
            'BotNegocio':"Construir um chatbot para WhatsApp não precisa ser difícil. Nosso criador de BOT sem código facilita a criação de um bot que pode coletar informações de clientes em potencial, preencher seus perfis, iniciar ações automáticas e muito mais.",
            

            'Asesores' : 'Acompanhe o desempenho de seus conselheiros',
            'NuevoCliente':"Você ainda não sabe se alguém respondeu a esse novo cliente?",
            'EnThomas':"Na Thomas, os gerentes não precisam questionar: eles podem facilmente criar e visualizar tarefas, abrir qualquer perfil de cliente para ver a história completa e medir o desempenho com nossas ferramentas de relatório.",
            'Comunicacion':'É um centro de comunicação para todas as suas conversas de negócios. Cada cliente em potencial recebe seu próprio perfil completo, onde você pode se comunicar com eles usando seus canais favoritos: chamada telefônica, SMS, e-mail ou mensagem como o WhatsApp.',
            'Equipo':"Quando se trata de usar o WhatsApp em equipe, as coisas podem ficar confusas rapidamente: números de telefone separados, dispositivos diferentes ... é uma dor de cabeça. É por isso que Thomas permite que todos os membros da equipe compartilhem uma única conta do WhatsApp.",
            'Equipo2':"E não se preocupe em se aprofundar em conversas que não são suas - em Thomas, você só é notificado sobre clientes em potencial que são atribuídos a você"
        },
        ValidationsForm: {
            "USERNAME_REQUIRED": "O usuário é requerido.",
            "USERNAME_MIN_LENGHT": "O campo do usuário deve ter pelo menos 4 caracteres.",
            "PASSWORD_REQUIRED": "A senha é necessária.",
            "PASSWORD_MIN_LENGHT": "O campo da senha deve ter pelo menos 4 caracteres.",
            "REQUIRED": "Campo obrigatório",
            "LOGIN_INVALID": "Usuário e/ou senha incorreta",
            "MIN_CHARACTER_REQUIRED": "Comprimento dos dados menor que o mínimo permitido.",
            "MAX_CHARACTER_ALLOWED": "Comprimento dos dados maior que o máximo permitido.",
            "CHARACTER_NOT_ALLOWED": "Dados inválidos.",

            "LOADING": "Carregamento, por favor, aguarde.",
            "SIGN_IN": "Entrar"
        } 

    }
};
