import { Pipe, PipeTransform } from '@angular/core';

// Jhonattan
@Pipe({name: 'PipePhone'})
export class PipePhone implements PipeTransform {
  transform(value: string, strToReplace: string, replacementStr: string): string {

    if (value === undefined || value === null) return '';

    value = value.toString();
    if(!value)
    {
      return value;
    } else if ( !value.includes('@c.us') ) {
      return value;
    }


    
      if(value.includes('@c.us') && value.includes('58')){
        return value.replace(new RegExp('@c.us', 'g'), '').replace(new RegExp('58', 'g'),'(+58) ');
      }
    
  }
}