import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser'
@Pipe({
  name: 'audiof',
  pure: false
})
export class AudiofPipe implements PipeTransform {

constructor(private dom:DomSanitizer){

}
  transform(value:any, args:any){
    return this.dom.bypassSecurityTrustResourceUrl(value);
  }

}

