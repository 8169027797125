
import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { HttpClient } from "@angular/common/http";


@Injectable({
  providedIn: 'root'
})
export class MutationService {

  constructor() {

  }

  /* Inicio Mutation Cliente */
  public CreateCLiente(apollo: any, Token: any, Data: any) {
    return new Promise((resolve, reject) => {

      //console.log(Token)

      apollo.mutate({
        mutation: CreateClientes,
        variables:
        {
          /*token: Token,
          idCliente: Data.id,
          NombreCliente: Data.name,
          Direccion: Data.ubicacion,
          licencia: Data.prefijo + Data.telefono,
          correo: Data.correo,
          rif: Data.rif,
          telefono: Data.telefono,
          prefijo: Data.prefijo,
          metodo_pago: 2,
          logo: Data.logo,
          active: true*/
          "token": Token,
          "input": {
            "idCliente": Data.id,
            "NombreCliente": Data.name,
            "Direccion": Data.ubicacion,
            "licencia": Data.prefijo + Data.telefono,
            "correo": Data.correo,
            "rif": Data.rif,
            "telefono": Data.telefono,
            "prefijo": Data.prefijo,
            "metodo_pago": 2,
            "logo": Data.logo,
            "active": true
          }
        }
      }).subscribe(({ data }) => {
        //console.log('Cliente  Creado');
        resolve(data)
      }, (error: any) => {
        console.error('there was an error sending the query', error);
        reject(error)
      });
    })
  }
  public UpdateClient(Token: string, apollo: any, Data: any) {
    return new Promise((resolve, reject) => {

      //console.log(Data)

      //let { IdCliente, NombreCliente, Direccion, Pais, correo, rif, telefono, prefijo, metodo_pago, logo, active } = Data;

      apollo.mutate({
        mutation: UpdateCliente,
        variables:
        {
          token: Token,
          IdCliente: Data.id,
          NombreCliente: Data.name,
          Direccion: Data.ubicacion,
          Pais: Data.Direccion,
          correo: Data.correo,
          rif: Data.rif,
          telefono: Data.telefono,
          prefijo: Data.prefijo,
          metodo_pago: 4,
          logo: Data.logo,
          active: true
        }
      }).subscribe(({ data }) => {
        resolve(data)
      }, (error) => {
        console.error('there was an error sending the query', error);
        reject(error)
      });
    })

  }
  /* Fin Mutation Cliente */

  /* Inicio Mutation de atajos */
  public AddAtajos(Token: string, apollo: any, Data: any, _httpClient?: HttpClient) {

    return new Promise((resolve, reject) => {

      let { abreviado, id, idCliente, Canales, texto, Ubicacion } = Data;
      let licencia: any

      if (typeof Canales == "string") {
        licencia = [Canales]
      }
      if (typeof Canales == 'object') {
        licencia = Canales
      }

      apollo.mutate({
        mutation: CreateAtajo,
        variables: {
          token: Token,
          IdCliente: idCliente,
          id: id,
          abreviado: abreviado,
          texto: texto,
          Canales: licencia,
          Ubicacion: Ubicacion
        }
      }).subscribe(({ data }) => {
        // const _httpClientCampos: HttpClient = _httpClient
        // _httpClientCampos.post('api/campos/1',id,Data)
        // .subscribe((response: any) => { }, (Error) => console.error('CAMPOS' + Error));
        resolve(data)
      }, (error) => {
        console.error('Error In Crete Atajo', error);
        reject(error)
      });
    })

  }
  public UpdateAtajos(Token: string, apollo: any, Data: any) {
    //VERIFICAR CUANDO LA LIC VENGA EN ARREGLO NO DEBE IR DENTRO DE OTRO ARREGLO COMO ESTA ACTUALMENTE OJO...
    return new Promise((resolve, reject) => {

      let { abreviado, id, idCliente, Canales, texto, Ubicacion } = Data;
      let licencia: any

      if (typeof Canales == "string") {
        licencia = [Canales]
      }
      if (typeof Canales == 'object') {
        licencia = Canales
      }

      apollo.mutate({
        mutation: UpdateAtajos,
        variables:
        {
          "token": Token,
          "IdCliente": idCliente,
          "id": id,
          "abreviado": abreviado,
          "texto": texto,
          "Canales": licencia,
          "Ubicacion": Ubicacion
        }
      }).subscribe(({ data }) => {
        resolve(data)
      }, (error) => {
        console.error('Error In Update Atajos', error);
        reject(error)
      });
    })

  }
  public DeleteAtajos(apollo: any, Token: any, Data: any) {
    return new Promise((resolve, reject) => {
      apollo.mutate({
        mutation: DeleteAtajo,
        variables: {
          token: Token,
          IdCliente: Data.IdCliente,
          id: Data.id
        }
      }).subscribe(({ data }) => {
        resolve(data)
      }, (error) => {
        console.error('Error In Detele Atajos', error);
        reject(error)
      });
    })

  }
  /* Fin de las Mutation de atajos */

  /* Inicio de las Mutaion de Campos */
  public CreateCampos(Token: string, apollo: any, Data: any) {

    return new Promise((resolve, reject) => {


      let { idCliente, id, title, type, requerido, Lic, ubicacion } = Data;

      let licencia: any, localizacion: any

      if (typeof Lic == "string") { licencia = [Lic] }

      if (typeof Lic == 'object') { licencia = Lic }

      //En la base de datos ubicacion es un arreglo de String, y aqui preparo la varible para un arreglo en ddo caso que se un string
      if (typeof ubicacion == "string") { localizacion = [ubicacion] }

      if (typeof ubicacion == 'object') { localizacion = ubicacion }

      apollo.mutate({
        mutation: CreateCampos,
        variables:
        {
          "token": Token,
          "IdCliente": idCliente,
          "id": id,
          "title": title,
          "tipo": type,
          "requerido": requerido,
          "ubicacion": localizacion,
          "canales": licencia
        }
      }).subscribe(({ data }) => {
        resolve(data)
      }, (error) => {
        console.error('Error in Create Campos', error);
        reject(error)
      });
    })

  }
  public UpdateCampos(Token: string, apollo: any, Data: any) {

    return new Promise((resolve, reject) => {

      console.log("data en update campos ")
      console.log(Data)

      let { idCliente, id, title, type, requerido, Lic, ubicacion } = Data;
      //requerido es de tipo String en backend se dee cambiar a boolean 30-04-21
      let licencia: any, localizacion: any

      if (typeof Lic == "string") { licencia = [Lic] }

      if (typeof Lic == 'object') { licencia = Lic }

      //En la base de datos ubicacion es un arreglo de String, y aqui preparo la varible para un arreglo en dado caso que sea un string
      if (typeof ubicacion == "string") { localizacion = [ubicacion] }

      if (typeof ubicacion == 'object') { localizacion = ubicacion }


      /*console.log({
        "token": Token,
        "IdCliente": idCliente,
        "id": id,
        "title": title,
        "tipo": type,
        "requerido": requerido,
        "ubicacion": localizacion,
        "canales": licencia
      })*/


      apollo.mutate({
        mutation: UpdateCampos,
        variables:
        {
          "token": Token,
          "IdCliente": idCliente,
          "id": id,
          "title": title,
          "tipo": type,
          "requerido": requerido,
          "ubicacion": localizacion,
          "canales": licencia
        }
      }).subscribe(({ data }) => {
        resolve(data)
      }, (error) => {
        console.error('Error In Update Campos', error);
        reject(error)
      });
    })

  }
  public DeleteCampos(apollo: any, Token: any, Data: any) {
    return new Promise((resolve, reject) => {
      apollo.mutate({
        mutation: DeleteCampo,
        variables: {
          token: Token,
          IdCliente: Data.IdCliente,
          id: Data.id
        }
      }).subscribe(({ data }) => {
        resolve(data)
      }, (error) => {
        console.error('Error In Delete Campos', error);
        reject(error)
      });
    })

  }
  /* Fin de las Mutation de Campos */

  /* Inicio de las Mutation de Etiquetas */
  public CreateEtiquetas(Token: string, apollo: any, Data: any) {
    return new Promise((resolve, reject) => {

      console.log('Esto me llego para la etiquet')
      console.log(Data)

      console.log('Token que llego')
      console.log(Token)

      let { idCliente, id, etiquetas, canal } = Data;
      let licencia: any

      if (typeof canal == "string") {
        licencia = [canal]
      }
      if (typeof canal == 'object') {
        licencia = canal
      }

      apollo.mutate({
        mutation: CreateEtiquetas,
        variables:
        {
          "token": Token,
          "IdCliente": idCliente,
          "id": id,
          "word": etiquetas,
          "idCanal": licencia
        }
      }).subscribe(({ data }) => {
        resolve(data)
      }, (error) => {
        console.error('Error In Create Etiqueta', error);
        reject(error)
      });
    })
  }
  public UpdateEtiquetas(Token: string, apollo: any, Data: any) {
    return new Promise((resolve, reject) => {

      let { idCliente, id, etiquetas, canal } = Data;
      let licencia: any

      console.log('Esto me llego para la etiquet')
      console.log(Data)

      if (typeof canal == "string") {
        licencia = [canal]
      }
      if (typeof canal == 'object') {
        licencia = canal
      }

      apollo.mutate({
        mutation: UpdateEtiquetas,
        variables:
        {
          "token": Token,
          "IdCliente": idCliente,
          "id": id,
          "word": etiquetas,
          "idCanal": licencia
        }
      }).subscribe(({ data }) => {
        //console.log('got data', data);
        resolve(data)
      }, (error) => {
        console.error('there was an error sending the query', error);
        reject(error)
      });
    })

  }
  public DeleteEtiqueta(apollo: any, Token: any, Data: any) {
    return new Promise((resolve, reject) => {
      apollo.mutate({
        mutation: DeleteEiquetas,
        variables: {
          token: Token,
          IdCliente: Data.IdCliente,
          id: Data.id
        }
      }).subscribe(({ data }) => {
        //console.log('Etiqueta ' + Data.id + " Eliminado con exito");
        resolve(data)
      }, (error) => {
        console.error('there was an error sending the query', error);
        reject(error)
      });
    })

  }
  /* Fin de las Mutation de Etiquetas */

  /* Inicio Mutation de Roles */
  public DeleteRol(apollo: any, token: any, IdRol: String) {//Para eliminar un rol
    return new Promise((resolve, reject) => {
      apollo.mutate({
        mutation: DeleteRoles,
        variables: {
          Token: token,
          IdRol: IdRol,
        }
      }).subscribe(({ data }) => {
        //console.log('Rol Eliminado Con Exito');
        resolve(data)
      }, (error) => {
        console.error('there was an error sending the query', error);
        reject(error)
      });
    })

  }
  /* Fin Mutation de Roles */

  /* Inicio de las Mutation de horarios */
  public CreateHorario(Token: string, apollo: any, Data: any) {
    return new Promise((resolve, reject) => {
      let { idCliente, idHorario, Descripcion, Excepciones, Jornadas } = Data;

      /*console.log(Data)

      console.log("__________________________________")
      console.log("IdCliente: " + idCliente)
      console.log("idHorario: " + idHorario)
      console.log("Descripcion: " + Descripcion)
      console.log("DiaInicio: " + Jornadas)
      console.log("DiaFin: " + Jornadas.DiaFin)
      console.log("HoraInicio: " + Jornadas.HoraInicio)
      console.log("HoraFin: " + Jornadas.HoraFin)
      console.log("DescripcionEx: " + Excepciones.Descripcion)
      console.log("Fecha: " + Excepciones.Fecha)*/

      apollo.mutate({
        mutation: CreateHorarios,
        variables:
        {
          "token": Token,
          "IdCliente": idCliente,
          "idHorario": idHorario,
          "Descripcion": Descripcion,
          "DiaInicio": Jornadas.DiaInicio,
          "DiaFin": Jornadas.DiaFin,
          "HoraInicio": Jornadas.HoraInicio,
          "HoraFin": Jornadas.HoraFin,
          "DescripcionEx": Excepciones.Descripcion,
          "Fecha": Excepciones.Fecha
        }
      }).subscribe(({ data }) => {
        //console.log(Data)
        //console.log('Horario ' + idHorario + ' Creado');
        resolve(data)
      }, (error) => {
        console.error('there was an error sending the query', error);
        reject(error)
      });
    })
  }

  public CreateHorario2(Token: string, apollo: any, Data: any) {
    return new Promise((resolve, reject) => {
      let { idCliente, idHorario, Descripcion, Excepciones, Jornadas } = Data;

      // console.log("__________________________________")
      // console.log("IdCliente: " + idCliente);
      // console.log("idHorario: " + idHorario);
      // console.log("Descripcion: " + Descripcion);
      // console.log("Jornada");
      // console.log(Jornadas);
      // console.log("Excepciones");
      // console.log(Excepciones);

      apollo.mutate({
        mutation: CreateHorarios2,
        variables:
        {
          "token": Token,
          "input":
          {
            "IdCliente": idCliente,
            "idHorario": idHorario,
            "Descripcion": Descripcion,
            "Jornadas": [...Jornadas],
            "Excepciones": [...Excepciones]
          }
        }
      }).subscribe(({ data }) => {
        //console.log(Data)
        //console.log('Horario ' + idHorario + ' Creado');
        resolve(data)
      }, (error) => {
        console.error('there was an error sending the query', error);
        reject(error)
      });
    })
  }
  public UpdateHorario(Token: string, apollo: any, Data: any) {
    return new Promise((resolve, reject) => {
      let { idCliente, idHorario, Descripcion, Excepciones, Jornadas } = Data;

      //console.log(Data)

      apollo.mutate({
        mutation: UpdateHorarios,
        variables:
        {
          "token": Token,
          "IdCliente": idCliente,
          "idHorario": idHorario,
          "Descripcion": Descripcion,
          "DiaInicio": Jornadas.DiaInicio,
          "DiaFin": Jornadas.DiaFin,
          "HoraInicio": Jornadas.HoraInicio,
          "HoraFin": Jornadas.HoraFin,
          "DescripcionEx": Excepciones.Descripcion,
          "Fecha": Excepciones.Fecha
        }
      }).subscribe(({ data }) => {
        //console.log('Horario ' + idHorario + ' Actualizado');
        resolve(data)
      }, (error) => {
        console.error('there was an error sending the query', error);
        reject(error)
      });
    })
  }

  public UpdateHorario2(Token: string, apollo: any, Data: any) {
    return new Promise((resolve, reject) => {
      let { idCliente, idHorario, Descripcion, Excepciones, Jornadas } = Data;
      //Si no se borra este campo sucede un error con GraphQL
      Jornadas.forEach(element => {
        delete element.__typename
      });
      //Si no se borra este campo sucede un error con GraphQL
      Excepciones.forEach(element => {
        delete element.__typename
      });

      apollo.mutate({
        mutation: UpdateHorarios2,
        variables:
        {
          "token": Token,
          "input":
          {
            "IdCliente": idCliente,
            "idHorario": idHorario,
            "Descripcion": Descripcion,
            "Jornadas": [...Jornadas],
            "Excepciones": [...Excepciones]
          }
        }
      }).subscribe(({ data }) => {
        //console.log('Horario ' + idHorario + ' Actualizado');
        resolve(data)
      }, (error) => {
        console.error('there was an error sending the query', error);
        reject(error)
      });
    })
  }
  public DeleteHorario(apollo: any, Token: any, Data: any) {
    return new Promise((resolve, reject) => {
      let { idCliente, idHorario } = Data
      //console.log("idCliente :" + idCliente + " idHorario :" + idHorario)
      apollo.mutate({
        mutation: DeleteHorarios,
        variables: {
          "Token": Token,
          "IdCliente": idCliente,
          "IdHorario": idHorario
        }
      }).subscribe(({ data }) => {
        //console.log('Horario ' + idHorario + " Eliminado con exito");
        resolve(data)
      }, (error) => {
        console.error('there was an error sending the query', error);
        reject(error)
      });
    })

  }
  /* Fin de las Mitation de horarios */

  /* Inicio de las Mutation de Canales */
  public CreateCanal(Token: string, apollo: any, Data: any) {
    return new Promise((resolve, reject) => {
      let { IdCliente, lic, type, version, totalmsg, online, status, bateria } = Data;

      //console.log(Data)

      apollo.mutate({
        mutation: CreateCanales,
        variables:
        {
          "token": Token,
          "IdCliente": IdCliente,
          "lic": lic,
          "type": type,
          "version": version,
          "totalmsg": totalmsg,
          "online": online,
          "status": status,
          "bateria": bateria
        }
      }).subscribe(({ data }) => {
        //console.log('Canal ' + lic + ' Creado');
        resolve(data)
      }, (error) => {
        console.error('there was an error sending the query', error);
        reject(error)
      });
    })
  }
  public UpdateCanal(Token: string, apollo: any, Data: any) {
    return new Promise((resolve, reject) => {

      let { IdCliente, lic, type, version, totalmsg, online, status, bateria } = Data;

      apollo.mutate({
        mutation: UpdateCanales,
        variables:
        {
          "token": Token,
          "IdCliente": IdCliente,
          "lic": lic,
          "type": type,
          "version": version,
          "totalmsg": totalmsg,
          "online": online,
          "status": status,
          "bateria": bateria
        }
      }).subscribe(({ data }) => {
        //console.log('Canal ' + lic + ' Actualizado');
        resolve(data)
      }, (error) => {
        console.error('there was an error sending the query', error);
        reject(error)
      });
    })

  }
  public DeleteCanal(apollo: any, Token: any, Data: any) {
    return new Promise((resolve, reject) => {

      apollo.mutate({
        mutation: DeleteCanales,
        variables: {
          "Token": Token,
          "IdCliente": Data.idCliente,
          "lic": Data.lic
        }
      }).subscribe(({ data }) => {
        //console.log('Canal ' + Data.lic + " Eliminado con exito");
        resolve(data)
      }, (error) => {
        console.error('there was an error sending the query', error);
        reject(error)
      });
    })

  }
  /* Fin de las mutation de Canales */

  /* Inicio de las Mutation de Galeria */
  /* public CreateGaleria(Token: string, apollo: any, Data: any) {
    return new Promise((resolve, reject) => {
      let { idCliente, id, owner, title, url, message, size, created, modified, available } = Data;

      console.log({
        "token": Token,
        "IdCliente": idCliente,
        "id": id,
        "owner": owner,
        "title": title,
        "url": url,
        "message": message,
        "size": size,
        "created": created,
        "modified": modified,
        "available": available
      })

      apollo.mutate({
        mutation: CreateGalerias,
        variables:
        {
          "token": Token,
          "IdCliente": idCliente,
          "id": id,
          "owner": owner,
          "title": title,
          "url": url,
          "message": message,
          "size": size,
          "created": created,
          "modified": modified,
          "available": available
        }
      }).subscribe(({ data }) => {
        //console.log('Galeria ' + id + ' Creada');
        resolve(data)
      }, (error) => {
        console.error('there was an error sending the query', error);
        reject(error)
      });
    })
  } */
  public CreateGaleria(Token: string, apollo: any, Data: any) {
    return new Promise((resolve, reject) => {
      let { idCliente, id, owner, title, url, message, size, created, modified, available } = Data;

      apollo.mutate({
        mutation: CreateGalerias2,
        variables: {
          "token": Token,
          "Data": {
            "IdCliente": idCliente,
            "id": id,
            "owner": owner,
            "title": title,
            "url": url,
            "message": message,
            "size": size,
            "created": created,
            "modified": modified,
            "available": available
          }
        }
      }).subscribe(({ data }) => {
        console.log('Galeria ' + id + ' Creada');
        resolve(data)
      }, (error) => {
        console.error('Error In CreateGaleria', error);
        reject(error)
      });
    })
  }
  /* public UpdateGaleria(Token: string, apollo: any, Data: any) {
    return new Promise((resolve, reject) => {
      let { idCliente, id, owner, title, url, message, size, created, modified, available } = Data;

      apollo.mutate({
        mutation: UpdateGalerias,
        variables:
        {
          "token": Token,
          "IdCliente": idCliente,
          "id": id,
          "owner": owner,
          "title": title,
          "url": url,
          "message": message,
          "size": size,
          "created": created,
          "modified": modified,
          "available": available
        }
      }).subscribe(({ data }) => {
        //console.log('Galeria ' + id + ' Actualizada');
        resolve(data)
      }, (error) => {
        console.error('there was an error sending the query', error);
        reject(error)
      });
    })

  } */
  public UpdateGaleria(Token: string, apollo: any, Data: any) {
    return new Promise((resolve, reject) => {
      let { idCliente, id, owner, title, url, message, size, created, modified, available } = Data;

      console.log('Data que llego para el update ')
      console.log({
        "IdCliente": idCliente,
        "id": id,
        "owner": owner,
        "title": title,
        "url": url,
        "message": message,
        "size": size,
        "created": created,
        "modified": modified,
        "available": available
      })

      apollo.mutate({
        mutation: UpdateGalerias2,
        variables: {
          "token": Token,
          "Data": {
            "IdCliente": idCliente,
            "id": id,
            "owner": owner,
            "title": title,
            "url": url,
            "message": message,
            "size": size,
            "created": created,
            "modified": modified,
            "available": available
          }
        }
      }).subscribe(({ data }) => {
        console.log('Galeria ' + id + ' Actualizada');
        resolve(data)
      }, (error) => {
        console.error('Error in UpdateGaleria', error);
        reject(error)
      });
    })

  }
  public DeleteGaleria(Token: any, apollo: any, Data: any) {
    return new Promise((resolve, reject) => {

      let { idCliente, id } = Data;
      console.log(idCliente, id, Token);
      apollo.mutate({
        mutation: DeleteGalerias,
        variables: {
          "token": Token,
          "Data": {
            "IdCliente": idCliente,
            "id": id
          }
        }
      }).subscribe(({ data }) => {
        //console.log('Galeria ' + id + " Eliminada con exito");
        resolve(data)
      }, (error) => {
        console.log(error);
        console.error('there was an error sending the query', error);
        reject(error)
      });
    })

  }
  /* Fin de las Mutation de Galeria */

  /* Inicio de las Mutation de Bot */
  public CreateBot(Token: string, apollo: any, Data: any) {
    return new Promise((resolve, reject) => {
      let { idCliente, idBot, idCanal, bot, noMatch } = Data;

      //console.log(Data)

      apollo.mutate({
        mutation: CreateBots,
        variables:
        {
          "token": Token,
          "IdCliente": idCliente,
          "idBot": idBot,
          "idCanal": idCanal,
          "contains": bot.contains,
          "exact": bot.exact,
          "tipo": bot.tipo,
          "response": bot.response,
          "apiURL": bot.apiURL,
          "bData": bot.bData,
          "noMatch": noMatch
        }
      }).subscribe(({ data }) => {
        //console.log('Bot ' + idBot + ' Creado');
        resolve(data)
      }, (error) => {
        console.error('there was an error sending the query', error);
        reject(error)
      });
    })
  }
  public UpdateBot(Token: string, apollo: any, Data: any) {
    return new Promise((resolve, reject) => {

      let { idCliente, idBot, idCanal, bot, noMatch } = Data;

      apollo.mutate({
        mutation: UpdateBots,
        variables:
        {
          "token": Token,
          "IdCliente": idCliente,
          "idBot": idBot,
          "idCanal": idCanal,
          "contains": bot.contains,
          "exact": bot.exact,
          "tipo": bot.tipo,
          "response": bot.response,
          "apiURL": bot.apiURL,
          "bData": bot.bData,
          "noMatch": noMatch
        }
      }).subscribe(({ data }) => {
        //console.log('Bot ' + idBot + ' Actualizado');
        resolve(data)
      }, (error) => {
        console.error('there was an error sending the query', error);
        reject(error)
      });
    })

  }
  public DeleteBot(apollo: any, Token: any, Data: any) {
    return new Promise((resolve, reject) => {

      let { idCliente, idBot } = Data;

      apollo.mutate({
        mutation: DeleteBots,
        variables: {
          Token: Token,
          IdCliente: idCliente,
          idBot: idBot
        }
      }).subscribe(({ data }) => {
        //console.log('Bot ' + idBot + " Eliminado con exito");
        resolve(data)
      }, (error) => {
        console.error('there was an error sending the query', error);
        reject(error)
      });
    })

  }
  /* Fin de las Mutation de Bot */

  /* Inicio de las Mutation de Contactos */
  public CreateContacto(apollo: any, Token: any, Data: any) {
    return new Promise((resolve, reject) => {
      let { id, idCliente, idEmpresa, idCanal, name, lastName, avatar, code, extra, category, status, phone, Canal, email, birthday, company, address, notes } = Data;

      console.log('Data que me llego en create contacto')
      console.log(Data)

      apollo.mutate({
        mutation: CreateContactos,
        variables:
        {
          'token': Token,
          'id': id,
          'idCliente': idCliente,
          'idEmpresa': idEmpresa,
          'idCanal': idCanal,
          'name': name,
          'lastName': lastName,
          'avatar': avatar,
          'code': code,
          'extra': extra,
          //'category':[""],
          'status': status,
          'phone': phone,
          'Canal': Canal,
          'email': email,
          'birthday': birthday,
          'company': company,
          'address': address,
          'notes': notes
        }
      }).subscribe(({ data }) => {
        console.log('Contacto ' + phone + ' Creado');
        console.log(data)
        resolve(data)
      }, (error) => {
        console.error('there was an error sending the query', error);
        reject(error)
      });
    })
  }
  public UpdateContacto(apollo: any, Token: any, Data: any) {
    return new Promise((resolve, reject) => {
      let { id, idCliente, idEmpresa, idCanal, name, lastName, avatar, code, extra, category, status, phone, Canal, email, birthday, company, address, notes, campos } = Data;

      /*console.log('Data que me llego en update contacto')
      console.log(Data)*/

      apollo.mutate({
        mutation: UpdateContactos,
        variables:
        {
          'token': Token,
          'id': id,
          'idCliente': idCliente,
          'idEmpresa': idEmpresa,
          'idCanal': idCanal,
          'name': name,
          'lastName': lastName,
          'avatar': avatar,
          'code': code,
          'extra': extra,
          //'category':[""], Fase 2
          'status': status,
          'phone': phone,
          'Canal': Canal,
          'email': email,
          'birthday': birthday,
          'company': company,
          'address': address,
          'notes': notes,
          'campos': campos
        }
      }).subscribe(({ data }) => {
        //console.log('Contacto ' + phone + ' Actualizado');
        resolve(data)
      }, (error) => {
        console.error('there was an error sending the query', error);
        reject(error)
      });
    })
  }
  public UpdatePhotoContacto(apollo: any, Data: any) {
    return new Promise((resolve, reject) => {
      let { id, avatar } = Data;

      /*console.log('Data que me llego en update foto contacto')
      console.log(Data)*/

      apollo.mutate({
        mutation: UpdateFotoContacto,
        variables: {
          id: id,
          avatar: avatar
        }
      }).subscribe(({ data }) => {
        //console.log('Contacto ' + id + ' Foto Actualizada');
        resolve(data)
      }, (error) => {
        console.error('Error In Update Photo Contact', error);
        reject(error)
      });
    })
  }

  public UpdateCamposContact(apollo: any, Data: any, token: any) {
    return new Promise((resolve, reject) => {
      let { id, campos } = Data;

      console.log('Data que me llego en update contacto')
      console.log(Data)

      apollo.mutate({
        mutation: UpdateCamposContactos,
        variables:
        {
          'token': token,
          'id': id,
          'campos': campos
        }
      }).subscribe(({ data }) => {
        console.log('Contacto ' + id + ' Actualizado');
        resolve(data)
      }, (error) => {
        console.error('there was an error sending the query', error);
        reject(error)
      });
    })
  }

  public DeleteContacto(apollo: any, token: any, idContacto: String) {//Para eliminar un usuario
    return new Promise((resolve, reject) => {
      apollo.mutate({
        mutation: DeleteContactos,
        variables: {
          token: token,
          idContacto: idContacto
        }
      }).subscribe(({ data }) => {
        //console.log('Contacto ' + idContacto + ' Eliminado Con Exito');
        resolve(data)
      }, (error) => {
        console.error('there was an error sending the query', error);
        reject(error)
      });
    })

  }
  /* Fin de las Mutation de Contactos */

  public createChat(apollo: any, Data: any, _httpClient?: HttpClient) {
    return new Promise((resolve, reject) => {
      let {
        idEmpresa,
        phone,
        idConversacion,
        idmessage,
        timestamp,
        me_to,
        contentType,
        content,
        TimeRead,
        status,
        statusc,
        readmsg,
        operador,
        lic
      } = Data;
      apollo.mutate({
        mutation: CreateChat,
        variables: {
          idEmpresa: idEmpresa,
          phone: phone,
          idConversacion: idConversacion,
          idmessage: idmessage,
          timestamp: timestamp,
          me_to: me_to,
          contentType: contentType,
          content: content,
          TimeRead: TimeRead,
          status: status,
          statusc: statusc,
          readmsg: readmsg,
          operador: operador,
          lic: lic
        }
      }).subscribe(({ data }) => {
        resolve({
          data,
          idConversacion,
          idmessage
        })
      }, (error) => {
        console.error('there was an error sending the query', error);
        reject(error)
      });
    })
  }

  public createChatCola(apollo: any, Data: any, _httpClient?: HttpClient) {
    return new Promise((resolve, reject) => {

      console.log(Data)

      apollo.mutate({
        mutation: CreateChatCola,
        variables: {
          idEmpresa: Data.idEmpresa,
          phone: Data.phone,
          idConversacion: Data.idConversacion,
          idmessage: Data.idmessage,
          timestamp: Data.timestamp,
          me_to: Data.me_to,
          contentType: Data.contentType,
          content: Data.content,
          TimeRead: Data.TimeRead || new Date(),
          status: Data.status,
          statusc: Data.statusc,
          readmsg: Data.readmsg,
          operador: Data.operador,
          lic: Data.lic,
          etiqueta: Data.etiqueta || ""
        }
      }).subscribe(({ data }) => {
        console.log('got data', data);
        resolve(data)
      }, (error) => {
        console.error('Error in createChatCola', error);
        reject(error)
      });
    })
  }
  // DEbe llegar
  public StatusCAll(apollo: any, Data: any, Token: any, _httpClient?: HttpClient) {
    //console.log('esto me llego a la mutation statusc: ', Data)

    return new Promise((resolve, reject) => {
      let {
        idConversacion,
        status,
        statusc,
        phone,
        lic,
        operador
      } = Data;

      apollo.mutate({
        mutation: UpdateChatByIdConversacion,
        variables: {
          token: Token,
          idConversacion: idConversacion,
          status: status,
          statusc: statusc,
          phone: phone,
          lic: lic,
          operador: operador
        }
      }).subscribe(({ data }) => {
        
      }, (error) => {
        console.error('there was an error sending the query', error);
        reject(error)
      });
    })
  }

  public UpdateChatByPhoneAndLic(apollo: any, Data: any, Token: any, _httpClient?: HttpClient) {
    //console.log('esto me llego al UpdateChatByPhoneAndLic: ', Data)

    return new Promise((resolve, reject) => {
      let {
        phone,
        statusc,
        lic,
        status,
        operador,
      } = Data;

      apollo.mutate({
        mutation: UpdateChatByPhoneAndLic,
        variables: {
          token: Token,
          lic: lic,
          phone: phone,
          statusc: statusc,
          status: status,
          operador: operador,
        }
      }).subscribe(({ data }) => {
        console.log('got data', data); resolve(data)
      }, (error) => {
        console.error('there was an error sending the query', error);
        reject(error)
      });
    })
  }

  public cambiarEtiqueta(apollo: any, Data: any, Token: any, _httpClient?: HttpClient) {
    //console.log('esto me llego a la mutation cambiarEtiqueta: ',Data)
    return new Promise((resolve, reject) => {
      console.log("Data")
      console.log(Data)
      let {
        idConversacion,
        etiqueta
      } = Data;
      apollo.mutate({
        mutation: UpdateEtiquetaChat,
        variables: {
          "token": Token,
          "phone": Data.phone,
          "lic": Data.lic,
          "etiqueta": Data.etiqueta
        }
      }).subscribe(({ data }) => {
        //console.log('got data', data);
        resolve(data)
      }, (error) => {
        console.error('there was an error sending the query', error);
        reject(error)
      });
    })
  }

  public reasignarConv(apollo: any, Data: any, Token: any, _httpClient?: HttpClient) {
    /*       console.log('esto me llego a la mutation: ', Data) */
    return new Promise((resolve, reject) => {
      let {
        idConversacion,
        operador
      } = Data;
      apollo.mutate({
        mutation: UpdateChatByIdConversacion,
        variables: {
          token: Token,
          idConversacion: idConversacion,
          operador: operador
        }
      }).subscribe(({ data }) => {
        /*         console.log('got data', data); */
        resolve(data)
      }, (error) => {
        console.error('there was an error sending the query', error);
        reject(error)
      });
    })
  }

  public reasignarConvByPhoneAndLic(apollo: any, Data: any, Token: any, _httpClient?: HttpClient) {
    return new Promise((resolve, reject) => {

      console.log("Token")
      console.log(Token)
      console.log("Data que llego para la reasignacion de chat por lic y phone ")
      console.log(Data)

      apollo.mutate({
        mutation: UpdateChatByPhoneLic,
        variables: {
          "token": Token,
          "Data": {
            "phone": Data.phone,
            "lic": Data.lic,
            "operador": Data.operador
          }
        }
      }).subscribe(({ data }) => {
        //console.log('got data', data); 
        resolve(data)
      }, (error) => {
        console.error('there was an error sending the query', error);
        reject(error)
      });
    })
  }

  /* Inicio Mutation de User */
  public CreateUser(apollo: any, Data: any) {
    return new Promise((resolve, reject) => {
      let { idUser, idEmpresa, idRol, IdHorario, idCliente, Avatar, Nombre, Apellido, Email, Clave, Phone, Direccion, TipoUsuario, idioma, Canales, status, isMaster, Online, Permisos, transferir } = Data;

      console.log('Data en CreateUser')
      console.log(Data)

      apollo.mutate({
        mutation: CreateUsers,
        variables:
        {
          "idUser": idUser,
          "idEmpresa": idEmpresa,
          "idRol": idRol,
          "idHorario": IdHorario,
          "idCliente": idCliente,
          "Avatar": Avatar,
          "Nombre": Nombre,
          "Apellido": Apellido,
          "Email": Email,
          "Clave": Clave,
          "Phone": Phone,
          "Direccion": Direccion,
          "TipoUsuario": TipoUsuario,
          "idioma": idioma,
          "Canales": Canales,
          "status": status,
          "isMaster": isMaster,
          "Online": Online,
          "Permisos": Permisos,
          "transferir": transferir
        }
      }).subscribe(({ data }) => {
        console.log('usuario ' + idUser + ' Creado');
        resolve(data)
      }, (error) => {
        console.error('there was an error sending the query', error);
        reject(error)
      });
    })
  }

  public UpdateUser(apollo: any, Token: any, Data: any) {
    return new Promise((resolve, reject) => {
      let { idUser, idEmpresa, idRol, IdHorario, idCliente, Avatar, Nombre, Apellido, Email, Clave, Phone, Direccion, TipoUsuario, idioma, Canales, status, isMaster, Online, Permisos, transferir } = Data;

      if (Clave == "noInfo" || Clave == "") {
        //console.log("No voy agregar la clave")
        apollo.mutate({
          mutation: UpdateUsers2,
          variables:
          {
            "token": Token,
            "idUser": idUser,
            "idEmpresa": idEmpresa,
            "idRol": idRol,
            "idHorario": IdHorario,
            "idCliente": idCliente,
            "Avatar": Avatar,
            "Nombre": Nombre,
            "Apellido": Apellido,
            "Email": Email,
            "Phone": Phone,
            "Direccion": Direccion,
            "TipoUsuario": TipoUsuario,
            "idioma": idioma,
            "Canales": Canales,
            "status": status,
            "isMaster": isMaster,
            "Online": Online,
            "Permisos": Permisos,
            "transferir": transferir
          }
        }).subscribe(({ data }) => {
          //console.log('usuario ' + idUser + ' Actualizado');
          resolve(data)
        }, (error) => {
          console.error('there was an error sending the query', error);
          reject(error)
        });
      } else {
        //console.log("Voy agregar la clave")
        apollo.mutate({
          mutation: UpdateUsers,
          variables:
          {
            "token": Token,
            "idUser": idUser,
            "idEmpresa": idEmpresa,
            "idRol": idRol,
            "idHorario": IdHorario,
            "idCliente": idCliente,
            "Avatar": Avatar,
            "Nombre": Nombre,
            "Apellido": Apellido,
            "Email": Email,
            "Clave": Clave,
            "Phone": Phone,
            "Direccion": Direccion,
            "TipoUsuario": TipoUsuario,
            "idioma": idioma,
            "Canales": Canales,
            "status": status,
            "isMaster": isMaster,
            "Online": Online,
            "Permisos": Permisos
          }
        }).subscribe(({ data }) => {
          //console.log('usuario ' + idUser + ' Actualizado');
          resolve(data)
        }, (error) => {
          console.error('there was an error sending the query', error);
          reject(error)
        });


      }

    })
  }

  public updateOnOff(apollo: any, Token: any, Data: any) {
    return new Promise((resolve, reject) => {
      let { idUser, Online } = Data;
      apollo.mutate({
        mutation: UpdateUsersO,
        variables:
        {
          "token": Token,
          "Data": {
            "idUser": idUser,
          }
        }
      }).subscribe(({ data }) => {
        /*         console.log('usuario ' + idUser + ' Actualizado');
                console.log(data); */
        resolve(data)
      }, (error: any) => {
        console.error('there was an error sending the query', error);
        reject(error)
      });
    })
  }

  public updateImgPerfil(apollo: any, Token: any, Data: any) {
    return new Promise((resolve, reject) => {
      let { idUser, Avatar } = Data;
      apollo.mutate({
        mutation: UpdateUsersO,
        variables:
        {
          "token": Token,
          "Data": {
            "idUser": idUser,
            "Avatar": Avatar
          }
        }
      }).subscribe(({ data }) => {
        //console.log('usuario ' + idUser + ' Actualizado');
        resolve(data)
      }, (error) => {
        console.error('there was an error sending the query', error);
        reject(error)
      });
    })
  }

  public UpdateUser2(apollo: any, Token: any, Data: any) {
    return new Promise((resolve, reject) => {
      let { idUser, idEmpresa, idRol, IdHorario, idCliente, Avatar, Nombre, Apellido, Email, Clave, Phone, Direccion, TipoUsuario, idioma, Canales, status, isMaster, Online, Permisos } = Data;

      //console.log("No voy agregar la clave")
      apollo.mutate({
        mutation: UpdateUsers2,
        variables:
        {
          "token": Token,
          "idUser": idUser,
          "idEmpresa": idEmpresa,
          "idRol": idRol,
          "idHorario": IdHorario,
          "idCliente": idCliente,
          "Avatar": Avatar,
          "Nombre": Nombre,
          "Apellido": Apellido,
          "Email": Email,
          "Phone": Phone,
          "Direccion": Direccion,
          "TipoUsuario": TipoUsuario,
          "idioma": idioma,
          "Canales": Canales,
          "status": status,
          "isMaster": isMaster,
          "Online": Online,
          "Permisos": Permisos
        }
      }).subscribe(({ data }) => {
        //console.log('usuario ' + idUser + ' Actualizado');
        resolve(data)
      }, (error) => {
        console.error('there was an error sending the query', error);
        reject(error)
      });


    })
  }

  public UpdateUserHorario(apollo: any, Token: any, Data: any) {
    return new Promise((resolve, reject) => {
      let { idUser, IdHorario } = Data;

      apollo.mutate({
        mutation: UpdateUsersH,
        variables:
        {
          "token": Token,
          "Data": {
            "idUser": idUser,
            "idHorario": IdHorario
          }


        }
      }).subscribe(({ data }) => {
        //console.log('usuario ' + idUser + ' Actualizado');
        resolve(data)
      }, (error) => {
        console.error('there was an error sending the query', error);
        reject(error)
      });


    })
  }

  public UpdateAgent(apollo: any, Token: any, Data: any) {
    return new Promise((resolve, reject) => {
      console.log("La data para actualizar al agente");
      console.log({ ...Data });
      apollo.mutate({
        mutation: UpdateAgent,
        variables:
        {
          "token": Token,
          "Data":
            { ...Data }



        }
      }).subscribe(({ data }) => {
        //console.log('usuario ' + idUser + ' Actualizado');
        resolve(data)
      }, (error) => {
        console.error('there was an error sending the query', error);
        reject(error)
      });


    })
  }

  public UpdateUserOnline(apollo: any, Token: any, Data: any) {
    return new Promise((resolve, reject) => {
      let { idUser, online } = Data;
      let OnlineUser: Boolean
      console.log('Data en la mutation de offline')
      console.log(Data)
      console.log('Online: ' + online)
      if (online == 'adjust/green') {
        //online = true;
        OnlineUser = true
      } else if (online == 'adjust/red') {
        //online = false
        OnlineUser = false
      } else {
        OnlineUser = Data.Online || Data.online
      }

      console.log('Online2: ' + online)

      apollo.mutate({
        mutation: UpdateUsersO,
        variables:
        {
          "token": Token,
          "Data": {
            "idUser": idUser,
            "Online": OnlineUser
          }

        }
      }).subscribe(({ data }) => {
        console.log(data)
        //console.log('usuario ' + idUser + ' Actualizado');
        resolve(data)
      }, (error) => {
        console.error('there was an error sending the query', error);
        reject(error)
      });


    })
  }

  public UpdatePasswordUser(Token: string, apollo: any, Data: any) {
    return new Promise((resolve, reject) => {

      let { IdUser, PassActual, NewPass, NoPass } = Data;

      apollo.mutate({
        mutation: UpdatePassUser,
        variables: {
          token: Token,
          IdUser: IdUser,
          PassActual: PassActual,
          NewPass: NewPass,
          NoPass: NoPass
        }
      }).subscribe(({ data }) => {
        //console.log('Data despues del update: ', data);
        //console.log('Se ha cambiado la clave del usuario ' + IdUser)
        resolve(data)
      }, (error) => {
        console.error('there was an error sending the query', error);
        reject(error)
      });
    })

  }
  public DeleteUser(apollo: any, token: any, idUser: String) {//Para eliminar un usuario
    return new Promise((resolve, reject) => {
      apollo.mutate({
        mutation: DeleteUsers,
        variables: {
          Token: token,
          idUser: idUser
        }
      }).subscribe(({ data }) => {
        //console.log('User ' + idUser + ' Eliminado Con Exito');
        resolve(data)
      }, (error) => {
        console.error('there was an error sending the query', error);
        reject(error)
      });
    })

  }
  /* Fin de Mutation de User */

  /* Inicio de mutation de Tecnicos */
  public CreateTecnico(apollo: any, Token: any, Data: any) {
    return new Promise((resolve, reject) => {
      let { idTecnico, idCliente, idContratista, idEmpresa, nombre, apellido, cedula, telef1, telef2, idVehiculo, email, direccion, avatar, idApp, VencimientoLic } = Data;
      apollo.mutate({
        mutation: CreateTecnico,
        variables: {
          "token": Token,
          "Data": {
            "idTecnico": idTecnico,
            "idContratista": idContratista,
            "idCliente": idCliente,
            "idEmpresa": idEmpresa,
            "idApp": idApp,
            "nombre": nombre,
            "apellido": apellido,
            "avatar": avatar,
            "cedula": cedula,
            "telef1": telef1,
            "telef2": telef2,
            "idVehiculo": idVehiculo,
            "email": email,
            "direccion": direccion,
            "VencimientoLic": VencimientoLic
          }
        }
      }).subscribe(({ data }) => {
        //console.log(Data)
        //console.log('Horario ' + idHorario + ' Creado');
        resolve(data)
      }, (error) => {
        console.error('Error in CreateTecnico', error);
        reject(error)
      });
    })
  }
  public UpdateTecnico(apollo: any, Token: any, Data: any) {
    return new Promise((resolve, reject) => {
      let { idTecnico, idCliente, idContratista, idEmpresa, nombre, apellido, cedula, telef1, telef2, idVehiculo, email, direccion, avatar, idApp, VencimientoLic } = Data;

      apollo.mutate({
        mutation: UpdateTecnicos,
        variables: {
          "token": Token,
          "Data": {
            "idTecnico": idTecnico,
            "idContratista": idContratista,
            "idCliente": idCliente,
            "idEmpresa": idEmpresa,
            "idApp": idApp,
            "nombre": nombre,
            "apellido": apellido,
            "avatar": avatar,
            "cedula": cedula,
            "telef1": telef1,
            "telef2": telef2,
            "idVehiculo": idVehiculo,
            "email": email,
            "direccion": direccion,
            "VencimientoLic": VencimientoLic
          }
        }
      }).subscribe(({ data }) => {
        //console.log(Data)
        //console.log('Horario ' + idHorario + ' Creado');
        resolve(data)
      }, (error) => {
        console.error('Error in UpdateTecnico', error);
        reject(error)
      });
    })
  }
  public DeleteTecnico(apollo: any, Token: any, idTecnico: any) {
    return new Promise((resolve, reject) => {
      apollo.mutate({
        mutation: DeleteTecnicos,
        variables: {
          "token": Token,
          "idTecnico": idTecnico
        }
      }).subscribe(({ data }) => {
        resolve(data)
      }, (error) => {
        console.error('Error in DeleteTecnico', error);
        reject(error)
      });
    })
  }
  /* Fin de Mutation de Tecnicos */

  /* Inicio de mutation de Vehiculos */
  public CreateVehiculo(apollo: any, Token: any, Data: any) {
    return new Promise((resolve, reject) => {
      let { idCliente, idVehiculo, idContratista, idEmpresa, vehiculo, marca, modelo, color, placa, Tipo, PolizaSeguro } = Data;
      apollo.mutate({
        mutation: CreateVehiculos,
        variables: {
          "token": Token,
          "Data": {
            "idVehiculo": idVehiculo,
            "idContratista": idContratista,
            "idCliente": idCliente,
            "idEmpresa": idEmpresa,
            "vehiculo": vehiculo,
            "marca": marca,
            "modelo": modelo,
            "color": color,
            "placa": placa,
            "Tipo": Tipo,
            "PolizaSeguro": PolizaSeguro
          }
        }
      }).subscribe(({ data }) => {

        resolve(data)
      }, (error) => {
        console.error('Error In Create Vehiculo', error);
        reject(error)
      });
    })
  }
  public UpdateVehiculo(apollo: any, Token: any, Data: any) {
    return new Promise((resolve, reject) => {
      let { idCliente, idVehiculo, idContratista, idEmpresa, vehiculo, marca, modelo, color, placa, Tipo, PolizaSeguro } = Data;
      apollo.mutate({
        mutation: UpdateVehiculos,
        variables: {
          "token": Token,
          "Data": {
            "idVehiculo": idVehiculo,
            "idContratista": idContratista,
            "idCliente": idCliente,
            "idEmpresa": idEmpresa,
            "vehiculo": vehiculo,
            "marca": marca,
            "modelo": modelo,
            "color": color,
            "placa": placa,
            "Tipo": Tipo,
            "PolizaSeguro": PolizaSeguro
          }
        }
      }).subscribe(({ data }) => {

        resolve(data)
      }, (error) => {
        console.error('Error In Update Vehiculo', error);
        reject(error)
      });
    })
  }
  public DeleteVehiculo(apollo: any, Token: any, idVehiculo: any) {
    return new Promise((resolve, reject) => {
      apollo.mutate({
        mutation: DeleteVehiculos,
        variables: {
          "token": Token,
          "idVehiculo": idVehiculo
        }
      }).subscribe(({ data }) => {
        resolve(data)
      }, (error) => {
        console.error('Error in Deletevehiculo', error);
        reject(error)
      });
    })
  }
  /* Fin de Mutation de Vehiculos */

  /* Inicio de mutation de Contratistas */
  public CreateContratista(apollo: any, Token: any, Data: any) {
    return new Promise((resolve, reject) => {
      let { idEmpresa, idContratista, idCliente, nombre, rif, telef, personaContacto, email, Direccion } = Data;
      apollo.mutate({
        mutation: CreateContratistas,
        variables: {
          "token": Token,
          "Data": {
            "idContratista": idContratista,
            "idCliente": idCliente,
            "idEmpresa": idEmpresa,
            "nombre": nombre,
            "rif": rif,
            "telef": telef,
            "personaContacto": personaContacto,
            "email": email,
            "Direccion": Direccion
          }
        }
      }).subscribe(({ data }) => {

        resolve(data)
      }, (error) => {
        console.error('Error In Create Contratista', error);
        reject(error)
      });
    })
  }
  public UpdateContratista(apollo: any, Token: any, Data: any) {
    return new Promise((resolve, reject) => {
      let { idEmpresa, idContratista, idCliente, nombre, rif, telef, personaContacto, email, Direccion } = Data;
      apollo.mutate({
        mutation: UpdateContratistas,
        variables: {
          "token": Token,
          "Data": {
            "idContratista": idContratista,
            "idCliente": idCliente,
            "idEmpresa": idEmpresa,
            "nombre": nombre,
            "rif": rif,
            "telef": telef,
            "personaContacto": personaContacto,
            "email": email,
            "Direccion": Direccion
          }
        }
      }).subscribe(({ data }) => {

        resolve(data)
      }, (error) => {
        console.error('Error In Update Contratista', error);
        reject(error)
      });
    })
  }
  public DeleteContratista(apollo: any, Token: any, idContratista: any) {
    return new Promise((resolve, reject) => {
      apollo.mutate({
        mutation: DeleteContratistas,
        variables: {
          "token": Token,
          "idContratista": idContratista
        }
      }).subscribe(({ data }) => {
        resolve(data)
      }, (error) => {
        console.error('Error in DeleteContratista', error);
        reject(error)
      });
    })
  }
  /* Fin de Mutation de Contratistas */

  /** Mutation  Log*/
  public CreateLog(apollo: any, Token: any, Accion: any) {
    return new Promise((resolve, reject) => {

      //console.log(Token)

      apollo.mutate({
        mutation: RegistrarLog,
        variables:
        {
          token: Token,
          accion: Accion
        }
      }).subscribe(({ data }) => {
        //console.log('Cliente  Creado');
        resolve(data)
      }, (error: any) => {
        console.error('there was an error sending the query', error);
        reject(error)
      });
    })
  }

  /**Fin de Mutation de Log */
}

/*Mutation */


//Log
const RegistrarLog = gql`
  mutation Log($token:String,$accion:String){
    CreateLogErrores(token:$token,Accion:$accion){
      IdUser
      IdEmpresa
      Accion
      Fecha
      Ip
      error
    }
}
`

//Cliente
const CreateClientes = gql`
mutation createCliente($token:String,$input:ClientesEmpresaInput){
    createCliente(token:$token,Cliente:$input){
    idCliente
    NombreCliente
    Direccion
    licencia
    correo
    rif
    telefono
    prefijo
    metodo_pago
    logo
    active
  }
  }
`
const UpdateCliente = gql`
mutation UpdateClientes(
  $token:String
  $IdCliente:String
  $NombreCliente:String
  $Direccion:String
  $Pais:String
  $correo:String
  $rif:String
  $telefono:String
  $prefijo:String
  $metodo_pago:Int
  $logo:String
  $active:Boolean
  ){
    UpdateClientes(
      token:$token
      Data:{
        IdCliente:$IdCliente
        NombreCliente:$NombreCliente
        Direccion:$Direccion
        Pais:$Pais
        correo:$correo
        rif:$rif
        telefono:$telefono
        prefijo:$prefijo
        metodo_pago:$metodo_pago
        logo:$logo
        active:$active
      }
    ){
      idCliente
      NombreCliente
      Direccion
      licencia
      correo
      rif
      telefono
      prefijo
      metodo_pago
      logo
      active
      Pais
    }
}`


//Atajos
const CreateAtajo = gql`
mutation CreateAtajos(
  $token:String,
  $IdCliente: String!,
  $id: String!,
  $abreviado: String!,
  $texto: String!,
  $Canales: [String],
  $Ubicacion: [String]){
    CreateAtajosCliente(token:$token,
    DAtajos:{
      IdCliente:$IdCliente,
      id:$id,
      abreviado:$abreviado,
      texto:$texto,
      Canales:$Canales,
      Ubicacion: $Ubicacion
    }){
      id
      abreviado
      texto
    }
  }
  `
const UpdateAtajos = gql`
  mutation UpdateAtajos(
    $token:String,
    $IdCliente: String!,
    $id: String!,
    $abreviado: String!,
    $texto: String!,
    $Canales: [String],
    $Ubicacion: [String]){
      UpdateAtajosCliente(token:$token,
      DAtajos:{
        IdCliente:$IdCliente,
        id:$id,
        abreviado:$abreviado,
        texto:$texto,
        Canales:$Canales,
        Ubicacion: $Ubicacion
      }){
        id
        abreviado
        texto
      }
    }
    `
const DeleteAtajo = gql`
mutation DeleteAtajosCliente($token:String, $IdCliente: String!, $id: String!){
  DeleteAtajosCliente(
    token:$token
    DataAtajos:{
      IdCliente:$IdCliente
      id:$id
    }
  ){
    Status
  }
}
`

//Campos
//Nota: se debe cambiar en el backend que ubicación sea un arreglo de String
const CreateCampos = gql`
mutation CreateCamposCliente(
  $token:String, 
  $IdCliente:String!, 
  $id:String, 
  $title:String, 
  $tipo:String,
  $requerido:Boolean, 
  $ubicacion:[String], 
  $canales:[String]
  ){
    CreateCamposCliente(
      token:$token,
      DCampos:{      
        IdCliente:$IdCliente
        id:$id
        title:$title
        tipo:$tipo
        requerido:$requerido
        ubicacion:$ubicacion
        Canales:$canales
      }
    )
  {
    id
    title
    tipo
  }
}
`
const UpdateCampos = gql`
mutation UpdateCamposCliente(
  $token:String,
  $IdCliente:String!,
  $id:String,
  $title:String,
  $tipo:String,
  $requerido:Boolean,
  $ubicacion:[String],
  $canales:[String]
  ){
    UpdateCamposCliente(
      token:$token
      DCampos:{
        IdCliente:$IdCliente,
        id:$id,
        title:$title,
        tipo:$tipo,
        requerido:$requerido,
        ubicacion:$ubicacion,
        Canales:$canales
      }
    )
    {
      id
      title
      tipo
    }
  }
`
const DeleteCampo = gql`
mutation DeleteCamposCliente($token:String, $IdCliente: String!, $id: String!){
  DeleteCamposCliente(
    token:$token
    DataCampos:{
      IdCliente:$IdCliente
      id:$id
    }
  ){
    Status
  }
}
`

//Etiquetas
const CreateEtiquetas = gql`
    mutation CreateEtiquetas($token:String,$IdCliente:String!,$id:String!,
      $word:String!,$idCanal:[String]){
        CreateEtiquetasCliente(token:$token,
        DEtiquetas:{
          IdCliente:$IdCliente,
          id:$id,
          word:$word,
          idCanal:$idCanal
        }){
          id
          word
        }
      }
      `
const UpdateEtiquetas = gql`
      mutation UpdateEtiquetas($token:String,$IdCliente:String!,$id:String!,
        $word:String!,$idCanal:[String]){
          UpdateEtiquetasCliente(token:$token,
          DEtiquetas:{
            IdCliente:$IdCliente,
            id:$id,
            word:$word,
            idCanal:$idCanal
          }){
            id
            word
          }
        }
        `
const DeleteEiquetas = gql`
mutation DeleteEtiquetasCliente($token:String, $IdCliente: String!, $id: String!){
  DeleteEtiquetasCliente(
    token:$token
    DataEtiquetas:{
      IdCliente:$IdCliente
      id:$id
    }
  ){
    Status
  }
}
`

//Roles
const DeleteRoles = gql`
mutation DeleteRol($Token:String,$IdRol:String){
    DeleteRol(
        token:$Token
        IdRol:$IdRol
    ){
        IdRol
    }
}
`

//Horarios
const CreateHorarios = gql`
mutation CreateHorariosCliente(
  $token:String, 
  $IdCliente:String!, 
  $idHorario:String!
  $Descripcion:String!
  $DiaInicio:Int
  $DiaFin:Int
  $HoraInicio:String
  $HoraFin:String
  $DescripcionEx:String
  $Fecha:String
  ){
    CreateHorariosCliente(
      token:$token
      DHorarios:{
        IdCliente:$IdCliente
        idHorario:$idHorario
        Descripcion:$Descripcion
        Jornadas:{
          DiaInicio:$DiaInicio
          DiaFin:$DiaFin
          HoraInicio:$HoraInicio
          HoraFin:$HoraFin
        }
        Excepciones:{
          Descripcion:$DescripcionEx
          Fecha:$Fecha
        }
      }
    ){
      idCliente
      idHorario
      Descripcion
    }
  }
`
const CreateHorarios2 = gql`
mutation CreateHorariosCliente($token:String,$input:HorariosInput){
  CreateHorariosCliente(token:$token,DHorarios:$input){
    idCliente
    idHorario
    Descripcion
    Jornadas{
      DiaInicio
      DiaFin
      HoraInicio
      HoraFin
    }
    Excepciones{
      Descripcion
      Fecha
    }
  }
}
`
const UpdateHorarios = gql`
mutation UpdateHorariosCliente(
  $token:String,
  $IdCliente:String!,
  $idHorario:String!,
  $Descripcion:String!,
  $DiaInicio:Int,
  $DiaFin:Int,
  $HoraInicio:String,
  $HoraFin:String,
  $DescripcionEx:String,
  $Fecha:String
  ){
    UpdateHorariosCliente(
      token:$token
      DHorarios:{
        IdCliente:$IdCliente
        idHorario:$idHorario
        Descripcion:$Descripcion
        Jornadas:{
          DiaInicio:$DiaInicio
          DiaFin:$DiaFin
          HoraInicio:$HoraInicio
          HoraFin:$HoraFin
        }
        Excepciones:{
          Descripcion:$DescripcionEx
          Fecha:$Fecha
        }
      }
    ){
      idCliente
      idHorario
      Descripcion
    }
  }
`
const UpdateHorarios2 = gql`
mutation UpdateHorariosCliente($token:String,$input:HorariosInput){
  UpdateHorariosCliente(token:$token,DHorarios:$input){
    idCliente
    idHorario
    Descripcion
    Jornadas{
      DiaInicio
      DiaFin
      HoraInicio
      HoraFin
    }
    Excepciones{
      Descripcion
      Fecha
    }
  }
}
`
const DeleteHorarios = gql`
mutation DeleteHorariosCliente($Token:String,$IdCliente:String!, $IdHorario:String!){
  DeleteHorariosCliente(
    token:$Token
    DataHorarios:{
      IdCliente:$IdCliente
      idHorario:$IdHorario
    }
  ){
    Status
  }
}
`

//Canales
const CreateCanales = gql`
mutation CreateCanalesCliente(
  $token:String, 
  $IdCliente:String,
  $lic:String,
  $type:String,
  $version:String,
  $totalmsg:Int,
  $online:Boolean,
  $status:Boolean,
  $bateria:String
  ){
    CreateCanalesCliente(
      token:$token
      DCanales:{
        IdCliente:$IdCliente
        lic:$lic
        type:$type
        version:$version
        totalmsg:$totalmsg
        online:$online
        status:$status
        bateria:$bateria
      }
    ){
      lic
      type
      version
      totalmsg
      online
      status
      bateria
      ultconex
    }
  }
`
const UpdateCanales = gql`
mutation UpdateCanalesCliente(
  $token:String, 
  $IdCliente:String,
  $lic:String,
  $type:String,
  $version:String,
  $totalmsg:Int,
  $online:Boolean,
  $status:Boolean,
  $bateria:String
  ){
    UpdateCanalesCliente(
      token:$token
      DCanales:{
        IdCliente:$IdCliente
        lic:$lic
        type:$type
        version:$version
        totalmsg:$totalmsg
        online:$online
        status:$status
        bateria:$bateria
      }
    ){
      lic
      type
      version
      totalmsg
      online
      status
      bateria
      ultconex
    }
  }
`
const DeleteCanales = gql`
mutation DeleteCanalesCliente($Token:String,$IdCliente:String, $lic:String){
  DeleteCanalesCliente(
    token:$Token
    DataCanales:{
      IdCliente:$IdCliente
      lic:$lic
    }
  ){
    Status
  }
}
`

//Galeria
const CreateGalerias = gql`
mutation CreateGaleriaCliente(
  $token:String,
  $IdCliente:String,
  $id:String,
  $owner:String,
  $title:String,
  $url:String,
  $message:String,
  $size:String,
  $created:String,
  $modified:String,
  $available:Boolean
  ){
    CreateGaleriaCliente(
      token:$token
      DGaleria:{
        IdCliente:$IdCliente
        id:$id
        owner:$owner
        title:$title
        url:$url
        message:$message
        size:$size
        created:$created
        modified:$modified
        available:$available
      }
    ){
      id
      idEmpresa
      owner
      title
      url
      message
      size
      created
      modified
      available
    }
  }
`
const CreateGalerias2 = gql`
mutation CreateGalery($token:String, $Data:GaleriasInput){
  CreateGaleriaCliente(token:$token, DGaleria:$Data){
    id
    owner
    title
    url
    message
    size
    created
    modified
    available
    error
  }
}
`
const UpdateGalerias = gql`
mutation UpdateGaleriaCliente(
  $token:String,
  $IdCliente:String,
  $id:String,
  $owner:String,
  $title:String,
  $url:String,
  $message:String,
  $size:String,
  $created:String,
  $modified:String,
  $available:Boolean
  ){
    UpdateGaleriaCliente(
      token:$token
      DGaleria:{
        IdCliente:$IdCliente
        id:$id
        owner:$owner
        title:$title
        url:$url
        message:$message
        size:$size
        created:$created
        modified:$modified
        available:$available
      }
    ){
      id
      idEmpresa
      owner
      title
      url
      message
      size
      created
      modified
      available
    }
  }
`
const UpdateGalerias2 = gql`
mutation UpdateGalery($token:String, $Data:GaleriasInput){
  UpdateGaleriaCliente(token:$token, DGaleria:$Data){
    id
    owner
    title
    url
    message
    size
    created
    modified
    available
    error
  }
}
`
const DeleteGalerias = gql`
mutation DeleteGaleria($token:String,$Data:DeleteGHCAECliente){
  DeleteGaleriaCliente(token:$token, DataGaleria:$Data){
    Status
  }
}
`

//Bot
const CreateBots = gql`
mutation CreateBotCliente(
  $token:String,
  $IdCliente:String,
  $idBot:Int,
  $idCanal:[String],
  $contains:[String],
  $exact:[String],
  $tipo:String,
  $response:String,
  $apiURL:String,
  $bData:Boolean,
  $noMatch:String
  ){
    CreateBotCliente(
      token:$token
      DBot:{
        IdCliente:$IdCliente
        idBot:$idBot
        idCanal:$idCanal
        bot:{
          contains:$contains
          exact:$exact
          tipo:$tipo
          response:$response
          apiURL:$apiURL
          bData:$bData
        }
        noMatch:$noMatch
      }
    ){
      idBot
      idCanal
      noMatch
    }
  }
`
const UpdateBots = gql`
mutation UpdateBotCliente(
  $token:String,
  $IdCliente:String,
  $idBot:Int,
  $idCanal:[String],
  $contains:[String],
  $exact:[String],
  $tipo:String,
  $response:String,
  $apiURL:String,
  $bData:Boolean,
  $noMatch:String
  ){
    UpdateBotCliente(
      token:$token
      DBot:{
        IdCliente:$IdCliente
        idBot:$idBot
        idCanal:$idCanal
        bot:{
          contains:$contains
          exact:$exact
          tipo:$tipo
          response:$response
          apiURL:$apiURL
          bData:$bData
        }
        noMatch:$noMatch
      }
    ){
      idBot
      idCanal
      noMatch
    }
  }
`
const DeleteBots = gql`
mutation DeleteBotCliente($Token:String, $IdCliente:String, $idBot:Int){
  DeleteBotCliente(
    token:$Token
    DataBot:{
      IdCliente:$IdCliente
      idBot:$idBot
    }
  ){
    Status
  }
}
`

//Contactos
const CreateContactos = gql`
mutation CreateContact(
  $token:String
  $id:String
  $idCliente:String
  $idEmpresa:String
  $idCanal:[String]
  $name:String
  $lastName:String
  $avatar:String
  $code:String
  $extra:String
  $status:Boolean
  $phone:String
  $Canal:String
  $email:String
  $birthday:String
  $company:String
  $address:String
  $notes:String
  ){
    CreateContact(token:$token, input:{
    id:$id
    idCliente:$idCliente
    idEmpresa:$idEmpresa
    idCanal:$idCanal
    name:$name
    lastName:$lastName
    avatar:$avatar
    code:$code
    extra:$extra
    status:$status
    phone:$phone
    Canal:$Canal
    email:$email
    birthday:$birthday
    company:$company
    address:$address
    notes:$notes
  }){
    id
  }
  }
`
const UpdateContactos = gql`
mutation UpdateContact(
  $token:String
  $id:String
  $idCliente:String
  $idEmpresa:String
  $idCanal:[String]
  $name:String
  $lastName:String
  $avatar:String
  $code:String
  $extra:String
  $status:Boolean
  $phone:String
  $Canal:String
  $email:String
  $birthday:String
  $company:String
  $address:String
  $notes:String
  $campos:String
  ){
    UpdateContact(token:$token, Data:{
    id:$id
    idCliente:$idCliente
    idEmpresa:$idEmpresa
    idCanal:$idCanal
    name:$name
    lastName:$lastName
    avatar:$avatar
    code:$code
    extra:$extra
    status:$status
    phone:$phone
    Canal:$Canal
    email:$email
    birthday:$birthday
    company:$company
    address:$address
    notes:$notes
    campos:$campos
  }){
    id
  }
  }
`
const UpdateCamposContactos = gql`
mutation UpdateContact(
  $token:String
  $id:String
  $campos:String
  ){
    UpdateContact(token:$token, Data:{
    id:$id
    campos:$campos
  }){
    id
    campos
  }
  }
`

const UpdateFotoContacto = gql`
mutation UpdateContact(
  $token:String
  $id:String
  $avatar:String
  ){
    UpdateContact(token:$token, Data:{
    id:$id
    avatar:$avatar
  }){
    id
  }
  }
`
const DeleteContactos = gql`
mutation DeleteContacto($token:String,$idContacto:String){
  DeleteContacto(token:$token
  idContacto:$idContacto)
}
`
//category:$category
//"$category:[String]"


//Chat
const CreateChat = gql`
mutation CreateChat(
  $idEmpresa:String
 $phone:String
 $idConversacion:String
 $idmessage:String
 $timestamp:Int
 $me_to:String
 $contentType:String
 $content:String
 $TimeRead:String
 $status:Int
 $statusc:Int
 $readmsg:Boolean
 $operador:String
 $lic:String
  ){
    CreateChat(Chat:{    
    idEmpresa: $idEmpresa
    phone: $phone  
    idConversacion: $idConversacion 
    idmessage: $idmessage
    timestamp: $timestamp
    me_to: $me_to
    contentType: $contentType
    content: $content
    TimeRead: $TimeRead
    status: $status
    statusc: $statusc
    readmsg: $readmsg
    operador: $operador
    lic: $lic
  }){
    idEmpresa 
    phone 
    idConversacion 
    idmessage 
    timestamp 
    me_to 
    contentType 
    content 
    TimeRead 
    status 
    statusc 
    readmsg 
    operador
    lic
  }
  }
  `
const UpdateChatByPhoneAndLic = gql`
mutation UpdateChatByPhoneAndLic (
  $token:String
  $phone:String
  $idConversacion:String
  $statusc:Int
  $lic:String
){
  UpdateChatByPhoneAndLic(
    token:$token
    Data:{
         phone:$phone
        idConversacion: $idConversacion
        statusc:$statusc
        lic:$lic
    }
  ){
        phone
        idConversacion
        statusc
        lic
  }
}
`

const UpdateEtiquetaChat = gql`
mutation UpdateChatByPhoneAndLic (
  $token:String
  $phone:String
  $lic:String
  $etiqueta:String
){
  UpdateChatByPhoneAndLic(
    token:$token
    Data:{
      phone:$phone
      lic:$lic
      etiqueta:$etiqueta
    }
  ){
    phone
    lic
    etiqueta
  }
}
`

const UpdateChatByIdConversacion = gql`
mutation UpdateChatByIdConversacion(
 $token:String
 $idEmpresa:String
 $phone:String
 $idConversacion:String
 $idmessage:String
 $timestamp:Int
 $me_to:String
 $contentType:String
 $content:String
 $TimeRead:String
 $status:Int
 $statusc:Int
 $readmsg:Boolean
 $operador:String
 $lic:String
 $etiqueta:String
  ){
    UpdateChatByIdConversacion(
      token:$token
      Data:{    
    idEmpresa : $idEmpresa
    phone : $phone  
    idConversacion : $idConversacion 
    idmessage : $idmessage
    timestamp : $timestamp
    me_to : $me_to
    contentType : $contentType
    content : $content
    TimeRead : $TimeRead
    status : $status
    statusc : $statusc
    readmsg : $readmsg
    operador : $operador
    lic : $lic
    etiqueta:$etiqueta
  })
  }
  `

const UpdateChatByPhoneLic = gql`
mutation UpdateChatByPhoneAndLic($token:String, $Data:ChatsInput){
  UpdateChatByPhoneAndLic(token:$token,Data:$Data){
    idEmpresa
    phone
    idConversacion
    idmessage
    timestamp
    me_to
    contentType
    content
    TimeRead
    status
    statusc
    readmsg
    operador
    lic
    tag
    etiqueta
    priority
    DatePc
  }
}
  `

//chatsCola
const CreateChatCola = gql`
mutation CreateChatCola(
  $idEmpresa:String
  $phone:String
  $idConversacion:String
  $idmessage:String
  $timestamp:Int
  $me_to:String
  $contentType:String
  $content:String
  $TimeRead:String
  $status:Int
  $statusc:Int
  $readmsg:Boolean
  $operador:String
  $lic:String
  $etiqueta:String
  ){
    CreateChatCola(
      Data:{ 
        idEmpresa:$idEmpresa
        phone:$phone
        idConversacion:$idConversacion
        idmessage:$idmessage
        timestamp:$timestamp
        me_to:$me_to
        contentType:$contentType
        content:$content
        TimeRead:$TimeRead
        status:$status
        statusc:$statusc
        readmsg:$readmsg
        operador:$operador
        lic:$lic
        etiqueta:$etiqueta
      }
    ){
      idEmpresa
      phone
      idConversacion
      idmessage
      timestamp
      me_to
      contentType
      content
      TimeRead
      status
      statusc
      readmsg
      etiqueta
      operador
      lic
      tag
      DatePc
      priority
    }
  }
`

//Users
const CreateUsers = gql`
mutation createUser(
  $idUser:String
  $idEmpresa:String
  $idRol:String
  $idHorario:[String]
  $idCliente:[String]
  $Avatar:String
  $Nombre:String
  $Apellido:String
  $Email:String
  $Clave:String
  $Phone:String
  $Direccion:String
  $TipoUsuario:String
  $idioma:String
  $Canales:[String]
  $status:Boolean
  $isMaster:Boolean
  $Online:Boolean
  $Permisos:[String]
  ){
    createUser(input:{
      idUser:$idUser
      idEmpresa:$idEmpresa
      idRol:$idRol
      idHorario:$idHorario
      idCliente:$idCliente
      Avatar:$Avatar
      Nombre:$Nombre
      Apellido:$Apellido
      Email:$Email
      Clave:$Clave
      Phone:$Phone
      Direccion:$Direccion
      TipoUsuario:$TipoUsuario
      idioma:$idioma
      Canales:$Canales
      status:$status
      isMaster:$isMaster
      Online:$Online
      Permisos:$Permisos
    })
    {
      idUser
    }
  }
`
const UpdateUsers = gql`
mutation UpdateUser(
  $token:String
  $idUser:String
  $idEmpresa:String
  $idRol:String
  $idHorario:[String]
  $idCliente:[String]
  $Avatar:String
  $Nombre:String
  $Apellido:String
  $Email:String
  $Clave:String
  $Phone:String
  $Direccion:String
  $TipoUsuario:String
  $idioma:String
  $Canales:[String]
  $status:Boolean
  $isMaster:Boolean
  $Online:Boolean
  $Permisos:[String]
  ){
    UpdateUser(
      token:$token
      Data:{
        idUser:$idUser
        idEmpresa:$idEmpresa
        idRol:$idRol
        idHorario:$idHorario
        idCliente:$idCliente
        Avatar:$Avatar
        Nombre:$Nombre
        Apellido:$Apellido
        Email:$Email
        Clave:$Clave
        Phone:$Phone
        Direccion:$Direccion
        TipoUsuario:$TipoUsuario
        idioma:$idioma
        Canales:$Canales
        status:$status
        isMaster:$isMaster
        Online:$Online
        Permisos:$Permisos
      }
    ){
      idUser
    }
  }
`
const UpdateUsers2 = gql`
mutation UpdateUser(
  $token:String
  $idUser:String
  $idEmpresa:String
  $idRol:String
  $idHorario:[String]
  $idCliente:[String]
  $Avatar:String
  $Nombre:String
  $Apellido:String
  $Email:String
  $Phone:String
  $Direccion:String
  $TipoUsuario:String
  $idioma:String
  $Canales:[String]
  $status:Boolean
  $isMaster:Boolean
  $Online:Boolean
  $Permisos:[String]
  ){
    UpdateUser(
      token:$token
      Data:{
        idUser:$idUser
        idEmpresa:$idEmpresa
        idRol:$idRol
        idHorario:$idHorario
        idCliente:$idCliente
        Avatar:$Avatar
        Nombre:$Nombre
        Apellido:$Apellido
        Email:$Email
        Phone:$Phone
        Direccion:$Direccion
        TipoUsuario:$TipoUsuario
        idioma:$idioma
        Canales:$Canales
        status:$status
        isMaster:$isMaster
        Online:$Online
        Permisos:$Permisos
      }
    ){
      idUser
    }
  }
`

const UpdateUsersH = gql`
mutation UpdateUser($token:String,$Data:UsuarioInput)
{
  UpdateUser(token:$token,Data:$Data){
    idUser
    idHorario  
  }
}
`

const UpdateAgent = gql`
mutation UpdateUser($token:String,$Data:UsuarioInput)
{
  UpdateUser(token:$token,Data:$Data){
    idUser
    idEmpresa
    Canales 
  }
}
`

const UpdateUsersO = gql`
mutation UpdateUser($token:String,$Data:UsuarioInput)
{
  UpdateUser(token:$token,Data:$Data){
    idUser
  }
}
`

const UpdatePassUser = gql`
mutation UpdateUser(
  $token:String,
  $IdUser:String,
  $PassActual:String,
  $NewPass:String,
  $NoPass:Boolean
  ){
    UpdatePasswordUser(
      token: $token
      Data:{
        IdUser:$IdUser
        PassActual:$PassActual
        NewPass:$NewPass,
        NoPass:$NoPass
      })
  }
`
const DeleteUsers = gql`
mutation DeleteUsers($Token:String,$idUser:String){
  DeleteUsers(
    token:$Token
    idUser:$idUser
  )
}
`

//Tecnicos
const CreateTecnico = gql`
mutation CreateTecnico($token:String,$Data:tecnicosinput){
  CreateTecnico(token:$token, Data:$Data){
    idTecnico
    idContratista
    idCliente
    idEmpresa
    nombre
    apellido
  }
}
`
const UpdateTecnicos = gql`
mutation UpdateTecnico($token:String,$Data:tecnicosinput){
  UpdateTecnico(token:$token, Data:$Data){
    idTecnico
    idContratista
    idCliente
    idEmpresa
    idApp
    nombre
    apellido
  }
}
`
const DeleteTecnicos = gql`
mutation DeleteTecnico($token:String,$idTecnico:String){
  DeleteTecnico(token:$token, idTecnico:$idTecnico)
}
`

//Vehiculos
const CreateVehiculos = gql`
mutation CreateVehiculo($token:String,$Data:vehiculosinput){
  CreateVehiculo(token:$token, Data:$Data){
    idVehiculo
    idContratista
    idCliente
    idEmpresa
    vehiculo
    marca
  }
}
`

const UpdateVehiculos = gql`
mutation UpdateVehiculo($token:String,$Data:vehiculosinput){
  UpdateVehiculo(token:$token, Data:$Data){
    idVehiculo
    idContratista
    idCliente
    idEmpresa
    vehiculo
    marca
  }
}
`
const DeleteVehiculos = gql`
mutation DeleteVehiculos($token:String,$idVehiculo:String){
  DeleteVehiulo(token:$token, idVehiculo:$idVehiculo)
}
`

//Contratistas
const CreateContratistas = gql`
mutation CreateContratista($token:String,$Data:contratistasinput){
  CreateContratista(token:$token, Data:$Data){
    idContratista
    idCliente
    idEmpresa
    nombre
    rif
    telef
  }
}
`

const UpdateContratistas = gql`
mutation UpdateContratista($token:String,$Data:contratistasinput){
  UpdateContratista(token:$token, Data:$Data){
    idContratista
    idCliente
    idEmpresa
    nombre
    rif
    telef
  }
}
`
const DeleteContratistas = gql`
mutation DeleteContratistas($token:String,$idContratista:String){
  DeleteContratista(token:$token, idContratista:$idContratista)
}
`
