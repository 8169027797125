import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AvatarService } from './avatar.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FileUrl } from 'app/services/interfaces/file-url';

@Component({
    selector: 'icons',
    templateUrl: './icons.component.html',
    styleUrls: ['./icons.component.scss']
})
export class IconsComponent implements OnInit, OnDestroy {

    @Input() CanEdit: boolean= true
    icons: any[];
    public file=new FormControl('')
    file_data:any=''
    filteredIcons: any[];
    loading: boolean;
    showEditimg = false
    public profileimg: any = 'assets/images/avatars/Profile.jpg';

    myForm = new FormGroup({
        name: new FormControl('', [Validators.required, Validators.minLength(3)]),
        file: new FormControl('', [Validators.required]),
        fileSource: new FormControl('', [Validators.required])
      });
    /**
     * Constructor
     */

     // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _matSnackBar: MatSnackBar,
        public avService: AvatarService,
        private _httpClient: HttpClient
    ) {
        
        // Set the defaults
        this.loading = true;

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        try {
            this.profileimg = this.avService.profileimg
        } catch (error) {
           this.profileimg=  'assets/images/avatars/profile.jpg';
        }
    }

    uploadFile($event) {
        this.showEditimg = false;
        const ResumValue = $event.target.files[0]
        let reader = new FileReader();
        reader.readAsDataURL($event.target.files[0]);
        reader.onload = (_event) => {
            const fileList: FileList = $event.target.files; 
            const file = fileList[0];   
            this.avService.changeValue(reader.result, file.size);
            this.avService.url.subscribe((res : FileUrl) => {
                this.profileimg = res.url;
            });
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        /*         this._httpClient.get('api/icons')
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((icons: any) => {
                        this.icons = icons;
                        this.filteredIcons = this.icons;
                        this.loading = false;
                    }); */
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Filter icons
     *
     * @param event
     */
    filterIcons(event): void {
        const value = event.target.value;

        this.filteredIcons = this.icons.filter(icon => {
            return icon.name.includes(value) || icon.tags.includes(value);
        });
    }
}
function input() {
    throw new Error('Function not implemented.');
}

