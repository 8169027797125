import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appCtrlB]'
})
export class CtrlBDirective {

    @Output() appCtrlB: EventEmitter<boolean>=new EventEmitter();
    constructor() {
    }

    @HostListener('window:keydown', ['$event'])
    onKeyDown(event: KeyboardEvent): void {
        
        if (event.getModifierState && event.getModifierState('Control') && event.keyCode===66) {
            this.appCtrlB.emit(true);
        }
    }

} 
