import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { isEmpty, takeUntil } from 'rxjs/operators';

import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { LoginSessionService } from "app/services/global/LoginSession.service";
import { SessionUserService } from '../../../app/shared/services/session-user.service';
import { GetTableV2Service } from 'app/services/global/getTableV2.service';
import { FakeDbService } from 'app/fake-db/fake-db.service';
import { Router } from '@angular/router';

import gql from 'graphql-tag'; 
import { Apollo } from 'apollo-angular';
import { environment } from 'environments/environment';
import { ContactosService } from 'app/main/apps/contactos/contactos.service';

@Component({
    selector: 'fuse-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseNavigationComponent implements OnInit {
    @Input()
    layout = 'vertical';

    //duda este decorador se le aplica a todos los parametros o solo al primero de navegacion 
    @Input()
    navigation: any;
    MenuNavegacion: any = [];
    menuUpdated: any[] = [];
    numberOfData: number = 7;
    counterOfData: number = 0;
    Url: string = ''

    Token = sessionStorage.getItem('DataLogin')
    Version = environment.version


    // Private
    private _unsubscribeAll: Subject<any>;

    //Nota se esta Definiendo los parametros en la funcion constructor y con el decorador @param
    /**
     *
       @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */


    constructor(
        private _FakeDbService: FakeDbService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _LoginSessionService: LoginSessionService,
        private _GetTableV2Service: GetTableV2Service,
        public _Router: Router,
        private apollo: Apollo,
        private _ContactosService:ContactosService,
        private sessionService: SessionUserService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        // Load the navigation either from the input or from the service
        this.navigation = this.navigation || this._fuseNavigationService.getCurrentNavigation();

        // Subscribe to the current navigation changes
        this._fuseNavigationService.onNavigationChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Load the navigation
                this.navigation = this._fuseNavigationService.getCurrentNavigation();

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // Se suscribe a los totales para mostrar en el menú

        this.Totales()
        this.sessionService.currentUserLogged.subscribe(res => {
            if(res) {
                this.GetPermisos();
                this.URL();
            }
        });
    }

    GetPermisos() {
        //this.MenuNavegacion = this.navigation
        const DataPermisos:any = this._LoginSessionService.GetRolUser()
        const Permisos = DataPermisos.Permisos
        const Master = DataPermisos.isMaster
        //const RedirectURL = sessionStorage.getItem("LogOut")

        // console.log('Permisos obtenidos')
        // console.log('Master: ' + Master)
        try {
            if(Master){
                console.log("master");
                this.MenuNavegacion = this.navigation
    
            }else{
                console.log("for");
                for (let index = 0; index < this.navigation.length; index++) {
                    let IndexChildren
                    let DataEncontrada: any = []
        
                    for (let indexPermisos = 0; indexPermisos < Permisos.length; indexPermisos++) {
        
                        IndexChildren = this.navigation[index].children.findIndex(d => d.id == Permisos[indexPermisos])
        
                        if (this.navigation[index].children[IndexChildren] == undefined) {
                        } else {
                            DataEncontrada.push(this.navigation[index].children[IndexChildren])
                        }
        
                    }
        
                    if (DataEncontrada.length >= 1) {
                        const DatosMenu = {
                            id: this.navigation[index].id,
                            title: this.navigation[index].title,
                            translate: this.navigation[index].translate,
                            type: this.navigation[index].type,
                            icon: this.navigation[index].icon,
                            children: DataEncontrada
                        }
                        this.MenuNavegacion.push(DatosMenu)
                    }
                    /*console.log(this.navigation.length - 1)
                    console.log('index ' + index)*/
                    if (index == this.navigation.length - 1) {
                        this.MenuNavegacion.push(this.navigation[index])
                    }
                }
    
            }
    
            //indico la ruta de inicio
            if (this.MenuNavegacion[0].children[0].url == undefined) {
                this.Url = this.MenuNavegacion[0].children[0].children[0].url
            } else {
                this.Url = this.MenuNavegacion[0].children[0].url
            }
            

            // if (RedirectURL == 'false') {
            //         setTimeout(() => {
            //             console.log(this.Url +' 2');
            //             this._Router.navigate([this.Url]);
            //         }, 3000);
            // }
        
        } catch (error) {
            console.error(error);
            console.error(new Date());
        }

        //this.Totales()

    }

    URL(){

        const RedirectURL = sessionStorage.getItem("LogOut")
        if (RedirectURL == 'false') {
            setTimeout(() => {
                //console.log(this.Url +' 2');
                this._Router.navigate([this.Url]);
            }, 3000);
        }

    }

    // Totales con FakeDB Jhonattan
    Totales() {
        // NOTA IMPORTANTE LOS ID DE NAVEGACION NO PUEDEN SER CAMBIADOS. SINO ESTO SE DEBE CAMBIAR LOS ID
        const idNavegationCliente = "2"
        const idNavegationChatv1 = "4"
        const idNavegationChatv2 = "18"
        const idNavegationAgentes = "8"
        const idNavegationContacto = "9"
        const idNavegationRol = "10"
        const idNavegationGaleria = "11"
        const idNavegationTecnicos = "16"

        // console.log(this.navigation)
        // Mantien actualizado el Total de Clientes

        try {
            this._FakeDbService.getTotalClientes().subscribe(totalCliente => {
                this.navigation[0].children[this.navigation[0].children.findIndex(d => d.id == idNavegationCliente)].badge.title = totalCliente;
                //this.counterOfData++;
            })
    
            //Mantiene los totales de chat 
            this._FakeDbService.getTotalChat().subscribe(totalChat => {
                this.navigation[0].children[this.navigation[0].children.findIndex(d => d.id == idNavegationChatv1)].badge.title = totalChat;
                this.navigation[0].children[this.navigation[0].children.findIndex(d => d.id == idNavegationChatv2)].badge.title = totalChat;
                //this.counterOfData++;
            })
    
            // Mantien actualizado el Total de Agentes
            this._FakeDbService.getTotalAgentes().subscribe(totalAgentes => {
                this.navigation[1].children[this.navigation[1].children.findIndex(d => d.id == idNavegationAgentes)].badge.title = totalAgentes;
                this.menuUpdated = [ ...this.MenuNavegacion ];
                //this.counterOfData++;
            });
    
            //Mantiene los totales de Contacto
            this._FakeDbService.getTotalContactos().subscribe(totalContactos => {
                this.navigation[1].children[this.navigation[1].children.findIndex(d => d.id == idNavegationContacto)].badge.title = totalContactos;
                //this.counterOfData++;
            })
            //Mantiene los totales de roles actualizados
            this._FakeDbService.getTotalRol().subscribe(totalRoles => {
                this.navigation[1].children[this.navigation[1].children.findIndex(d => d.id == idNavegationRol)].badge.title = totalRoles;
                //this.counterOfData++;
            })
    
            //Mantiene los totales de Galeria actualizados
            this._FakeDbService.getTotalGaleria().subscribe(totalGaleria => {
                this.navigation[1].children[this.navigation[1].children.findIndex(d => d.id == idNavegationGaleria)].badge.title = totalGaleria;
                //this.counterOfData++;
            })
    
            //Mantiene los totales de Tecnicos actualizados
            this._FakeDbService.getTotalTecnico().subscribe(totalTecnicos => {
                this.navigation[1].children[this.navigation[1].children.findIndex(d => d.id == idNavegationTecnicos)].badge.title = totalTecnicos;
                //this.counterOfData++;
            });
        } catch (error) {
            console.error(error);
            console.error(new Date());
        }
        

    }
}
