import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, pipe } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment as env } from '../../../environments/environment';
import { FileBase64 } from '../interfaces/file-base64';
import { FileUrl, FileUrlDeleted } from '../interfaces/file-url';
import { LoginSessionService } from './LoginSession.service';
import { pick } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class GetUrlImageService {
  public limitMB: number = 10;
  public mimeTypesValidImage: string[] = ['data:image/png', 'data:image/jpeg', 'data:image/webp', 'data:image/gif'];
  public userData;
  constructor( private http: HttpClient,
               private snackBar: MatSnackBar,
               private loginSession: LoginSessionService,

    ) { 
    this.userData = this.loginSession.GetDataUser();
  }

  verifyFileSize(fileSize: number): boolean {
    fileSize =  fileSize / Math.pow(1024 , 2);
    if( fileSize <= this.limitMB ) {
      return true;
    }
    this.snackBar.open('El archivo no debe pesar más de 10MB', 'OK', {
      verticalPosition: 'top',
      duration: 3500
    });
    return false;
  }

  getFormatValidImage(fileBase64: string, otherFiles?: boolean): boolean {
    let formatFiles = 'El formato no es válido, solo esta permitido .png, .jpeg, .webp y .gif';
    let index = fileBase64.indexOf(';');
    fileBase64 = fileBase64.slice(0, index);
    if( this.mimeTypesValidImage.includes(fileBase64) && !otherFiles  ) {
      return true;
    } else if ( [...this.mimeTypesValidImage, 'data:application/pdf'].includes(fileBase64) && otherFiles ) {
      return true
    } else if ( !otherFiles ) {
      formatFiles = 'El formato no es válido, solo esta permitido .png, .jpeg, .webp , .gif y .pdf';
    }
    this.snackBar.open(formatFiles, 'OK', {
      verticalPosition: 'top',
      duration: 3500
     });
    return false;
  }

  getUrlImageBase64( dataFile: FileBase64 ): Observable<FileUrl> {
    dataFile.empresa = this.userData.idEmpresa;
    return this.http.post<FileUrl>(`${env.serverImageBase64}file-base-64`, dataFile);
  }

  errorUploadSnackBar() {
    this.snackBar.open('Error al subir Archivo', 'OK', {
      verticalPosition: 'top',
      duration: 3500
    })
  }

  errorSaveFile() {
    this.snackBar.open('Error al guardar archivo', 'OK', {
      verticalPosition: 'top',
      duration: 3500
    });
  }

  deleteImageById(id: string): Observable<FileUrlDeleted> {
    return this.http.delete<FileUrlDeleted>(`${env.serverImageBase64}delete-file-by-id/${id}`);
  }

}
