import { Injectable } from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {auth} from 'firebase';
import {User} from 'firebase'

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public user:User

  constructor(public afAuth:AngularFireAuth) { }

  async loginGoogle() {
    return this.afAuth.auth.signInWithPopup(new auth.GoogleAuthProvider())
  }

}
