import { HttpClient } from "@angular/common/http";
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";
import { EventEmitter, Injectable } from '@angular/core';

export class ProjectKamikase {
  static apollo: any;
  public static DashReady: EventEmitter<any> = new EventEmitter();
  public static YacargueDash: Boolean = false

  public static TotatesKamikase = []

  public static GetTotoalesDashboard(apollo: Apollo, Token: string, _httpClient: HttpClient) {

    let data = apollo.watchQuery<any>({
      query: TotalesDashboardK,
      variables: {
        token: Token
      },
      fetchPolicy: "no-cache"
    })
      .valueChanges
      .subscribe((result) => {
        console.log('get totales kamikaze')
        console.log(result.data.TotalesDashboardK)

        const DTotales = result.data.TotalesDashboardK

        DTotales.forEach((Res: any) => {
          const Lic = parseInt(Res.lic)

          const Datatotales = {
            item: { id: parseInt(Res.lic) },
            id: Lic.toString(),
            lic: Lic.toString(),
            NameCliente: Res.NameCliente,
            NameEmpresa: Res.NameEmpresa,
            idCliente: Res.idCliente,
            idEmpresa: Res.idEmpresa,
            Total: Res.totales
          }

          _httpClient.post('api/project-dashboardK/' + Lic, Datatotales).subscribe((response: any) => {
            console.log("Ya estoy ready ControlV2")
            this.DashReady.emit(true)
            this.YacargueDash=true
           }, (Error) => { console.error('DashboardKamikase'); console.error(Error) });

        })

      });

  }


}

const TotalesDashboardK = gql`
query TotalesDashboardK ($token:String){
  TotalesDashboardK(token:$token){
    NameEmpresa
    idEmpresa
    NameCliente
    idCliente
    lic
    DateInsert
    Fecha
    totales{
      Order
      Tipo
      NombreTipo
      Total
    }
  }
}
`
