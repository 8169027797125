// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//$2a$10$goGEnbGcgns1uOM2MUwVQ.wMd7WYS0CozHtXQAX923vu0uU42nipK
// clave

export const environment = {
  production: false,
  hmr: false,
  //uriGraphql:"https://pha.thomas-talk.me/",//"https://pha.thomas-talk.me/",  //pha.thomas-talk.me/
  // uriGraphql: "http://192.168.0.194:4300/",
  // subsGraphql: "ws://192.168.0.194:4300/subscriptions",
  // uriGraphql: "http://localhost:8000/",
  // subsGraphql: "ws://localhost:8000/subscriptions",
  uriGraphql: "https://pha.thomas-talk.me/",
  subsGraphql: "wss://pha.thomas-talk.me/subscriptions",
  ServerUploadFile: "https://upload.thomas-talk.me",
  serverImageBase64: 'https://img.thomas-talk.me/api/', //https://img.thomas-talk.tk/api/', http://localhost:5056/api/
  urlApiSaldo: 'https://api.thomas-talk.me/SaldoCe/',
  urlApiWsp: 'https://wa.thomas-talk.me/',
  urlApiEstadoDeCuenta:'https://api.thomas-talk.me/GenerateEdoCuenta/',
  firebaseConfig: {
    apiKey: "AIzaSyD8u2wPPFAR0V0TyKpHiikfw_ZWnzjKs7Q",
    authDomain: "thomas-v9.firebaseapp.com",
    databaseURL: "https://thomas-v9-default-rtdb.firebaseio.com",
    projectId: "thomas-v9",
    storageBucket: "thomas-v9.appspot.com",
    messagingSenderId: "73649914330",
    appId: "1:73649914330:web:523950baf0b4f2a4de40e3",
    measurementId: "G-CVEBGCGLG0"
  },
  campanasShow: false,
  citasShow: false,
  tareasShow: false,
  proyectosShow: false,
  reportesShow: true,
  mercadeoShow: false,
  planesShow: false,
  pagosShow: false,
  version: "V11.1.0-BETA"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional

  // Initialize Firebase
 // firebase.initializeApp(firebaseConfig);
 // firebase.analytics();







