import { Injectable, OnInit } from '@angular/core';
import { id } from '@swimlane/ngx-charts/release/utils';
import { MutationService } from 'app/fake-db/Mutation/mutation.service';
import { LoginSessionService } from "app/services/global/LoginSession.service";

@Injectable({
  providedIn: 'root'
})
export class EcommerceService {


  TokenLogin: string;

  TokenLogin2: String = sessionStorage.getItem('DataLogin')

  TokenFinal: any

  constructor(
    private ServiceFake: MutationService,
    private _LoginSessionService: LoginSessionService
  ) {
    /*console.log("Voy a buscar en el token")
    console.log(sessionStorage.getItem('DataLogin'));*/
    this.TokenLogin = this._LoginSessionService.GetToken()

    if (this.TokenLogin) {
      this.TokenFinal = this.TokenLogin
    } else {
      this.TokenFinal = this.TokenLogin2
    }

  }


  EditServiceAtajo(ApolloC: any, Data: any) {
    return new Promise((resolve, reject) => {
      this.ServiceFake.UpdateAtajos(this.TokenFinal, ApolloC, Data).then((DatoIn) => {
        resolve(DatoIn)
      }).catch((Error) => {
        reject(Error)
      })
    })
  }

  CreateServiceAtajo(ApolloC: any, Data: any) {
    return new Promise((resolve, reject) => {
      this.ServiceFake.AddAtajos(this.TokenFinal, ApolloC, Data).then((DatoIn) => {
        resolve(DatoIn)
      }).catch((Error) => {
        reject(Error)
      })
    })

  }

  CreateServiceCampo(ApolloC: any, Data: any) {

    return new Promise((resolve, reject) => {
      this.ServiceFake.CreateCampos(this.TokenFinal, ApolloC, Data).then((DatoIn) => {
        resolve(DatoIn)
      }).catch((Error) => {
        reject(Error)
      })
    })
  }

  EditServiceCampo(ApolloC: any, Data: any) {
    return new Promise((resolve, reject) => {
      console.log('Datos para editar campo en el edit service campo')
      console.log(Data)
      this.ServiceFake.UpdateCampos(this.TokenFinal, ApolloC, Data).then((DatoIn) => {
        resolve(DatoIn)
      }).catch((Error) => {
        reject(Error)
      })
    })
  }

  CreateServiceEtiqueta(ApolloC: any, Data: any) {
    return new Promise((resolve, reject) => {
      this.ServiceFake.CreateEtiquetas(this.TokenFinal, ApolloC, Data).then((DatoIn) => {
        resolve(DatoIn)
      }).catch((Error) => {
        reject(Error)
      })
    })
  }

  EditServiceEtiqueta(ApolloC: any, Data: any) {
    return new Promise((resolve, reject) => {
      console.log('Esto me llego para la etiquet')
      console.log(Data)
      this.ServiceFake.UpdateEtiquetas(this.TokenFinal, ApolloC, Data).then((DatoIn) => {
        resolve(DatoIn)
      }).catch((Error) => {
        reject(Error)
      })
    })
  }

  CreateServiceContactos(ApolloC: any, Data: any) {
    return new Promise((resolve, reject) => {
      this.ServiceFake.CreateContacto(ApolloC, this.TokenFinal, Data).then((DatoIn) => {
        if (DatoIn) {
          resolve(DatoIn)
        }
      }).catch((Error) => {
        reject(Error)
      })
    })
  }

  EditServiceContactos(ApolloC: any, Data: any) {
    return new Promise((resolve, reject) => {
      this.ServiceFake.UpdateContacto(ApolloC, this.TokenFinal, Data).then((DatoIn) => {
        if (DatoIn) {
          resolve(DatoIn)
        }
      }).catch((Error) => {
        reject(Error)
      })
    })
  }

  DeleteServiceContactos(ApolloC: any, Id: any) {
    return new Promise((resolve, reject) => {
      this.ServiceFake.DeleteContacto(ApolloC, this.TokenFinal, Id).then((DatoIn) => {
        if (DatoIn) {
          resolve(DatoIn)
        }
      }).catch((Error) => {
        reject(Error)
      })
    })
  }

  CreateServiceHorario(ApolloC: any, Data: any) {
    return new Promise((resolve, reject) => {
      this.ServiceFake.CreateHorario(this.TokenFinal, ApolloC, Data).then((DatoIn) => {
        resolve(DatoIn)
      }).catch((Error) => {
        reject(Error)
      })
    })

  }

  CreateServiceHorario2(ApolloC: any, Data: any) {
    return new Promise((resolve, reject) => {
      this.ServiceFake.CreateHorario2(this.TokenFinal, ApolloC, Data).then((DatoIn) => {
        resolve(DatoIn)
      }).catch((Error) => {
        reject(Error)
      })
    })

  }

  UpdateServiceHorario(ApolloC: any, Data: any) {
    return new Promise((resolve, reject) => {
      this.ServiceFake.UpdateHorario(this.TokenFinal, ApolloC, Data).then((DatoIn) => {
        resolve(DatoIn)
      }).catch((Error) => {
        reject(Error)
      })
    })

  }

  UpdateServiceHorario2(ApolloC: any, Data: any) {
    return new Promise((resolve, reject) => {
      this.ServiceFake.UpdateHorario2(this.TokenFinal, ApolloC, Data).then((DatoIn) => {
        resolve(DatoIn)
      }).catch((Error) => {
        reject(Error)
      })
    })

  }

  CreateServiClient(ApolloC: any, Data: any) {
    return new Promise((resolve, reject) => {
      this.ServiceFake.CreateCLiente(ApolloC, this.TokenFinal, Data).then((DatoIn) => {
        resolve(DatoIn)
      }).catch((Error) => {
        reject(Error)
      })
    })

  }

  UpdateClient(ApolloC: any, Data: any) {
    return new Promise((resolve, reject) => {
      this.ServiceFake.UpdateClient(this.TokenFinal, ApolloC, Data).then((DatoIn) => {
        resolve(DatoIn)
      }).catch((Error) => {
        reject(Error)
      })
    })

  }

  DeleteServiceHorario(ApolloC: any, Data: any) {

    /*console.log("Data para eliminar")
    console.log("Data")*/
    return new Promise((resolve, reject) => {
      this.ServiceFake.DeleteHorario(ApolloC, this.TokenFinal, Data).then((DatoIn) => {
        resolve(DatoIn)
      }).catch((Error) => {
        reject(Error)
      })
    })

  }

  CreateServiceUser(ApolloC: any, Data: any) {
    return new Promise((resolve, reject) => {
      this.ServiceFake.CreateUser(ApolloC, Data).then((DatoIn) => {
        resolve(DatoIn)
      }).catch((Error) => {
        reject(Error)
      })
    })
  }

  EditServiceUser(ApolloC: any, Data: any) {
    return new Promise((resolve, reject) => {
      this.ServiceFake.UpdateUser(ApolloC, this.TokenFinal, Data).then((DatoIn) => {
        resolve(DatoIn)
      }).catch((Error) => {
        reject(Error)
      })
    })
  }

  EditServiceUser2(ApolloC: any, Data: any) {
    return new Promise((resolve, reject) => {
      console.log(Data);
      this.ServiceFake.UpdateUser2(ApolloC, this.TokenFinal, Data).then((DatoIn) => {
        resolve(DatoIn)
      }).catch((Error) => {
        reject(Error)
      })
    })
  }

  EditServiceAgent(ApolloC: any, Data: any, Token: any) {
    return new Promise((resolve, reject) => {
      // console.log("Este es el token mutation service");
      // console.log(Token);
      this.ServiceFake.UpdateAgent(ApolloC, Token, Data).then((DatoIn) => {
        resolve(DatoIn)
      }).catch((Error) => {
        reject(Error)
      })
    })
  }

  EditServiceUserH(ApolloC: any, Data: any) {
    return new Promise((resolve, reject) => {
      this.ServiceFake.UpdateUserHorario(ApolloC, this.TokenFinal, Data).then((DatoIn) => {
        resolve(DatoIn)
      }).catch((Error) => {
        reject(Error)
      })
    })
  }

  EditServiceUserO(ApolloC: any, Data: any) {
    return new Promise((resolve, reject) => {
      this.ServiceFake.UpdateUserOnline(ApolloC, this.TokenFinal, Data).then((DatoIn) => {
        resolve(DatoIn)
      }).catch((Error) => {
        reject(Error)
      })
    })
  }

  EditPassServiceUser(ApolloC: any, Data: any) {
    return new Promise((resolve, reject) => {
      this.ServiceFake.UpdatePasswordUser(this.TokenFinal, ApolloC, Data).then((DatoIn) => {
        resolve(DatoIn)
      }).catch((Error) => {
        reject(Error)
      })
    })
  }

  DeleteServiceUser(ApolloC: any, Data: any) {
    return new Promise((resolve, reject) => {
      this.ServiceFake.DeleteUser(ApolloC, this.TokenFinal, Data).then((DatoIn) => {
        resolve(DatoIn)
      }).catch((Error) => {
        reject(Error)
      })
    })
  }

  CreateSeviceTecnico(ApolloC: any, Data: any) {
    return new Promise((resolve, reject) => {

    })
  }

  EditServiceTecnico(Apollo: any, Data: any) {
    return new Promise((resolve, reject) => {

    })
  }

  DeleteServiceTecnico(Apollo: any, Data: any) {
    return new Promise((resolve, reject) => {

    })
  }

  GenerateUniqueID() {
    function chr4() {
      return Math.random().toString(16).slice(-4);
    }
    return chr4() + chr4() +
      '-' + chr4() +
      '-' + chr4() +
      '-' + chr4() +
      '-' + chr4();
  }

}
