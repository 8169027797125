import { style } from "@angular/animations";
import { HttpClient } from "@angular/common/http";
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";
import { RolesFakeDb } from './roles';

export class FaqFakeDb
{
    static apollo: any;

    public static Faq =     [
        {
            'question': 'question',
            'answer': 'answer',
          
        },
        {
            'question': 'question0',
            'answer': 'answer0',
        
        },
        {
            'question': 'question1',
            'answer': 'answer1',
            'img': ['/assets/img/examples/horarios.jpg']
        },
         {
            'question': 'question2',
            'answer': 'answer2',
         
        }, 
        {
            'question': 'question3',
            'answer': 'answer3',
    
        },
        {
            'question': 'question4',
            'answer': 'answer4',
           
        },
        {
            'question': 'question5',
            'answer': 'answer5',
           
        },
        {
            'question': 'question6',
            'answer': 'answer6',
    
        },
        {
            'question': 'question7',
            'answer': 'answer7',
          
        },
        {
            'question': 'question8',
            'answer': 'answer8',
           
        },
        {
            'question': 'question9',
            'answer': 'answer9',
           
        },
        {
            'question': 'question10',
            'answer': 'answer10',
        
        },
        {
            'question': 'question11',
            'answer': 'answer11',
            
        },
        {
            'question': 'question12',
            'answer': 'answer12',
         
        },
        {
            'question': 'question13',
            'answer': 'answer13'
        },
        {
            'question': 'question14',
            'answer': 'answer14',
        },
        {
            'question': 'question15',
            'answer': 'answer15',
        },
        {
            'question': 'question16',
            'answer': 'answer16',
        },
        {
            'question': 'question17',
            'answer': 'answer17',
        },
       /*  {
            'question': 'question18',
            'answer': 'answer18',
            'img': ['/assets/img/examples/Bloqueo1.jpg']
        },
 */
    ];

    public static async CargarFaq() {
        return this.Faq
    }

    public static async ConsultaFaq(apollo:Apollo,Token: any,_httpClient: HttpClient) {
        let Datafaq= await apollo.watchQuery<any>({
            query: FaqConsulta,
            variables: {
                token: Token
            }
        })
        .valueChanges
        .subscribe((result) => {
            let FaqData: any = result.data.Faq
             const Datos: any ={
                Faq: FaqData
             }
            _httpClient.post('api/faq/', Datos)
            .subscribe((response: any) => {  
                
            });
            
        });

    }
}

const FaqConsulta =gql`
  query Faq($token:String){
    Faq(token:$token){
        question
        answer
    }
  }
`