import { Component, OnDestroy, OnInit, AfterViewInit,ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseProgressBarService } from '../../../../@fuse/components/progress-bar/progress-bar.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { navigation } from 'app/navigation/navigation';
import { SessionUserService } from '../../../shared/services/session-user.service';
import { GetTableService } from '../../../main/apps/e-commerce/getTable.service';

@Component({
    selector     : 'vertical-layout-1',
    templateUrl  : './layout-1.component.html',
    styleUrls    : ['./layout-1.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class VerticalLayout1Component implements OnInit, OnDestroy, AfterViewInit
{
    fuseConfig: any;
    navigation: any;
    showSpinner : boolean = true;
    userLogged: boolean = false;
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private sessionService: SessionUserService,
        private getTable: GetTableService,
        private porgressBarService: FuseProgressBarService,
        private router: Router
    )
    {   
        // Set the defaults
        this.navigation = navigation;
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        // Catch sesion user 

        /*
        combineLatest([
            this.sessionService.currentUserLogged, 
            this.porgressBarService.url
        ]).subscribe((data) => {
            const [ logged, route ] = data
            if( true ) {

            } 
        })
        */
       /*
        this.sessionService.currentUserLogged.subscribe(res => {
            if( res ) {
                this.userLogged  = res;
                return;
            }
            this.showSpinner = false;

        })
        */
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;
            });
        }
        
    /**
     * After view checked
     */
    ngAfterViewInit() {
        /*
        this.getTable.dataLoaded.subscribe(res => {
            if( res ) {
                this.showSpinner = false;
            }
        });
        */
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
