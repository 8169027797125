import { Injectable, HostListener } from '@angular/core';
import { SimuladorComponent } from '../components/simulador/simulador.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class SimuladorService {
  private screenHeight: number;
  private screenWidth: number;
  
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    //console.log(this.screenHeight, this.screenWidth);
  }

  constructor(public dialog: MatDialog) { 
    this.getScreenSize();
  }

  Open(Data) {
    const ValueWidth = this.screenWidth > 1000 ? this.screenWidth*0.5  + 'px' : this.screenWidth*0.9  + 'px'
    const dialog = this.dialog.open(SimuladorComponent, {
      data: Data,
      width: ValueWidth,
      maxHeight: '80vh',
    });

    dialog.afterClosed().subscribe((ldata) => {
    });

  }



}
