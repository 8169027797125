import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { GetTableV2Service } from 'app/services/global/getTableV2.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ValidationsService } from '../services/global/validations.service';
import { reject } from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class ChatFakeDb {
    public static contador = 0

    public static Counting: EventEmitter<Number> = new EventEmitter();
    public static chatsReady: EventEmitter<any> = new EventEmitter();
    public static YacargueChats: Boolean = false


    loading: boolean;
    res2: any;
    data
    Token = sessionStorage.getItem('DataLogin')

    public static CountingChat: EventEmitter<Number> = new EventEmitter();
    public static CountingContactos: EventEmitter<Number> = new EventEmitter();
    public static EsperarRespuesta: EventEmitter<any> = new EventEmitter()

    getChatXLic = gql`
query ChatByLic($token:String,$Lic:String){
    ChatByLic(token:$token,Lic:$Lic){
        idmessage
        idConversacion
        idContacto
        timestamp
        me_to
        contentType
        content
        TimeRead
        status
        statusc
        readmsg
        etiqueta
        operador
        lic
    }
}
`;

    UpdateChat = gql`
mutation createEmpresa($token:String,$IdConversacion:String,$Status:String,$StatusC:String){
    UpdateChatByIdConversacion(
        token:$token,
        Data:{
            idConversacion:$IdConversacion
            status:$Status
            statusc:$StatusC
        }){
            idConversacion
            status
            statusc
        }
    }
`
    static _ValidationsService: ValidationsService;

    constructor(private apollo: Apollo,
        public _httpClient: HttpClient, public _GetTableV2Service: GetTableV2Service) {

        ChatFakeDb._ValidationsService = new ValidationsService()
        ChatFakeDb.chatsReady.emit(false)
        const helper = new JwtHelperService();
        const decodedToken = helper.decodeToken(this.Token);
        if (decodedToken) {
            ChatFakeDb.user[0].id = decodedToken.Data.idUser
            ChatFakeDb.user[0].name = decodedToken.Data.Nombre
        }

    }

    ngOnInit(): void {

    }

    cargarcontactosBD() {
        this._GetTableV2Service.TableURL = "contactos" // "contactos"

        this._GetTableV2Service.getData().then(async DataIn => {
            var EsperarRespuesta = new Promise<void>((resolve, reject) => {
                var DataTemp: any = []
                /*console.log('DataIn Contactos en chats')
                console.log(DataIn)*/
                let contact: any = {}
                DataIn.forEach(rows => {
                    rows.rows.forEach(async contacto => {
                        /*                 console.log(contacto) */
                        contact = {
                            'id': contacto.phone,
                            'name': contacto.name,
                            'avatar': contacto.avatar || 'assets/images/avatars/profile.jpg',
                        }
                        ChatFakeDb.contacts.push(contact)
                        await this._httpClient.post('api/chat-contact/' + contact.id, contact)
                    });

                });
                /*         console.log(ChatFakeDb.contacts)
                                new Promise((resolve, reject) => {
                                    this._httpClient.get('api/chat-contacts')
                                .subscribe((response: any) => {
                                    console.log(response);
                                }, reject);
                        }); */
            })
        }).catch((Error) => console.error(Error));
    }
    public static async ConsultaChats(apollo: Apollo, Token: any, _httpClient: HttpClient) {
        //console.log("ConsultarChats")
        let dataChats = apollo.watchQuery<any>({
            query: ChatByContact,
            variables: {
                token: Token
            }
        })

            .valueChanges.subscribe(async (result) => {
                /*console.log(result.data.ChatByContact)*/
                if (!result.data.ChatByContact) {
                    return;
                }
                let text = ''
                //Aqui extraigo los chats y sus contactos si existen
                let { ChatsAll, ContactosAll } = result.data.ChatByContact;
                if (this.contador == 0) {
                    this.contador = 1;
                } else {
                    return;
                }
                //este filter para limpiar datos null, undefined, etc
                ContactosAll = ContactosAll.filter(Boolean);
                let Checkarroba = ChatsAll.filter((chat: any) => !chat.idConversacion.includes('@c.us'));
                //console.log("Checkarroba: ",Checkarroba)
                ChatsAll = Checkarroba.filter(Boolean);
                /*                 console.log('chatsAll', ChatsAll)
                                console.log('contactos', ContactosAll) */
                //recorremos chats para settear 
                //console.log("Acabo de recibir de chats", new Date());
                ChatsAll.forEach(async (c: any, index) => {
                    let contact: any = {};
                    let chatList: any = {
                        unread: 0
                    };
                    let chat: any = {};
                    if (text == '') {
                        //console.log('text: ' + text,'content:',c.content)
                        text = c.content
                    } else {
                        if (text == c.content) {
                            return
                        } else {
                            text = c.content
                        }


                    }
                    //c.content= c.content.replace(/<br>/g, '\n')
                    //buscamos si el chat se encuentra duplicado por idConversacion
                    let duplicated = this.chats.find((chat: any) => chat.id == c.idConversacion); //|| (chat.dialog[0].who==c.phone && chat.dialog[0].lic==c.lic)

                    //buscamos si  existe un chat con el mismo num de telefono y licencia para agregarlo en el mismo
                    let duplicatedcontact = this.chats.findIndex((chat: any) => chat.dialog[0].who == c.phone && chat.dialog[0].lic == c.lic);

                    //si no existe pasamos a crear lo necesario (contact, chatlist y chat)
                    if (!duplicated) {
                        /*                         console.log("Pase 1") */
                        //buscamos si existe el contacto
                        let findContact = ContactosAll.find((contact: any) => contact.phone == c.phone);
                        /*                   console.log('findcontact: ',findContact) */
                        //si existe lo seteamos para meterlo en el arr
                        if (findContact) {
                            contact = {
                                'id': findContact.phone || c.phone,
                                'id2': findContact.id,
                                'name': findContact.name || c.phone,
                                'avatar': findContact.avatar || 'assets/images/avatars/profile.jpg',
                                /*                         'mood':'esto es una  prueba de mood', */
                                "idCliente": findContact.idCliente,
                                "idEmpresa": findContact.idEmpresa,
                                "idCanal": findContact.idCanal,
                                "lastName": findContact.lastName,
                                "code": findContact.code,
                                "extra": findContact.extra,
                                "fecha": findContact.fecha,
                                "category": findContact.category,
                                /* "status" : true,  //Aqui valido si es true lo paso online */
                                "phone": findContact.phone,
                                "Canal": findContact.Canal || "assets/icons/Whatsapp.png",
                                "email": findContact.email,
                                "company": findContact.company,
                                "birthday": findContact.birthday,
                                "address": findContact.address,
                                "notes": findContact.notes,
                                /*                         "campos":JSON.stringify(toJson)//findContact.campos */
                                "campos": findContact.campos || "",
                            };
                        } else {
                            //si no lo seteamos con los datos que tenemos del chat para no perder la conversacion
                            contact = {
                                'id': c.phone,
                                'id2': c.lic,
                                'name': c.phone || " ",
                                'avatar': 'assets/images/avatars/profile.jpg',
                            };
                        }
                        //seteamos el chatlist (lista de conversaciones)
                        const ContentD: string = ChatFakeDb._ValidationsService.TextShowList(c.contentType, c.content);
                        chatList = {
                /*idConversacion*/ 'id': c.idConversacion,
                            'idEmpresa': c.idEmpresa,
                            "lic": c.lic,
                            'contactId': c.phone,
                            'name': findContact ? findContact.name : c.phone,
                            "contenType": c.contentType || "0",
                            'unread': 1,
                            'lastMessage': ContentD,
                            'lastMessageTime': c.TimeRead,
                            'canal': "assets/icons/Whatsapp.png",
                            'etiqueta': c.etiqueta || ""
                        };
                        //seteamos el chat por primera vez
                        chat = {
                            'id': c.idConversacion,
                            'dialog': [
                                {
                                    'who': c.phone,
                                    'contenType': c.contentType || "0",
                                    'message': c.content || "",
                                    'time': c.TimeRead,
                                    me_to: c.me_to,
                                    idEmpresa: c.idEmpresa,
                                    phone: c.phone,
                                    idConversacion: c.idConversacion,
                                    idmessage: c.idmessage,
                                    timestamp: new Date(),
                                    /*                                     contentType : "0" , */
                                    TimeRead: c.TimeRead,
                                    status: c.status || 0,
                                    statusc: c.statusc || 0,
                                    readmsg: c.readmsg || false,
                                    operador: c.operador || "0",
                                    lic: c.lic,
                                    etiqueta: c.etiqueta ? c.etiqueta : " "
                                }
                            ]
                        };

                        //si conseguimos una conversacion con el mismo num y lic lo agregamos a ese chat
                        if (duplicatedcontact != -1) {
                            this.chats[duplicatedcontact].dialog.push(chat.dialog[0]);
                            /*                             console.log(this.chats[duplicatedcontact]) */
                            //TODO: buscar este chat en chatList y sumarle al unread
                            /*                             "lic": c.lic,
                                                        'contactId': c.phone, */
                            /*contactId: "584144435821@c.us"
                            contenType: "0"
                            id: "74bbbfff-391b-54e9-e38c-bf4d6ce10dc1"
                            idEmpresa: "prueba123456"
                            lastMessage: "Necesito un alias como registr. . ."
                            lastMessageTime: "1629405621000"
                            lic: "584142788259@c.us"
                            name: "584144435821@c.us" */
                            let findChatlist = this.user[0].chatList.findIndex((chatlist: any) => chatlist.contactId == c.phone);
                            // console.log("Encontre el chat en la pos: ",findChatlist)
                            /*                             if (findChatlist) {
                                                            this.user[0].chatList[findChatlist].unread++
                                                        } */
                            /*                             console.log(this.chats[duplicatedcontact].dialog) */
                            /*                             console.log("Chat: ", chat) */
                        } else {
                            /*                             console.log(duplicatedcontact)
                                                        console.log(' no lo consegui en duplicatedcontact') */
                            //si no agregamos todo para la nueva conversacion
                            this.contacts.push(contact);
                            this.user[0].chatList.push(chatList);
                            this.chats.push(chat);
                        }

                    } else {
                        /*                         console.log("Pase 2") */
                        //si conseguimos el chat duplicado seteamos el chat para agregarlo a la conversacion existente
                        chat = {
                            'id': c.idConversacion,
                            'dialog': [
                                {
                                    'who': c.phone,
                                    'contenType': c.contentType || "0",
                                    'message': c.content || "",
                                    'time': c.TimeRead,
                                    me_to: c.me_to,
                                    idEmpresa: c.idEmpresa,
                                    phone: c.phone,
                                    idConversacion: c.idConversacion,
                                    idmessage: c.idmessage,
                                    timestamp: new Date(),
                                    /*                                     contentType : "0" , */
                                    TimeRead: c.TimeRead,
                                    status: c.status || 0,
                                    statusc: c.statusc || 0,
                                    readmsg: c.readmsg || false,
                                    operador: c.operador || "0",
                                    lic: c.lic,
                                    etiqueta: c.etiqueta ? c.etiqueta : " "
                                }
                            ]
                        };
                        /*                         console.log("Chat: ", chat) */
                        //sacamos la posicion del chat existente par agregar el nuevo
                        let find = this.chats.indexOf(this.chats.find((chat) => chat.id == c.idConversacion));
                        //lo ubicamos en chatlist para sumarle el contador de unread
                        let findChatlist = this.user[0].chatList.indexOf(this.user[0].chatList.find((list: any) => list.id == c.idConversacion));
                        //console.log('findChatlist ',findChatlist)
                        /*                         console.log("Aqui esta el me_to") */
                        /*                         console.log(chat.dialog[0].me_to=="i") */
                        if (chat.dialog[0].me_to == "i") {
                            /*                                 console.log("mew_to=i") */
                            if (this.user[0].chatList[findChatlist].unread) {

                                /*                                         console.log("unread if: ",this.user[0].chatList[findChatlist].unread) */
                                this.user[0].chatList[findChatlist].unread++;
                            } else {
                                /*                                     console.log("unread else: ",this.user[0].chatList[findChatlist].unread) */
                                this.user[0].chatList[findChatlist].unread = 1;
                            }
                        }
                        this.user[0].chatList[findChatlist].contentType = c.contentType;
                        this.user[0].chatList[findChatlist].lastMessage = ChatFakeDb._ValidationsService.TextShowList(c.contentType, c.content)
                        this.user[0].chatList[findChatlist].lastMessageTime = c.TimeRead;

                        if (this.chats[find] != undefined && !this.chats[find].dialog.find((id: any) => id.idmessage == c.idmessage)) {
                            //hacemos push del chat al encontrado
                            this.chats[find].dialog.push(chat.dialog[0]);
                        }
                    }
                });
                //console.log("Acabo de terminar de settear chats", new Date());
                //this.chatsReady.emit(true);
                setTimeout(() => {
                    this.chatsReady.emit(true);
                    this.YacargueChats = true;
                }, 1500);
                //console.log("Ya termine de cargar");
                ChatFakeDb.EsperarRespuesta.emit(true);
                try {
                    /*                     console.log(this.user) */
                    this.Counting.emit(this.user[0].chatList.length);
                } catch (error) {
                    this.Counting.emit(0);
                }
            }
                /*                 new Promise((resolve, reject) => {
                                    _httpClient.get('api/chat-contacts')
                                .subscribe((response: any) => {
                                    console.log(response);
                                }, reject);
                        }); */

            )
    }

    ChatByIdOperador(token: string, idOperador: String) {
        return new Promise((resolve, reject) => {
            try {

                this.data = this.apollo.watchQuery<any>({
                    query: ChatByOperador,
                    variables: {
                        token: token,
                        IdOperador: idOperador
                    }
                })
                    .valueChanges
                    .subscribe((result) => {
                        resolve(result.data.ChatByOperador)
                    });

            } catch (error) {
                console.error(error)
                reject(error)
            }
        })
    }

    ChatByLic(Lic: String) {
        //console.log("ChatByLic 375")
        return new Promise((resolve, reject) => {
            try {

                //console.log('entre en chatbylic')

                this.data = this.apollo.watchQuery<any>({
                    query: ChatByLic,
                    variables: {
                        token: this.Token,
                        lic: Lic
                    }
                })
                    .valueChanges
                    .subscribe((result) => {

                        const DataMsj: any = result.data.ChatsBySub
                        /*                         console.log(DataMsj)  */

                        //const licClient = this._ValidationsService.validateArroba(Lic,"add")// Lic.replace('@c.us', '')

                        if (Lic != undefined) {
                            const lic = ChatFakeDb._ValidationsService.validateArroba(Lic, "delete")

                            const DataUserByCliente = {
                                item: { id: parseInt(lic) },
                                id: lic.toString(),
                                lic: lic.toString(),
                                colum: [
                                    { colum: 'contacto', type: 'phone', head: 'Contacto', visible: true, isModelProperty: true },
                                    /* { colum: 'type', type: 'string', head: 'Tipo', visible: true, isModelProperty: true }, */
                                    { colum: 'cuenta', type: 'phone', head: 'Cuenta', visible: true, isModelProperty: true },
                                    { colum: 'fecha_i', type: 'date', head: 'Fecha Inicio', visible: true, isModelProperty: true },
                                    { colum: 'agente_exc', type: 'string', head: 'Agentes exclusivos', visible: true, isModelProperty: true },
                                    { colum: 'requerimiento', type: 'string', head: 'Requerimiento', visible: true, isModelProperty: true },
                                    { colum: 'mensajes', type: 'string', head: 'Mensajes', visible: true, isModelProperty: true },
                                    { colum: 'menu', type: 'menu', head: 'Menú', visible: true },
                                ],
                                type: ['string', 'string', 'string', 'string', 'string', 'string', 'string', 'menu'],
                                menu: ["Chat/chrome_reader_mode", "Reasignar/swap_horiz"],
                                rows: DataMsj
                            }
                            /*                             console.log(DataMsj) */

                            this._httpClient.post('api/dashboard-mensajes/' + lic, DataUserByCliente).subscribe((response: any) => { resolve(DataMsj) }, (Error) => { console.error('Dashboard Mensajes'); console.error(Error) });
                        }


                    });

            } catch (error) {
                console.error(error)
            }
        })
            .catch((error: any) => reject(error))
    }

    ChatByPhoneLic(lic: any, phone: any) {
        return new Promise((resolve, reject) => {
            try {

                let data = this.apollo.watchQuery<any>({
                    query: ChatPhoneAndLic,
                    variables: {
                        token: this.Token,
                        phone: phone,
                        lic: lic
                    }
                })
                    .valueChanges
                    .subscribe((result) => {
                        let DataChat = result.data.ChatByPhoneAndLic

                        resolve(DataChat)

                    });

            } catch (error) {
                console.error(error)
                reject(error)
            }
        })

    }

    /* UpdateChatXIdConversacion() {
        this.apollo.mutate({
            mutation: this.UpdateChat,
            variables: {
                token: this.TOKEN,
                IdConversacion: "1",
                Status: "2",
                StatusC: "3"
            }
        }).subscribe(res => {console.log("UpdateChatXIdConversacion: Update Listo") });

    } */



    public static contacts = [
        //{
        //"id" : "584168301532@c.us", 
        //"id2" : "12345-54321", 
        //'name'  : 'Alice ',
        //'avatar': 'assets/images/avatars/alice.jpg',
        //"idCliente" : "2525", 
        //"idEmpresa" : "prueba123456", 
        //"idCanal": [
        //"584120202020@c.us"
        //],
        //"lastName" : "Freeman", 
        //"code" : "", 
        //"extra" : null, 
        //"fecha" : "2020-05-27T15:52:38.000+0000", 
        //"category" : null, 
        //"phone" : "584168301532@c.us", 
        //"Canal" : "assets/icons/Whatsapp.png", 
        //"email" : "", 
        //"company" : null,
        //"birthday" : null, 
        //"address" : null,
        //"notes" :null,
        //"campos": ""
        //},
        //{
        //"id" : "584121236578@c.us", 
        //"id2" : "qweewq", 
        //'name'  : 'Josefina ',
        //'avatar': 'assets/images/avatars/Josefina.jpg',
        //"idCliente" : "2525", 
        //"idEmpresa" : "prueba123456", 
        //"idCanal": [
        //"584120202020@c.us"
        //],
        //"lastName" : "Freeman", 
        //"code" : "", 
        //"extra" : null, 
        //"fecha" : "2020-05-27T15:52:38.000+0000", 
        //"category" : null, 
        //"phone" : "584121236578@c.us", 
        //"Canal" : "assets/icons/Whatsapp.png", 
        //"email" : "", 
        //"company" : null,
        //"birthday" : null, 
        //"address" : null,
        //"notes" :null,
        //"campos": ""
        //},
        //{
        //"id" : "584128160258@c.us", 
        //"id2" : "a1b2c3", 
        //'name'  : 'Velazquez ',
        //'avatar': 'assets/images/avatars/Velazquez.jpg',
        //"idCliente" : "2525", 
        //"idEmpresa" : "prueba123456", 
        //"idCanal": [
        //"584121311690@c.us"
        //],
        //"lastName" : "Freeman", 
        //"code" : "", 
        //"extra" : null, 
        //"fecha" : "2020-05-27T15:52:38.000+0000", 
        //"category" : null, 
        //"phone" : "584128160254@c.us", 
        //"Canal" : "assets/icons/Whatsapp.png", 
        //"email" : "", 
        //"company" : null,
        //"birthday" : null, 
        //"address" : null,
        //"notes" :null,
        //"campos": ""
        //},
        /*         {
                    'id'    : '5725a680b3249760ea21de59',
                    'name'  : 'prueba',
                    'avatar': 'assets/images/avatars/Tyson.jpg',
                    'status': 'online',
                    'mood'  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
                }, */

    ];

    public static chats = [
        /*         {
                    /*idConversacion'id'    : '1234',
                    'dialog': [
                        {
                            /*id'who'    : '5725a680b3249760ea21de52',
                            "idmessage" : "false_584166408040@c.us_3A5327C395C156B1D3332", 
                            'contenType':'0',
                            /*content'message': 'Quickly come to the meeting room 1B, we have a big server issue',
                            /*timestamp'time'   : '2017-03-22T08:54:28.299Z',
                            "me_to" : "i", 
                            "TimeRead" : "2021-02-21T10:58:19.597Z", 
                            "status" : "0", 
                            "statusc" : 3,
                            "readmsg" : false, 
                        },
                        {
                            'who'    : '5725a680b3249760ea21de52',
                            'contenType':'0',
                            'message': 'Quickly come to the meeting room 1B, we have a big server issue',
                            'time'   : '2017-03-22T08:54:28.299Z'
                        },
                        {
                            'who'    : '5725a680b3249760ea21de52',
                            'contenType':'1',
                            'message': 'assets/images/avatars/Velazquez.jpg',
                            'time'   : '2017-03-22T08:54:28.299Z'
                        },
                         
                    ]
                },
                {
                    'id'    : '2725a680b8d240c011d',
                    'dialog': [
                        {
                            'who'    : '5725a680b8d240c011dd224b',
                            'contenType':'0',
                            'message': 'Quickly come to the meeting room 1B, we have a big server issue',
                            'time'   : '2017-04-22T01:00:00.299Z'
                        },
                       
                    ]
                },
                {
                    'id'    : '3725a6809413bf8a0a5272b4',
                    'dialog': [
                        {
                            'who'    : '5725a6809413bf8a0a5272b1',
                            'contenType':'0',
                            'message': 'Quickly come to the meeting room 1B, we have a big server issue',
                            'time'   : '2017-04-22T02:10:00.299Z'
                        }
                    ]
                } */
    ];

    public static user: any = [  // Son los mensajes del Operador John Doe
        {
            /*operador*/'id': '0101',
            'name': 'John Doe',
            'avatar': 'assets/images/avatars/profile.jpg',
            'status': 'online',
           /*snippet */ 'mood': 'it\'s a status....not your diary...',
            'chatList': [/*
                {
                   /*idConversacion 'id'             : '1234',
                    'idEmpresa': "2525",
                    "lic" : "584142788259",
                    'contactId'     : '5725a680b3249760ea21de52',
                    'name'           : 'Alice Freeman',
                    'unread'         : 4,
                    'lastMessage'    : 'You are the worst!',
                    'lastMessageTime': '2017-06-12T02:10:18.931Z'
                },
                {
                    'id'             : '2725a680b8d240c011d',
                    'idEmpresa': "2525",
                    'contactId'      : '5725a680b8d240c011dd224b',
                    'name'           : 'Josefina',
                    'unread'         : null,
                    'lastMessage'    : 'We are losing money! Quick!',
                    'lastMessageTime': '2017-02-18T10:30:18.931Z'
                },
/*                 {
                    'id'             : '3725a6809413bf8a0a5272b4',
                    'idEmpresa': "2526",
                    'contactId'      : '5725a6809413bf8a0a5272b1',
                    'name'           : 'Velazquez',
                    'unread'         : 2,
                    'lastMessage'    : 'Quickly come to the meeting room 1B, we have a big server issue',
                    'lastMessageTime': '2017-03-18T12:30:18.931Z'
                } 
/*                 {
                   'id'             : '1725a680b7569990ea21aa01',
                    'idEmpresa': "2525",
                    "lic" : "584142788259",
                    'contactId'     : '5725a680b3249760ea21de59',
                    'name'           : 'prueba',
                    'unread'         : 4,
                    'lastMessage'    : 'You are the worst!',
                    'lastMessageTime': '2017-06-12T02:10:18.931Z'
                } */
            ]
        }
    ];


    public static CargarConversaciones(Token: string) {
        // Aqui se debe hacer la consulta de graphql
        return this.user
    }


    public static CargarContactos(Token: string) {
        // Aqui se debe hacer la consulta de graphql
        return this.contacts
    }


    public static CargarContactosDB(Token: string) {
        // Aqui se debe hacer la consulta de graphql
        return this.contacts
    }

    public static CargarChats(Token: string) {
        // Aqui se debe hacer la consulta de graphql
        return this.chats
    }

    /*    public static cleanAll(){
           console.log('pase por clean')
            this.contacts=[]
            this.chats=[]
            this.user=[]
        } */

    public static ChatsByPhones(apollo: Apollo, Token: String, Phone: String) {
        return new Promise((resolve, reject) => {

            let data = apollo.watchQuery<any>({
                query: ChatByPhone,
                variables: {
                    token: Token,
                    phone: Phone
                }
            })
                .valueChanges
                .subscribe((result) => {
                    let DataChatByPhone = result.data.ChatByPhone //Guardo la data obtenida
                    //console.log("DataChatByPhone")
                    //console.log(DataChatByPhone) //muestro la data por la consola

                });

        })
    }

    ChatsByPhones2(apollo: Apollo, Token: String, Phone: String) {
        return new Promise((resolve, reject) => {

            let data = apollo.watchQuery<any>({
                query: ChatsByPhone2,
                variables: {
                    token: Token,
                    phone: Phone
                }
            })
                .valueChanges
                .subscribe((result) => {

                    let DataChatByPhone = result.data.ChatByPhone2 //Guardo la data obtenida
                    resolve(DataChatByPhone)

                });

        })
    }

    chatbyLicAndPhone(apollo: Apollo, Token: String, Phone: String, Lic: String) {
        return new Promise((resolve, reject) => {

            let data = apollo.watchQuery<any>({
                query: ChatPhoneAndLic,
                variables: {
                    token: Token,
                    phone: Phone,
                    lic: Lic
                }
            })
                .valueChanges
                .subscribe((result) => {
                    console.log('result', result)
                    let DataChatByPhone = result.data.ChatByPhoneAndLic //Guardo la data obtenida
                    //console.log("DataChatByPhone: ", DataChatByPhone) //muestro la data por la consola
                    resolve(DataChatByPhone)

                });

        })
    }

    ChatHistoryByPhones(apollo: Apollo, Token: String, Phone: String, Page: number, Limit: Number) {
        return new Promise((resolve, reject) => {

            let data = apollo.watchQuery<any>({
                query: ChatsHistoryByPhone,
                variables: {
                    token: Token,
                    Phone: Phone,
                    page: Page,
                    limit: Limit
                }
            })
                .valueChanges
                .subscribe((result) => {
                    /*                     console.log(result) */

                    let DataChatHistoryByPhone = result.data.HistoryByPhone //Guardo la data obtenida
                    resolve(DataChatHistoryByPhone)

                });

        })
    }

    MSJInCola(apollo: Apollo, Token: String) {
        return new Promise((resolve, reject) => {

            //console.log('Token para la cola de mensajes ')
            //console.log(Token)

            let data = apollo.watchQuery<any>({
                query: ColaBylic,
                variables: {
                    token: Token,
                }/* ,
                fetchPolicy: "no-cache" */
            })
                .valueChanges
                .subscribe((result) => {
                    let Data = result.data.TotalMSJColaByLic //Guardo la data obtenida
                    /* console.log("Data para la cola de mensajes")
                    console.log(Data) //muestro la data por la consola */
                    resolve(Data)
                });

        })
    }

}


/*
{ "_id" : { "$oid" : "606f0dcdd0b6952e20c78c17" }, 
"idEmpresa" : "23342342", 
"id" : "584166408040@c.us", 
"idConversacion" : "2e68dd98-b95d-5691-631f-066453678b18", 
"idmessage" : "false_584166408040@c.us_3A5327C395C156B1D3A3", 
"timestamp" : 1.61784E12, 
"me_to" : "i", 
"contentType" : "0",
"content" : "2", 
"TimeRead" : "2021-02-21T10:58:19.597Z", 
"status" : "0", 
"statusc" : 3,
"readmsg" : false, 
"operador" : "0", 
"lic" : "584142788259" }
*/



export const ChatByOperador = gql`
  query ChatByOperador($token:String, $IdOperador:String){
    ChatByOperador(
        token:$token
        IdOperador:$IdOperador
    ){
        idEmpresa
        phone
        idConversacion
        idmessage
        timestamp
        me_to
        contentType
        content
        TimeRead
        status
        statusc
        readmsg
        operador
        lic
        tag
        etiqueta
        priority
    }
  }
`
export const ChatByContact = gql`
  query ChatByContact($token:String){
    ChatByContact(token:$token){
            ChatsAll{
                idEmpresa
                phone
                idConversacion
                idmessage
                timestamp
                me_to
                contentType
                content
                TimeRead
                status
                statusc
                readmsg
                operador
                lic
                etiqueta
                }
                ContactosAll{
                id
                idCliente
                idEmpresa
                idCanal
                name
                lastName
                avatar
                code
                extra
                fecha
                category
                status
                phone
                Canal
                email
                company
                birthday
                address
                notes
                campos
                }
    
        }
  }
`

export const ChatByPhone = gql`
query ChatByPhone( $token:String, $phone:String ){
    ChatByPhone( token:$token, phone:$phone ){
        ChatsAll{
            idEmpresa
            phone
            idConversacion
            idmessage
            timestamp
            me_to
            contentType
            content
            TimeRead
            status
            statusc
            readmsg
            operador
            lic
            tag
        }
        ContactosAll{
            id
            idCliente
            idEmpresa
            idCanal
            name
            lastName
            avatar
            code
            extra
            fecha
            category
            status
            phone
            Canal
            email
            company
            birthday
            address
            notes
            campos
        }
  }
}
`

export const ChatsByPhone2 = gql`
query ChatByPhone2( $token:String, $phone:String ){
    ChatByPhone2( token:$token phone:$phone )
    {
        Status
        ContactosAll{
            id
            idCliente
            idEmpresa
            idCanal
            name
            lastName
            avatar
            code
            extra
            fecha
            category
            status
            phone
            Canal
            email
            company
            birthday
            address
            notes
            campos
        }
        ChatsAll{
            idEmpresa
            phone
            idConversacion
            idmessage
            timestamp
            me_to
            contentType
            content
            TimeRead
            status
            statusc
            readmsg
            operador
            lic
            tag
            etiqueta
        }
    }
}
`

export const ChatsHistoryByPhone = gql`
query HistoryByPhone( $token:String, $Phone:String, $page:Int, $limit:Int ){
    HistoryByPhone(token:$token, Phone:$Phone, page:$page, limit:$limit)
    {
        idEmpresa
        phone
        idConversacion
        idmessage
        timestamp
        me_to
        contentType
        content
        TimeRead
        status
        statusc
        readmsg
        etiqueta
        operador
        lic
        tag
        name
        avatar
        isLocalLast
        isNewDay
        id
    }  
}
`

export const ChatByLic = gql`
  query ChatsBySub($token:String, $lic:String){
    ChatsBySub(
        token:$token
        lic:$lic
    ){
        idEmpresa
        phone
        idConversacion
        idmessage
        timestamp
        me_to
        contentType
        content
        TimeRead
        status
        statusc
        readmsg
        operador
        lic
        tag
        etiqueta
        priority
    }
  }
`

export const ColaBylic = gql`
query TotalMSJColaByLic( $token:String){
    TotalMSJColaByLic(token:$token){
    lic
    Total
  } 
}
`

export const ChatPhoneAndLic = gql`
  query ChatByPhoneAndLic($token:String, $phone:String,$lic:String){
      ChatByPhoneAndLic(
          token:$token
          phone:$phone
          lic:$lic
        ){
            idEmpresa
            phone
            idConversacion
            idmessage
            timestamp
            me_to
            contentType
            content
            TimeRead
            status
            statusc
            readmsg
            operador
            lic
            tag
            etiqueta
            priority
            DatePc
        }
    }
`
