import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sliceString'
})
export class SliceStringPipe implements PipeTransform {

  transform(value: string, searchValue: string, replaceFor: string = '', end?: number): any {
    let index: number = value.indexOf(searchValue);
    let str1: string = '';
    let str2: string = '';
    if( index === -1 ) {
      return value;
    }
    str1 = value.slice(0, index);
    if( !end ) {
      str2 = value.slice(index + searchValue.length);
    } else {
      str2 = value.slice(index + searchValue.length, end);
    }
    value = `${str1}${replaceFor}${str2}`;
    return value;
  }

}
