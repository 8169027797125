import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ShowTotalesService {


  public showtotales: EventEmitter<boolean> = new EventEmitter<boolean>();
  private lstatus: boolean;
  constructor() { 

  }

  changeshow(status) {
    this.lstatus = status
    this.showtotales.emit(status)
  }

  getStatus() {
    return this.lstatus;
  }

}
