import { HttpClient } from "@angular/common/http";
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";

export class ListColumn {
    name?: string;
    property?: string;
    visible?: boolean;
    isModelProperty?: boolean;
    displayFn: any;
  }

  
export class AtajosFakeDb
{

    static apollo:Apollo
    // Si se edita alguno de los campos aqui presente sobre todo menú
    // asi sea una mayúscula puede dañar el sistema, NO TOCAR MEJOR
    public static atajos = [] 

    public static CargarAtajos(Token: string) {
        // Aqui se debe hacer la consulta de graphql
        return this.atajos
    }

    public AddAtajos(Token: string,apollo:Apollo,Confirm:boolean) {
       //Aqui se debe hacer la consulta de graphql
       if(Confirm){
        let Data:{
            IdCliente:"2526",
            id:"5584",
            abreviado:"rr",
            texto:"prueba",
            Canales:["584241084648"]
        }
        let { IdCliente,id,abreviado,texto,Canales }=Data;

        apollo.mutate({
            mutation: MutationAtajo,
            variables: {
              token: Token,
              IdCliente: IdCliente,
              id: id,
              abreviado: abreviado,
              texto: texto,
              Canales: Canales
            }
          }).subscribe(({ data }) => {
          },(error) => {
            console.error('Atajos', error);
          });
    }
}

}

  const MutationAtajo =gql`
  mutation CreateAtajosCliente($Token:String,$IdCliente:String,$id:String,$abreviado:String,$texto:String,$Canales:String) {
    CreateAtajosCliente(token:Token,
      DAtajos:{
      IdCliente:$IdCliente,
      id:$id,
      abreviado:$abreviado,
      texto:$texto,
      Canales:$Canales
    }){
      IdCliente
      id
      abreviado
      texto
      Canales
    }
  }
  `

