 
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GetTableV2Service implements Resolve<any>
{
    Data: any[];
    onDataChanged: BehaviorSubject<any>;
    TableURL: string;
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient
    )
    {
        // Set the defaults
        this.onDataChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise<void>((resolve, reject) => {

            Promise.all([
                this.getData()
            ]).then(
                () => {
                    resolve();
                },
                reject
            ).catch((Error) => { console.error(Error) });
        });
    }

    /**
     * Get products
     *
     * @returns {Promise<any>}
     */
    getData(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get('api/' + this.TableURL)
            .subscribe((response: any) => {
                    this.Data = response;
                    this.onDataChanged.next(this.Data);
                    resolve(response);
                }, reject);
        });
    }


    /**
     * Save product
     *
     * @param Data
     * @returns {Promise<any>}
     */
    saveData(Data): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.post('api/' + this.TableURL + '/' + Data.id, Data)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    /**
     * Add product
     *
     * @param Data
     * @returns {Promise<any>}
     */
    addData(Data): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.post('api/' + this.TableURL + '/', Data)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }


}
