export const locale = {
    lang: 'tr',
    data: {
        'NAV': {
            'APLICACIÓN': 'Programlar',
            'DASHBOARDS'  : 'Kontrol Paneli',
            'CALENDAR'    : 'Takvim',
            'ECOMMERCE'   : 'E-Ticaret',
            'MAIL'        : {
                'TITLE': 'Posta',
                'BADGE': '15'
            },
            'MAIL_NGRX'        : {
                'TITLE': 'Posta Ngrx',
                'BADGE': '13'
            },
            'CHAT'        : 'Sohbet',
            'Cliente' : 'Cliente',
            'FILE_MANAGER': 'Dosya Yöneticisi',
            'CONTACTS'    : 'Kişiler',
            'TODO'        : 'Yapılacaklar',
            'SCRUMBOARD'  : 'Proje'
        }, 
        Reportes: {
            'CliTitle0001': 'Kişiler',
            'Contactos': 'Contactos',
            'Search': 'Search for a',
            'Nombre': 'Nombre',
            'Avater': 'Logotipo',
            'Agentes': 'Agentes',
            'Estatus': 'Estatus',
            'Cola': 'Em espera',
            'Menú': 'Cardápio',

            'avatar': 'Logotipo',
            'name': 'Nome',
            'phone': 'Telefone',
            'Canal': 'Canal',
            'menú': 'Cardápio',

            'Roles': 'Permit',
            'Rol': 'Permit',
            'Descripción': 'Description',
            'Acción': 'Action',
            'LocalConfig.BottonTitle' : 'Add New',
            'LocalConfig.title' : 'Reports',

            'url' : 'Url',
            'title' : 'Title',
            'owner' : 'Owner',
            'modified' : 'Modified',
            'menu' : 'Menu'

            
        },
        ReportList:  {
            'Agentes': 'Agents',
            'Atajos': 'Shortcuts',
            'Abreviado': 'Abbreviated',
            'Texto': 'Text',
            'Canal': 'Channel',
            'Menú': 'Menu',

            'Campos': 'Countryside',
            'Campo': 'Countryside',
            'Tipo': 'Type',
            'Requerido': 'Required',
            'Ubicación': 'Location',

            'Etiquetas': 'Labels',

            
            'Descripción': 'Description',
            'Jornadas': 'Journeys', 
            'DiaInicio': 'Beginning', 
            'DiaFin': 'End', 
            'Excepciones': 'Exceptions',
            'Horarios': 'Schedule',

            'Contactos': 'Contacts',

            'Bot': 'Bot',

            'Avatar': 'Avatar',
            'Nombre': 'Name',
            'Cola': 'Cola',
            'Online': 'Online',
            

            'Mensajes': 'Messages',
            'Contacto': 'Contact',
            'Cuenta': 'Account',
            'Fecha Inicio': 'Date / Start ',
            'Requerimiento': 'Requirement',
            'Agentes exclusivos': 'Exclusive agents',

            'Transferir': 'To transfer',
            'Ultima conexion': 'Last connection',

            'Exportar' : 'To Export',
            'Telefono':'Telefon',
            'Mensaje':'İleti'
            
        },
         Generico: {
            'Siguente': 'Next',
            'SIGUIENTE': 'NEXT',
            'Seleccionar': 'To select',
            'Atras': 'Behind',
            'CANCELAR': 'CANCEL',
            'AGREGAR': 'ADD',
            'OK': 'OK',
            'GUARDAR': 'SAVE',
            'CARGANDO' : 'LOADING',
            'CREAR HORARIO' : 'CREATE SCHEDULE',
            'Anterior': 'Previous',
            'CrearUsuario': 'Create User',
            'GuardarCambios' : 'Save Changes',
            'Mensajes': 'Messages',
            'Agentes': 'Agents',
            'WelcomeBack': 'Welcome back,',
            'Home': 'Home',

            
            
        },
        DatosClientes: {
            'AjustesDeLaCuenta' : 'Account Settings',
            'NuevaCliente' : 'New Client',
            'BasicInfo' : 'Basic information',
            'Canales' : 'Channels',
            'Galeria' : 'Gallery',
            'Contactos' : 'Contacts ',
            'Horarios' : 'Schedules',
            'Etiquetas' : 'Label',
            'Campos' : 'Countryside',
            'Atajos' : 'Shortcuts',
            'NameEmp': 'Company Name',
            'Rif': 'Rif',
            'Email': 'Email',
            'Phone': 'Phone',
            'Direction': 'Direction',
            'PaymetMethod': 'Paymet Method',
            'MensajeAdicional' : 'Additional Message'

        },
        Canales: {
            'ChannelType':'Channel Type',
            'CtrlWhatsApp':'Control of WhatsApp Multi Agents',
            'CtrlFacebook':'Channel Type',
            'NoDisponible':'No disponible',
            'SignalAgen':'Signal Multi Agentes',
            'TelegAgen':'Telegram Multi Agentes',
            'ScannQR':'Escaner QR',
            
            'ConfDeMensaj':'Message Settings',
            'LastnameFirstname' :'Last Name, Firstname',
            'MensajDeBienve': 'Welcome Message',
            'MensajDelContacto': 'Message to Request Name and Surname of the Contact', 
            'MensajCorreo':'Message to Request Contact Email',
            'MensajDatos' :'Message to indicate that the Completed data has been saved',
            'MensajAgentes': 'Message to Indicate the Assignment of an Agent to the Conversation',
            'MensajConvers': 'Message to Indicate End of Conversation',
            'MensajDisponi' : 'Message to indicate that there are no Agents Available',
            'MensajAlerta' : 'Alert Message to Contact when Inactivity is Detected in Conversation',
            'MensajFinaliz': ' Message to Indicate that the Conversation will End Due to Contact Inactivity',
            'MensajArchivo': 'Message to Tell the Contact that it is not possible to receive the file',
            'MensajLlamada':'Message in case of Calls',

            'PermiDelCanal' : 'Canal Permits',
            'PermiConvers'  : 'Allow to Start a Conversation with Unknown Contacts',
            'PermiNotif' : 'Allow Send Notifications',
            'PermiDifusiones' : 'Allow to send broadcastss',
            'EnviarMensaj': 'Send unavailability message after each message',
            'RecibirConvers' : 'Receive group conversations',
            'PermiActuali' : 'Allow update of message statuses',
            'PermiArchivos': 'Allow files',
            'BloqueVideos' : 'Block videos',
            'MensajVideo' : 'Message to indicate that it is not possible to receive the Video files',
            'PermitirAudios' : 'Allow Audios',
            'PermitirImágenes' : 'Allow Images',

            'Done' : 'Done',
            'YouAreNowDone' : 'You are now done.',

            'Nombre' : 'Name',
        },
        ListCanales: {
            'Vincular' : 'Link',
            'Desvincular' : 'Unlink',
            'ActualizarDatos' : 'Update Data',
            'Eliminar' : 'Eliminate',
            'NroDeTeléfono' : 'Phone Number',
            'Bateria' : 'Drums',
            'VerWhatsapp': 'Whatsapp version',
            'TotalDeMensaje' : 'Total Message',
            'Online' : 'Online',
            'Status' : 'Status',
            'UltimaConexion': 'Last Connection', 
        },
        Campos: {
            'Campo' : 'Countryside',
            'Tipo' : 'Type',
            'Ubicacion' : 'Location',    
        },
        SelectCanales : {
            'Canal' : 'Channel',
        },
        Atajo: {
            'Abreviado' : 'Abbreviated',
            'texto' : 'Text',
            'canales' : 'Channels',

        },
        Etiquetas : {
            'Etiquetas' : 'Labels',
        },
        Horarios : {
            'DescripJorna' : 'Day Description',
            'DiaInicio' : 'Start Day',
            'DiaFin' : 'End Day', 
            'HoraInicio' : 'Start Time',
            'HoraFin' : 'EndTime',
            'Descripcion' : 'Descripction',
            'SeleccioneLaFecha' : 'Select Date',
        },
        Agent : {
            'DatosPersonales' : 'Personal Information',
            'Nombre' : 'Name',
            'Apellido' : 'Firstname',
            'Email' : 'Email',
            'Contrasena' : 'Password',
            'Telefono' : 'Phone',
            'Direccion' : 'Direction',
            'HorariosPermisos' : 'Schedules / Permissions',
            'Canales' : 'Channels',
            'Horarios' : 'Schedules',
            'TipodeUsuario' : 'Type of user',
            'RoldelUsuario' : 'User Role',
            'AñadirNuevoRol' : 'Add new role',
            'Agentes' : 'Agent',
            'Ajustes' : 'Settings',
        },
        tRoles : {
            'Rol' : 'Permit',
            'domain' : 'Domain',
            'Descripcion' : 'Description',
            'Permisos' : 'Permissions',
            'Usuarios' : 'Users',
        },
        tContactos : {
            'Name' : 'Name',
            'Lastname' : 'Lastname',
            'CodeID' : 'Code/ID',
            'Email' : 'Email',
            'Company' : 'Company',
            'Birthday' : 'Birthday',
            'Address' : 'Address',
            'Notes' : 'Notes',

        },
        Chat : {
            'Select' : 'Select a contact to start a chat!'
        },
        Galeria : {
            'Name' : 'Name',
            'Type' : 'Type',
            'Owner' : 'Owner',
            'Size' : 'Size',
            'Created' : 'Created',
            'Info' : 'information'
        },
        Configuracion: {
            'Administracion' : 'Administration',
        },
    }
};
