
import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GetTableService implements Resolve<any>
{
    @Output() dataLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
    DatosSalida: any[];
    widgets: any[];
    Table:string;   // Para usar el servicio se debe pasar este parametro
    await: any;
 
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient
    )
    {
        this.dataLoaded.next(false);
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {

        return new Promise<void>((resolve, reject) => {

            Promise.all([
                this.getTable()
                
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get projects
     *
     * @returns {Promise<any>}
     */
    getTable(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get('api/' + this.Table)
                .subscribe((response: any[]) => {
                    if( response.length < 1 ) {
                        return;
                    }
                    //this.dataLoaded.next(true);
                    this.DatosSalida = response;
                    resolve(response);
                    
                }, reject);
        });
    }

    /**
     * Get projects
     *
     * @returns {Promise<any>}
     */
     DeleteRecord(id): Promise<any>
     {
         return new Promise((resolve, reject) => {
             this._httpClient.delete('api/' + this.Table + "/" + id)
                 .subscribe((response: any) => {                     
                     resolve(response);
                 },(Error:any) => reject(Error));
         });
     }


      /**
     * Save product
     *
     * @param product
     * @returns {Promise<any>}
     * AllData = Toda la Data JSON
     * Data = La data que se 
     * RowKey = La llave de donde esta la Data ha editar.
     * RowKeyTwo = Si es blanco no se toma en cuenta.
     * Id = Es el ID que se va a editar
     */
    UpdateRecordUno2(AllData:any, Data, RowKey, RowKeyTwo, Id,Campo?): Promise<any>
    {
         
        return new Promise((resolve, reject) => {
            // Estoy trabnajando directo con el arreglo donde esta la data.
            /*console.log('Entre a UpdateREcordUno')
            console.log(AllData[0][RowKey])
            console.log(AllData)*/
             
                // Encontre el campo llave donde esta la data
                    //console.log(AllData[0][RowKey])
                    var LogreEditar: boolean = false
                    
                    for (var KeyEdit in AllData[0][RowKey]) {
                        
                         var Index: number
                         if (RowKeyTwo != '') {
                             if(Campo){
                                 /*console.log("Campo");
                                 console.log(Campo);*/
                                Index = AllData[0][RowKey][KeyEdit][RowKeyTwo].findIndex(item => item[Campo] == Id);
                                /*console.log("Este es lo que consegui de index con el campo que pasaste");
                                console.log(Index)*/
                             }else{
                                Index = AllData[0][RowKey][KeyEdit][RowKeyTwo].findIndex(item => item.id == Id);
                             }
                            
                         } else {
                            if(Campo){
                                if(Campo=="idUser"){
                                    //console.log("El campo es idUser")
                                    Index = -1
                                    //console.log(`${AllData[0][RowKey][KeyEdit].idUser}==${Id}`)
                                    if(AllData[0][RowKey][KeyEdit].idUser==Id){
                                            //console.log("Esta es la data nueva");
                                            AllData[0][RowKey][KeyEdit] 
                                            LogreEditar = true

                                    }
                                }else
                                {
                                /*console.log("Campo");
                                console.log(Campo);*/
                                Index = AllData[0][RowKey][KeyEdit].findIndex(item => item[Campo]== Id);
                               /*console.log("Este es lo que consegui de index con el campo que pasaste");
                               console.log(Index)*/
                                }
                            }else{
                                Index = AllData[0][RowKey][KeyEdit].findIndex(item => item.id == Id);
                            }
                         } 
                         // Si lo encuentra Edita el JSON
                        if (Index != -1) {
                            if (RowKeyTwo != '') {
                                
                                AllData[0][RowKey][KeyEdit][RowKeyTwo][Index] = Data
                                // Temp mientras se resuelve el array de Lic en el Combo
                                AllData[0][RowKey][KeyEdit][RowKeyTwo][Index].Lic = [Data.lic]
                            } else {
                                AllData[0][RowKey][KeyEdit][Index] = Data
                            }
                            /*console.log(AllData[0])
                            console.log('LOGRE EDITAR: ' + Id)*/
                            LogreEditar = true
                            // Busco el Indice donde esta la data, la Edito y la mando al la memoria
                             
                            // return 0       // Como quue no interrumpe el for que es lo que quiero realmente
                        }  
                    }
                    if (!LogreEditar) {
                        try {
                                /*console.log('AGREGANDO EL ID NUEVO ' + Id)
                                console.log('Lo que trate de agregar')*/
                                
                                if (RowKeyTwo != '') {
                                        //console.log('Pase 1')

                                        // Debo validar si es array
                                        // Temp mientras se resuelve el array de Lic en el Combo                                        
                                        Data.Lic = Array.isArray(Data.lic) ? Data.lic : [Data.lic] 
                                        //console.log(Data)
                                        AllData[0][RowKey][KeyEdit][RowKeyTwo].push(Data) 
                                        AllData[0][RowKey][KeyEdit][RowKeyTwo][AllData[0][RowKey][KeyEdit][RowKeyTwo].length-1].Lic = ['TODOS']
                                } else {
                                    //console.log('Pase 2')
                                    AllData[0][RowKey][KeyEdit].push(Data) 
                                }
                        } catch (error) {
                            console.error(error)
                        }
                    }
                    // Guardo en Memoria. 
                    /*console.log('AGREGANDO EL ID NUEVO ' + Id + 'api/' + this.Table + '/1/')
                    console.log(AllData[0]) */  
                    if (RowKeyTwo != this.Table) {
                        //console.log('PILAS que debes esta mandando la data ha otra tabla....')
                    }
                    this._httpClient.post('api/' + this.Table + '/1/', AllData[0] )
                    .subscribe((response: any) => 
                    { resolve(response);  }, 
                    (Error) => {console.error('Error Agregando Elemento' + Error), reject(Error) });
        });
    }
    
    UpdateRecordUno(AllData:any, Data, RowKey, RowKeyTwo, Id): Promise<any>
    {
         
        return new Promise((resolve, reject) => {
            // Estoy trabnajando directo con el arreglo donde esta la data.
           /* console.log('Entre a UpdateREcordUno')
            console.log(AllData[0][RowKey])
            console.log(AllData)*/
             
                // Encontre el campo llave donde esta la data
                    //console.log(AllData[0][RowKey])
                    var LogreEditar: boolean = false
                    
                    for (var KeyEdit in AllData[0][RowKey]) {
                        
                         var Index: number
                         if (RowKeyTwo != '') {
                            Index = AllData[0][RowKey][KeyEdit][RowKeyTwo].findIndex(item => item.id == Id);
                         } else {
                            Index = AllData[0][RowKey][KeyEdit].findIndex(item => item.id == Id);
                         } 
                         // Si lo encuentra Edita el JSON
                        if (Index != -1) {
                            if (RowKeyTwo != '') {
                                AllData[0][RowKey][KeyEdit][RowKeyTwo][Index] = Data
                                // Temp mientras se resuelve el array de Lic en el Combo
                                //AllData[0][RowKey][KeyEdit][RowKeyTwo][Index].Lic = [Data.lic] //comentado por michel
                            } else {
                                AllData[0][RowKey][KeyEdit][Index] = Data
                            }
                            /*console.log(AllData[0])
                            console.log('LOGRE EDITAR: ' + Id)*/
                            LogreEditar = true
                            // Busco el Indice donde esta la data, la Edito y la mando al la memoria
                             
                            // return 0       // Como quue no interrumpe el for que es lo que quiero realmente
                        }  
                    }
                    if (!LogreEditar) {
                        try {
                                /*console.log('AGREGANDO EL ID NUEVO ' + Id)
                                console.log('Lo que trate de agregar')*/
                                
                                if (RowKeyTwo != '') {
                                        //console.log('Pase 1')

                                        // Debo validar si es array
                                        // Temp mientras se resuelve el array de Lic en el Combo                                        
                                        Data.Lic = Array.isArray(Data.lic) ? Data.lic : [Data.lic] 
                                        //console.log(Data)
                                        AllData[0][RowKey][KeyEdit][RowKeyTwo].push(Data) 
                                        AllData[0][RowKey][KeyEdit][RowKeyTwo][AllData[0][RowKey][KeyEdit][RowKeyTwo].length-1].Lic = ['TODOS']
                                } else {
                                    //console.log('Pase 2')
                                    AllData[0][RowKey][KeyEdit].push(Data) 
                                }
                        } catch (error) {
                            console.error(error)
                        }
                    }
                    // Guardo en Memoria. 
                    /*console.log('AGREGANDO EL ID NUEVO ' + Id + 'api/' + this.Table + '/1/')
                    console.log(AllData[0])*/    
                    if (RowKeyTwo != this.Table) {
                        //console.log('PILAS que debes esta mandando la data ha otra tabla....')
                    }
                    console.log("Voy a guardar la data");
                    this._httpClient.post('api/' + this.Table + '/1/', AllData[0] )
                    .subscribe((response: any) => 
                    { resolve(response);  }, 
                    (Error) => {console.error('Error Agregando Elemento' + Error), reject(Error) });
        });
    }

    SaUpRecordUno(AllData:any, Data, RowKey, RowKeyTwo,Editar:boolean,Id?,idCliente?,Campo?,Horario?:boolean): Promise<any>
    { 
        return new Promise((resolve, reject) => {
                  
                    if(Editar){
                        for (var KeyEdit in AllData[0][RowKey]) {
                        
                            var Index: number
                            if (RowKeyTwo != '') {
                                if(Campo){
                                    //Nombre de campo que queremos editar
                                   Index = AllData[0][RowKey][KeyEdit][RowKeyTwo].findIndex(item => item[Campo] == Id);
                                }else{
                                    //Por defecto el campo sera ID
                                   Index = AllData[0][RowKey][KeyEdit][RowKeyTwo].findIndex(item => item.id == Id);
                                }
                               
                            } else {
                               if(Campo)
                               {
                                   if(Campo=="idUser")
                                   {
                                       Index = -1
                                       if(AllData[0][RowKey][KeyEdit].idUser==Id)
                                       {
                                           AllData[0][RowKey][KeyEdit] 
                                       }
                                   }else
                                   {
                                    Index = AllData[0][RowKey][KeyEdit].findIndex(item => item[Campo]== Id);
                                   }
                               }else{
                                   Index = AllData[0][RowKey][KeyEdit].findIndex(item => item.id == Id);
                               }
                            } 
                            // Si lo encuentra Edita el JSON
                           if (Index != -1) {
                               if (RowKeyTwo != '') {
                                   
                                   AllData[0][RowKey][KeyEdit][RowKeyTwo][Index] = Data
    
                               } else {
                                   AllData[0][RowKey][KeyEdit][Index] = Data
                               }
                               // Busco el Indice donde esta la data, la Edito y la mando al la memoria
                                
                               // return 0       // Como quue no interrumpe el for que es lo que quiero realmente
                           }  
                       }
                    }else{
                            try {
                                    if (RowKeyTwo != '') {
                                        // Debo agregar al arreglo pero primero debo saber de que empresa es
                                        if(Campo){
                                            //Nombre de campo que queremos editar
                                            const IndexClient = AllData[0][RowKey].findIndex((Client: any) => Client[Campo] === idCliente);
                                            if (IndexClient != -1) {
                                                AllData[0][RowKey][IndexClient][RowKeyTwo].push(Data);
                                            }
                                        }else{
                                            //Por defecto el campo sera ID
                                            const IndexClient = AllData[0][RowKey].findIndex((Client: any) => Client.id === idCliente);
                                            if (IndexClient != -1) {
                                                AllData[0][RowKey][IndexClient][RowKeyTwo].push(Data);
                                            }
                                        }
                                        
                                        
                                        
                                    } else {
                                        //No tiene segundo campo
                                        if(Campo){

                                                const IndexClient = AllData[0][RowKey].findIndex((Client: any) => Client[Campo]=== idCliente);
                                                if (IndexClient != -1) {
                                                    AllData[0][RowKey][IndexClient].push(Data);
                                                }
                                        }else{
                                            //Por defecto el campo sera ID
                                            const IndexClient = AllData[0][RowKey].findIndex((Client: any) => Client.id === idCliente);
                                            if (IndexClient != -1) {
                                                AllData[0][RowKey][IndexClient].push(Data);
                                            }
                                        }
                                        
                                         
                                    }
                            } catch (error) {
                                console.log(new Date());
                                console.error(error);
                            }
                    }
                    // Guardo en Memoria. 
                    this._httpClient.post('api/' + this.Table + '/1/', AllData[0] )
                    .subscribe((response: any) => 
                    { resolve(response);  }, 
                    (Error) => {console.error('Error Agregando Elemento' + Error), reject(Error) });
        });
    }

    SaveUpdateHorario(AllData:any, Data,Editar:boolean,Id?,idCliente?,Delete?:boolean){
        return new Promise((resolve, reject) => {
        if(Editar){
            try {
                AllData[0].clientes.forEach(ClientesData => {
                    ClientesData.forEach((HorariosC:any,Index:number) => {
                        if(HorariosC.idCliente == idCliente && HorariosC.idHorario==Id){
                            HorariosC=Data;
                        }
                    });
                }); 
            } catch (error) {
                console.log(new Date());
                console.error(error);
            }
           
        }else if(Delete){
            try {
                AllData[0].clientes.forEach(ClientesData => {
                    ClientesData.forEach((HorariosC:any,Index:number) => {
                        if(HorariosC.idCliente == idCliente && HorariosC.idHorario==Id){
                            ClientesData.splice(Index,1)
                        }
                    });
                }); 
                    
            } catch (error) {
                console.log(new Date());
                console.error(error);
            }

        }else{
            try {
                AllData[0].clientes.forEach(ClientesData => {
                    ClientesData.forEach((HorariosC:any,Index:number) => {
                        if(HorariosC.idCliente == idCliente && Index==0){
                            ClientesData.push(Data);
                        }
                    });
                }); 
                    
            } catch (error) {
                console.log(new Date());
                console.error(error);
            }
        }

        this._httpClient.post('api/' + this.Table + '/1/', AllData[0] )
                    .subscribe((response: any) => 
                    { resolve(response);  }, 
                    (Error) => {console.error('Error Agregando Elemento' + Error), reject(Error) });

        })
    }
    
    PostTable(AllData:any){
        return new Promise((resolve, reject) => {
            this._httpClient.post('api/' + this.Table + '/1/', AllData[0])
            .subscribe((response: any) => {
                resolve(response)
              }, (Error) => {
                reject(Error);
              });
    
        })   
    }

    DeleteRecordUno(AllData:any, Data, RowKey, RowKeyTwo, Id) 
    { 
        return new Promise((resolve, reject) => {
            // Estoy trabnajando directo con el arreglo donde esta la data.
            /*console.log('Entre a DeleteRecordUno')
            console.log(AllData[0][RowKey])
            console.log(AllData)*/
             
                // Encontre el campo llave donde esta la data
                    //console.log(AllData[0][RowKey])
                    var LogreEditar: boolean = false
                    
                    for (var KeyEdit in AllData[0][RowKey]) {
                        
                         var Index: number
                         if (RowKeyTwo != '') {
                            Index = AllData[0][RowKey][KeyEdit][RowKeyTwo].findIndex(item => item.id == Id);
                         } else {
                            Index = AllData[0][RowKey][KeyEdit].findIndex(item => item.id == Id);
                         } 
                         // Si lo encuentra Edita el JSON
                        if (Index != -1) {
                            if (RowKeyTwo != '') {
                                AllData[0][RowKey][KeyEdit][RowKeyTwo].splice(Index,1)
                            } else {
                                AllData[0][RowKey][KeyEdit].splice(Index,1)
                            }
                            /*console.log(AllData[0])
                            console.log('LOGRE Eliminar: ' + Id)*/
                            LogreEditar = true
                            // Busco el Indice donde esta la data, la Edito y la mando al la memoria
                             
                            // return 0       // Como quue no interrumpe el for que es lo que quiero realmente
                        }  
                    }
                   
                    // Guardo en Memoria. 
                    // Se elimina con post porque se quira del arroglo y se manda el unico registro que tienen la memoria, dentro tiene arrays con la data                  
                    this._httpClient.post('api/' + this.Table + '/1/', AllData[0] )
                    .subscribe((response: any) => 
                    { resolve(response);  }, 
                    (Error) => {console.error('Error Agregando Elemento' + Error), reject(Error) });
        });
    }
}
