import { Injectable, Output, EventEmitter } from '@angular/core';
import { Observable, Subscriber, from } from 'rxjs';
import { concatMap, delay, takeWhile } from 'rxjs/operators';
import { MutationService } from '../../fake-db/Mutation/mutation.service';
import { Apollo } from 'apollo-angular';
import { LoginSessionService } from '../../services/global/LoginSession.service'; 
import { EcommerceService } from '../../main/apps/e-commerce/service/e-commerce.service';

@Injectable({
  providedIn: 'root'
})
export class MassiveMessageService {

  @Output() countMessageSent: EventEmitter<number> = new EventEmitter<number>();
  @Output() numberOfMessageToSend: EventEmitter<number> = new EventEmitter<number>();
  @Output() moveIcon: EventEmitter<boolean>  =new EventEmitter<boolean>();
  @Output() showCounter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() processSuccessful: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() totalSent: EventEmitter<number> =  new EventEmitter<number>();
  public numberMessagesSent: number = 0;
  private idEmpresa: string = '';
  public stopObservable: boolean = true;
  public restOfNumbersAndMessages: any[] = [];
  public urlImageExist: boolean = false;
  public urlImageToSend: string = '';
  constructor(  private mutationService: MutationService,
                private apollo: Apollo,
                private loginService: LoginSessionService,
                private eccomerceService: EcommerceService
    ) {
      this.idEmpresa = this.loginService.GetDataUser().idEmpresa;
      console.log(this.idEmpresa);
      this.showCounter.emit(false);
    }


  takeAllNumbers(allNumberAndMessage: any[], lic: string) {
    // Observable que toma todos los mensajes y emite de uno en uno
    // Y solo pasa al siguiente mensaje hasta que se envié el actual 
    this.restOfNumbersAndMessages = allNumberAndMessage;
    this.stopObservable = true;
    this.numberOfMessageToSend.emit(allNumberAndMessage.length);
    this.moveIcon.emit(true);
    this.processSuccessful.emit(false);
    return from(allNumberAndMessage).pipe(
      concatMap((contact) => this.sendOneMessage(contact, lic)),
      takeWhile(() => this.stopObservable)
    );
  }

  sendOneMessage(contact: any, lic: string): Observable<any> {
    // Procesa un único mensaje a la vez

    const { phone, message } = contact;
    return new Observable((subscriber: Subscriber<any>) => {
      let msgText = this.messageStructure(phone, message, lic);
      
      if(this.urlImageExist) {
        // Verifica si este mensaje tiene una imagen que debe ser enviada

        // Crea la estructura del mensaje que debe ser enviada
        let msgImage = this.messageStructure(phone, this.urlImageToSend, lic, '1');
        
        let sendToMsg = from([msgText, msgImage]);

        // Envia primero el texto de mensaje y luego la imagen  
        sendToMsg
          .subscribe((msg) => {
            //this.mutationService.createChat(this.apollo, msg).then(res => {
              this.mutationService.createChatCola(this.apollo, msg).then(res => {
              console.log(res);
            }).catch((err) => console.error(err))
          }, 
          (err) => console.error(err),
          () => {
            this.restOfNumbersAndMessages = this.restOfNumbersAndMessages.filter((contact_) => contact_ !== contact);
            ++this.numberMessagesSent;
            this.countMessageSent.emit(this.numberMessagesSent);
            subscriber.next('Mensaje enviado');
            subscriber.complete();
          });
        return;
      }
      
      //this.mutationService.createChat(this.apollo, msgText).then(res => {
        this.mutationService.createChatCola(this.apollo, msgText).then(res => {
        this.restOfNumbersAndMessages = this.restOfNumbersAndMessages.filter((contact_) => contact_ !== contact);
        console.log(res);
        ++this.numberMessagesSent;
        this.countMessageSent.emit(this.numberMessagesSent);
        subscriber.next('Mensaje enviado');
        subscriber.complete();
      }).catch((err) => console.error(err))
    }).pipe(delay(1000));
  }


  messageStructure(phone: string, message: string, lic: string, contentType: string = '0') {
    const date = new Date();
    lic = lic.toString();
    lic = lic.includes('@c.us')? lic : `${lic}@c.us`;
    phone = phone.toString();
    phone = phone.includes('@c.us')? phone : `${phone}@c.us`; 
    phone = phone.includes('58') ? phone : `58${phone}`;
    return {
        operador: "-1",   // Solo para pruebas
        idEmpresa: this.idEmpresa,
        phone: phone,
        idConversacion: `${lic.replace('@c.us', '')}_${phone.replace('@c.us', '')}_${date.getFullYear()}_${date.getMonth() + 1}-${date.getDate()}`,
        idmessage: `${phone.replace('@c.us', '')}_true_${this.eccomerceService.GenerateUniqueID()}`,
        me_to: "o",
        contentType: `${contentType}`,
        content: message,
        TimeRead: new Date().toISOString(), 
        timestamp: 	Math.floor(date.getTime()/1000.0),
        status: 0,
        statusc: 0,
        readmsg: false,
        lic: `${lic}`
    };
  }
}
