import gql from "graphql-tag";
import { HttpClient } from "@angular/common/http";
import { Apollo } from "apollo-angular";

import { EventEmitter } from '@angular/core';

export class FileManagerFakeDb {

    public static Counting: EventEmitter<Number> = new EventEmitter();

    constructor(
        private apollo: Apollo
    ) {

    }

    static apollo: Apollo
   
    public static files = {
        id: '1',
        item: { id: 1 },
        title: 'Galeria',
        columnsResum: ['url', 'title', 'owner', 'modified', 'menu'],
        type: ['img', 'string', 'string', 'date', 'menu'],
        typeResum: ['img', 'string', 'string', 'date', 'menu'],
        menu: ["Eliminar/delete"],
        'rows': [ ]
    };

    public static CargarGaleria(Token: string) {
        return this.files
    }

    public static ConsultaGaleria(apollo: Apollo, Token: string, _httpClient: HttpClient) {
        let data = apollo.watchQuery<any>({
            query: GaleriaByEmpresa,
            variables: {
                token: Token
            }
        })
            .valueChanges
            .subscribe((result) => {
                if (!result.data.GaleriaByEmpresa) {
                    return;
                }
                let DataGaleria = result.data.GaleriaByEmpresa //Guardo la data obtenida
/*                 console.log(DataGaleria); */
                const DataG = {
                    title: 'Galeria',
                    columnsResum: ['url', 'title', 'owner', 'modified', 'menu'],
                    type: ['img', 'string', 'string', 'date', 'menu'],
                    typeResum: ['img', 'string', 'string', 'date', 'menu'],
                    menu: ["Eliminar/delete"],//"Editar/edit",
                    'rows': DataGaleria
                }
                /*
                _httpClient.post('api/file-manager/1', { id: '1', item: { id: 1 }, Data: DataGaleria })
                            .subscribe(
                            (response: any) => { console.log(response) }, 
                            (Error) => {
                                console.error('file-manager');
                                console.error(Error);
                            });
                */
                DataGaleria.forEach(element => {
                    this.files.rows.push(element)
                });

                try {
                    this.Counting.emit(DataGaleria.length)
                } catch (error) {
                    this.Counting.emit(0)
                }

            });
        return this.files
    }

    static async QueryGallery() {
        let querySubscription = this.apollo.watchQuery<any>({
            query: GaleriaByEmpresa,
            variables: {
                token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJEYXRhIjp7IklkSG9yYXJpbyI6W10sIl9pZCI6IjYwYTU4MTJiOWUxNDdhMjFlOGZmNWYyZiIsIkF2YXRhciI6Imh0dHBzOi8vd3d3LmV0b2JlLm14L2Fzc2V0cy9hcHAvaW1nL2UuanBnIiwiaWRpb21hIjoiZXMiLCJQZXJtaXNvcyI6WyI0IiwiOSIsIjExIl0sIkNhbmFsZXMiOlsiNTE5NTcwNTI4NzJAYy51cyJdLCJVbHRpbWFDb25leGlvbiI6IjIwMjEtMDQtMTNUMDU6MDU6NDEuNjk1WiIsInN0YXR1cyI6dHJ1ZSwiT25saW5lIjp0cnVlLCJpc01hc3RlciI6ZmFsc2UsImlkVXNlciI6IjMwMTIiLCJpZEVtcHJlc2EiOiJwcnVlYmExMjM0NTYiLCJpZFJvbCI6IjIiLCJOb21icmUiOiJqaG9uYXR0YW4iLCJBcGVsbGlkbyI6IiIsIkVtYWlsIjoiamVybXNvZnQyQGdtYWlsLmNvbSIsIkNsYXZlIjoibm9JbmZvIiwiUGhvbmUiOiIwIiwiRGlyZWNjaW9uIjoiUGVydSIsIlRpcG9Vc3VhcmlvIjoiYWdlbnRlIn0sImlhdCI6MTYyNDMwODA2MywiZXhwIjoxNjI0Mzk0NDYzfQ.KHLvg8VVv3fga99BqhmO8XNdtOuCgXtOvSHBJFC4yBk"
            }
        })
            .valueChanges
            .subscribe(({ data, loading }) => {
                //return data.UsersByidEmpresa
            });
    }

}



export const GaleriaByEmpresa = gql`
  query GaleriaByEmpresa($token:String) {
    GaleriaByEmpresa(token:$token) {
    id
    idEmpresa
    title
    type
    url
    message
    owner
    size
    created
    modified
    available
    error
    }
  }
`;
