export class CanalesFakeDb
{

    public static canales =[ 
        /* {
            idCliente:'1',
            id:'ABGHSKWURJS-15SSFDDJ',
            canales:[
                {
                "lic": "584120202020",
                "type": "wa",
                "version": "8.0",
                "totalmsg": "47",
                "online": true,
                "status": true,
                "bateria": "65%",
                "ultconex": "2021-03-16T23:44:02.491Z"
              },
              {
                "lic": "584121311690",
                "type": "wa",
                "version": "8.0",
                "totalmsg": "47",
                "online": true,
                "status": true,
                "bateria": "37%",
                "ultconex": "2021-03-16T23:44:02.491Z"
              },
              {
                "lic": "51957052872",
                "type": "wa",
                "version": "8.0",
                "totalmsg": "47",
                "online": true,
                "status": true,
                "bateria": "65%",
                "ultconex": "2021-03-16T23:44:02.491Z"
              }
            ]
        } *//*,
        {
            idCliente:'2',
            id:'ABGHSKWURJS-16SSDDK',
            canales:[
                {
                    "lic": "584120202020",
                    "type": "wa",
                    "version": "8.0",
                    "totalmsg": "47",
                    "online": true,
                    "status": true,
                    "bateria": "65%",
                    "ultconex": "2021-03-16T23:44:02.491Z"
                  },
                  {
                    "lic": "584121311690",
                    "type": "wa",
                    "version": "8.0",
                    "totalmsg": "47",
                    "online": true,
                    "status": true,
                    "bateria": "37%",
                    "ultconex": "2021-03-16T23:44:02.491Z"
                  },
                  {
                    "lic": "51957052872",
                    "type": "wa",
                    "version": "8.0",
                    "totalmsg": "47",
                    "online": true,
                    "status": true,
                    "bateria": "65%",
                    "ultconex": "2021-03-16T23:44:02.491Z"
                  }
            ]
        }*/
    ]

    public static CargaCanales(Token: string) {
        // Aqui se debe hacer la consulta de graphql
        return this.canales
    }
}