export class ChatPanelFakeDb
{
    public static contacts = [
        {
            'id'    : '5725a680b3249760ea21de52',
            'name'  : 'Alice Freeman',
            'avatar': 'assets/images/avatars/alice.jpg',
            'status': 'online',
            'mood'  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit...',
            'unread': '2'
        }, 
        {
            'id'    : '5725a68018c663044be49cbf',
            'name'  : 'Tyson',
            'avatar': 'assets/images/avatars/Tyson.jpg',
            'status': 'do-not-disturb',
            'mood'  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit...'
        },
        {
            'id'    : '5725a6809413bf8a0a5272b1',
            'name'  : 'Velazquez',
            'avatar': 'assets/images/avatars/Velazquez.jpg',
            'status': 'online',
            'mood'  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit...'
        }
    ];

    public static chats = [
        {
            'id'    : '1725a680b3249760ea21de52',
            'dialog': [
                {
                    'who'    : '5725a680b3249760ea21de52',
                    'message': 'Quickly come to the meeting room 1B, we have a big server issue',
                    'time'   : '2017-03-22T08:54:28.299Z'
                },
                {
                    'who'    : '5725a6802d10e277a0f35724',
                    'message': 'I’m having breakfast right now, can’t you wait for 10 minutes?',
                    'time'   : '2017-03-22T08:55:28.299Z'
                } 
            ]
        },
        {
            'id'    : '2725a680b8d240c011dd2243',
            'dialog': [
                {
                    'who'    : '5725a680606588342058356d',
                    'message': 'Quickly come to the meeting room 1B, we have a big server issue',
                    'time'   : '2017-04-22T01:00:00.299Z'
                } 
            ]
        },
        {
            'id'    : '3725a6809413bf8a0a5272b4',
            'dialog': [
                {
                    'who'    : '5725a68009e20d0a9e9acf2a',
                    'message': 'Quickly come to the meeting room 1B, we have a big server issue',
                    'time'   : '2017-04-22T02:10:00.299Z'
                }
            ]
        }
    ];

    public static user = [
        {
            'id'      : '5725a6802d10e277a0f35724',
            'name'    : 'John Doe',
            'avatar'  : 'assets/images/avatars/profile.jpg',
            'status'  : 'online',
            'mood'    : '',
            'chatList': [
                {
                    'chatId'         : '1725a680b3249760ea21de52',
                    'contactId'      : '5725a680b3249760ea21de52',
                    'lastMessageTime': '2017-06-12T02:10:18.931Z'
                },
                {
                    'chatId'         : '2725a680b8d240c011dd2243',
                    'contactId'      : '5725a680606588342058356d',
                    'lastMessageTime': '2017-02-18T10:30:18.931Z'
                },
                {
                    'chatId'         : '3725a6809413bf8a0a5272b4',
                    'contactId'      : '5725a68009e20d0a9e9acf2a',
                    'lastMessageTime': '2017-03-18T12:30:18.931Z'
                }
            ]
        }
    ];


    public static CargarConversaciones(Token: string) {
        // Aqui se debe hacer la consulta de graphql
        return this.user
    }


    public static CargarContactos(Token: string) {
        // Aqui se debe hacer la consulta de graphql
        return this.contacts
    }

    public static CargarChats(Token: string) {
        // Aqui se debe hacer la consulta de graphql
        return this.contacts
    }
}
