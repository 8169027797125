import { Component, Inject, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ChatService } from 'app/main/apps/chat/chat.service';
import { SubscriptionGraphqlService } from 'app/services/global/subscription-graphql.service';
import gql from 'graphql-tag';
import { Subject } from 'rxjs';
import { GetTableV2Service } from '../../../services/global/getTableV2.service';

const SUBSCRIBE_QUERY = gql`
subscription($input:String){
  newChat(input:$input){
    idEmpresa
    phone
    idConversacion
    idmessage
    timestamp
    me_to
    contentType
    content
    TimeRead
    status
    statusc
    readmsg
    operador
    lic
    tag
    etiqueta
  }
}
`;


@Component({
  selector: 'app-simulador',
  templateUrl: './simulador.component.html',
  styleUrls: ['./simulador.component.scss']
})

export class SimuladorComponent implements OnInit {

  idOld

  InpUser: any = {
    'id': '5725a6802d10e277a0f35724',
    'name': 'John Doe',
    'avatar': 'assets/images/avatars/profile.jpg',
    'status': 'online',
    'mood': 'it\'s a status....not your diary...',
    'chatList': [
      {
        'id': '1725a680b3249760ea21de52',
        'contactId': '5725a680b3249760ea21de52',
        'name': 'Alice Freeman',
        'unread': 4,
        'lastMessage': 'You are the worst!',
        'lastMessageTime': '2017-06-12T02:10:18.931Z'
      },
      {
        'id': '2725a680b8d240c011dd2243',
        'contactId': '5725a680b8d240c011dd224b',
        'name': 'Josefina',
        'unread': null,
        'lastMessage': 'We are losing money! Quick!',
        'lastMessageTime': '2017-02-18T10:30:18.931Z'
      },
      {
        'id': '3725a6809413bf8a0a5272b4',
        'contactId': '5725a6809413bf8a0a5272b1',
        'name': 'Velazquez',
        'unread': 2,
        'lastMessage': 'Quickly come to the meeting room 1B, we have a big server issue',
        'lastMessageTime': '2017-03-18T12:30:18.931Z'
      }
    ]
  }
  
  InpContact: any /* = {
    avatar: "assets/images/avatars/alice.jpg",
    id: "5725a680b3249760ea21de52",
    mood: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    name: "Alice Freeman",
    status: "online"
  }*/

  InpDialog = [];

  Data: any
  idConversacion:any


  constructor(  private _SubscriptionGraphqlService: SubscriptionGraphqlService,
                @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

  ngOnInit() { //TODO TRY
    /* console.log('Data en el simulador')
    console.log(this.data) */
    this.SubChats()

    let Datafinal: any = []

    const DataContacto = {//creo los datos del contacto
      avatar: "assets/images/avatars/profile.jpg",
      id: this.data.id,
      mood: "",
      name: this.data.contacto,
      status: "online"
    }
    this.InpContact = DataContacto
    this.idConversacion=DataContacto.id

    //recorro el arreglo de MSJ para obtener todos los mensajes 
    this.data.Msj.forEach(element => {
      this.SetChat(element)

/*       const DataMensaje = {
        'who': element.phone,
        'contenType': element.contentType || element.contenType || '0',
        'message': element.content || element.message,
        'time': element.timestamp,
        me_to: element.me_to,
        idEmpresa: element.idEmpresa,
        phone: element.phone,
        idConversacion: element.idConversacion,
        idmessage: element.idmessage,
        timestamp: element.timestamp || new Date(),
        TimeRead: element.TimeRead || new Date(),
        status: element.Status || 0,
        statusc: element.statusc || 0,
        readmsg: element.readmsg || false,
        operador: element.operador,
        lic: element.lic,
        etiqueta: element.etiqueta ? element.etiqueta : " "
      } */
      //console.log(DataMensaje)
/*       Datafinal.push(DataMensaje) */
    });

/*     this.InpDialog = Datafinal */

    /*this.data.Msj[1].TimeRead = new Date(this.data.Msj[1].TimeRead.TimeRead / 1000)
    this.data.Msj[1].lic = parseFloat(this.data.Msj[1].lic);
    this.data.Msj[1].status = 0
    this.data.Msj[1].who = this.data.Msj[1].phone;
    this.data.Msj[1].message = this.data.Msj[1].content || this.data.Msj[1].message //'Esto es una prueba '//this.data.Msj[1].content;
    this.data.Msj[1].time = this.data.Msj[1].timestamp;
    this.data.Msj[1].timestamp = (new Date(this.data.Msj[1].timestamp * 1000))
    this.InpDialog.push(this.data.Msj[1]);*/

  }

    ngOnChanges(changes: SimpleChanges): void {
/*       console.log('pase por ngonchanges',changes) */
    }

    SetChat(element:any){ //TODO TRY
      let contenido=element.content || element.message
      //contenido = contenido.replace(/<br>/g, '\n')
      const DataMensaje = {
        'who': element.phone,
        'contenType': element.contentType || element.contenType || '0',
        'message': contenido,//element.content || element.message,
        'time': element.TimeRead,
        me_to: element.me_to,
        idEmpresa: element.idEmpresa,
        phone: element.phone,
        idConversacion: element.idConversacion,
        idmessage: element.idmessage,
        timestamp: element.timestamp || new Date(),
        TimeRead: element.TimeRead || new Date(),
        status: element.Status || 0,
        statusc: element.statusc || 0,
        readmsg: element.readmsg || false,
        operador: element.operador,
        lic: element.lic,
        etiqueta: element.etiqueta ? element.etiqueta : " "
      }
      //console.log(DataMensaje)
      this.InpDialog.push(DataMensaje)
    }

    SubChats() {
         console.log("suscripcion en chats")
   //en el input va el id del operador
   //console.log('pase por la subscripcion')
   try {
   this._SubscriptionGraphqlService.createSubscriptionObservable(SUBSCRIBE_QUERY,{input:"Todo"}).subscribe(eventData => { 
      let chat=eventData.data.newChat
      console.log("me llego este chat: ",chat)
/*       console.log(this.idOld) */
      if (this.idOld!=eventData.data.newChat.idmessage) {
          this.idOld=eventData.data.newChat.idmessage
          //TODO: Buscar la conversacion y hacer push 
          if (chat.idConversacion == this.idConversacion) {
            this.SetChat(chat)
          }
      }
  }, (err) => {
    console.log('Err');
    console.log(err);
    this.SubChats()
  });
   } catch (error) {
       console.log(error)
   }
}

}
