export class TipoPagoDB
{
    public static tipopago = [
        {
            idTipoPago:0,
            descripTipoPago:"[Seleccione una opción]"
        },
        {
            idTipoPago:1,
            descripTipoPago:"PayPal"
        },
        {
            idTipoPago:2,
            descripTipoPago:"Tarjeta Crédito"
        },
        {
            idTipoPago:3,
            descripTipoPago:"Transferencia"
        }
    ]
    
    public static prefijo = [
        {
            idPrefijo:0,
            Prefijo:"[Prefijo]"
        },
        {
            idPrefijo:51,
            Prefijo:"+51"
        },
        {
            idPrefijo:57,
            Prefijo:"+57"
        },
        {
            idPrefijo:58,
            Prefijo:"+58"
        }
    ] 
    
}