import { Injectable, EventEmitter } from '@angular/core';
import { LoginSessionService } from "app/services/global/LoginSession.service";
import { EcommerceService } from 'app/main/apps/e-commerce/service/e-commerce.service';
import { Apollo } from 'apollo-angular';
import {MutationService} from 'app/fake-db/Mutation/mutation.service';

@Injectable({
  providedIn: 'root'
})
export class OfflineService {
    DataOnline:any = "";
    TokenOnline:String = "";
    
      
  constructor(
        private _LoginSessionService: LoginSessionService,
        private _ConsultServ: EcommerceService,
        private apollo: Apollo,
        private _mutationService:MutationService
      ) { 

  }


  Offline(Token:String){
    let UserLogin = this._LoginSessionService.GetDataOfflineOnline();
    let Dataupdate ={
        "idUser": UserLogin.idUser,
        "idEmpresa":UserLogin.idEmpresa,
        "Online": false
    }

    this._mutationService.updateOnOff(this.apollo, Token, Dataupdate).then((res:any)=>{
      console.log(res)
    }).catch((Error) => { console.error(Error) });

    // console.log("Token");
    // console.log(this.TokenOnline);
    // this._ConsultServ.EditServiceAgent(this.apollo,Dataupdate,this.TokenOnline)
    // .then((res:any)=>console.log(res))
    // .catch((err:any)=>console.error(err));
    //Limpio la memoria donde guarde los datos
    UserLogin = "";
    Dataupdate = {
        "idUser":"",
        "idEmpresa":"",
        "Online": false
    }
  }

  Online(Token:any,Data:any){
    // console.log("Online");
    // console.log(Data);
    // console.log(this.TokenOnline)
    this.TokenOnline=Token;
    // console.log(this.TokenOnline);
    this.DataOnline=Data;
    this._ConsultServ.EditServiceAgent(this.apollo,Data,Token)
    .then((res:any)=>console.log(res))
    .catch((err:any)=>console.error(err));
    setTimeout(() => {
     this.UpdateLastConexion(this.TokenOnline,this.DataOnline)
    }, 120000);

  }

  UpdateLastConexion(Token:any,Data:any){
    // console.log("UpdateLastConexion");
    let Fecha = new Date();
    this.DataOnline.UltimaConexion=new Date(Fecha);
    // this._ConsultServ.EditServiceAgent(this.apollo,Data,Token)
    // .then((res:any)=>console.log(res))
    // .catch((err:any)=>console.error(err));

  }

}
