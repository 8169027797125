import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ShowDataService {

  @Output() showData: EventEmitter<boolean>  = new EventEmitter<boolean>();

  constructor() { }
}
