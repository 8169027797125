import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { FuseUtils } from '@fuse/utils';
import { TranslateService } from '@ngx-translate/core';
import { Apollo } from 'apollo-angular';
import { GetTableService } from 'app/main/apps/e-commerce/getTable.service';
import { EcommerceService } from 'app/main/apps/e-commerce/service/e-commerce.service';
import { GetTableV2Service } from 'app/services/global/getTableV2.service';
import { ShowTotalesService } from 'app/services/global/ShowTotales.service';
import { isEmpty } from 'lodash';

@Component({
  selector: 'app-profile-view',
  templateUrl: './profile-view.component.html',
  styleUrls: ['./profile-view.component.scss']
})
export class ProfileViewComponent implements OnInit {

  //@ViewChild('myname') button:any;
  @Input('agent') data: any
  @Input() DataAlAgente: any
  @Input() BtnCancelar = false
  @Input() Lectura= false;

  @Output() botCancelar: EventEmitter<boolean> = new EventEmitter(); 
  avatar: any = 'assets/images/avatars/profile.jpg'
  newUser = true;
  showEditimg = false;
  hide = true;
  VissibilityPass= true;
  isChecked = false;
  toppings = new FormControl();
  allComplete: boolean = false;
  ProcesoAlaMitad: boolean = false;

  Prefijo: string  = 'Agent.';
  Prefijoi18n: string = 'Generico.'

  
  task: Task = {
    name: 'Select All',
    permitir: false,
    subtasks: [
      { name: 'Allow 1', permitir: false },
      { name: 'Allow 2', permitir: false },
      { name: 'Allow 3', permitir: false }
    ]
  };
  //idCliente=['1','2']
  idCliente = [/*'2526', '2525', '2527'*/]
  clienteSelect = {
    clientes: [],
    canales: [],
    horarios: [],
    roles: [],
  }

  clientes = [];
  canales = [];
  horarios = [];
  tipoUser = [];
  roles = [];

  Data: any;

  ClientSelect: any[] = [];
  IdEmpresa: any;

  public form: FormGroup;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  valueCanales: any;
 

  constructor(public fb: FormBuilder,
    _ShowTotalesService: ShowTotalesService,
    private _GetTableService: GetTableService,
    private _GetTableV2Service: GetTableV2Service,
    private apollo: Apollo,
    public ConsultServ: EcommerceService,
    private _httpClient: HttpClient,
    private _matSnackBar: MatSnackBar,
    private translate: TranslateService) {

    _ShowTotalesService.changeshow(false);

    this.firstFormGroup = this.fb.group({
      nombre: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      apellido: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      //contrasena: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
      telefono: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
      direccion: ['', Validators.compose([Validators.required, Validators.minLength(5)])],
    });
    this.secondFormGroup = this.fb.group({
      clientes: [''],
      canales: [''],
      horarios: [''],
      tipousuario: [''],
      rol: [''],
    });
    //console.log("Voy a buscar la data en el api clientes")
    _GetTableV2Service.TableURL = "clientes"
    _GetTableV2Service.getData().then(async DataIn => {
      /*console.log('DataIn de cclientes')
      console.log(DataIn)*/
      DataIn.forEach((e: any) => {
        let cliente = {
          id: e.id,
          name: e.name
        }

        e.canales.forEach(eCanales => {
          let canal = {
            idCliente: e.id,
            id: eCanales.id,
            lic: eCanales.lic,
            tipo: eCanales.type
          }
          this.canales.push(canal)
        });

        e.Horarios.forEach(eHorarios => {
          let horario = {
            idCliente: e.id,
            idHorario: eHorarios.idHorario,
            Descripcion: eHorarios.Descripcion
          }
          this.horarios.push(horario)
        });

        this.idCliente.push(e.id)
        this.clientes.push(cliente)
      })
    }).catch((Error) => console.error(Error))

    _GetTableV2Service.TableURL = "roles"
    _GetTableV2Service.getData().then(async DataIn => {
      
      try {  // Jhonattan Implement
          this.IdEmpresa = DataIn[0].idEmpresa
          DataIn[0].rows.forEach(eRoles => {
          let rol = {
            idRol: eRoles.id,
            Rol: eRoles.Rol,
            Descripcion: eRoles.Descripcion,
            Permisos: eRoles.Idpermisos
          }
          this.roles.push(rol)
        });
      } catch (error) {
        
      }
      
      
    }).catch((Error) => { console.error(Error) })

    this.clienteSelect.clientes = this.idCliente
    this.clienteSelect.canales = this.canales
    this.clienteSelect.horarios = this.horarios
    this.clienteSelect.roles = this.roles
    /*console.log("this.clienteSelect");
    console.log(this.clienteSelect);*/
  }

  ClickCancel() {
    if (!this.ProcesoAlaMitad) {
      //console.log('Click Cancelar')
      this.botCancelar.next(true);
    } else {
      // enviar mensajes de que existe un proceso a la mitas y si de verdad desea cancelar
    }

  }
  

  ngOnInit() {
    if(this.Lectura){
      this.firstFormGroup.disable()
    }
    
    //this.secondFormGroup.disable()
    /*console.log('esto me llego')
    console.log(this.data)*/
    this.valueCanales = this.canales.filter((x:any,index:number) => (x.lic == this.data.data.Canales[index]))
    /*console.log("Estos son sus canales");
    console.log(this.valueCanales);*/
    const data = this.data.data
    if (!isEmpty(data)) {
      this.VissibilityPass = false;
      this.newUser = false;
      this.avatar = data.Avatar
      this.firstFormGroup.setValue({
        nombre: data.Nombre || data.name,
        apellido: data.Apellido,
        email: data.Email,
        //contrasena: data.Clave || '',
        telefono: data.Phone || "0",
        direccion: data.Direccion || '',
      });
      this.avatar = data.Avatar || data.avatar
      this.isChecked = data.Transferir || false //verificar este campo que no lo trae el agente de dashboard
      this.secondFormGroup.setValue({
        clientes: [''],
        canales: data.Canales || [''] ,
        horarios: data.idHorario || [''],
        tipousuario: data.TipoUsuario,
        rol: data.idRol,
      });
    }
  }

  updateAllComplete() {
    this.allComplete = this.task.subtasks != null && this.task.subtasks.every(t => t.permitir);
  }

  someComplete(): boolean {
    if (this.task.subtasks == null) {
      return false;
    }
    return this.task.subtasks.filter(t => t.permitir).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.task.subtasks == null) {
      return;
    }
    this.task.subtasks.forEach(t => t.permitir = completed);
  }

  uploadFile($event: any) {
    const file = $event.target.files[0]
    /*       console.log(file); // outputs the first file */
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      this.data.avatar = reader.result
      this.avatar = reader.result
    }
  }

  createUser() {
    //console.log(this.firstFormGroup.value)
    //console.log(this.secondFormGroup.value)
    //this.data.Nombre= this.firstFormGroup.get("nombre").value
    let allForm = Object.assign(this.firstFormGroup.value, this.secondFormGroup.value)

    const DataRol: any = this.clienteSelect.roles.filter((r: any) => r.idRol == allForm.rol)
    this.VissibilityPass = true
    const DataCreateuser = {
      "IdHorario": allForm.horarios,
      "Avatar": this.avatar || "assets/images/avatars/profile.jpg",
      "idioma": "es",
      "Permisos": DataRol[0].Permisos,
      "Canales": allForm.canales,
      "UltimaConexion": new Date(),
      "status": false,
      "Online": false,
      "isMaster": false,
      "idUser": FuseUtils.generateGUID(),
      "idEmpresa": this.IdEmpresa,
      "idRol": allForm.rol,
      "Nombre": allForm.nombre,
      "Apellido": allForm.apellido,
      "Email": allForm.email,
      "Clave": allForm.contrasena,
      "Phone": allForm.telefono,
      "Direccion": allForm.direccion,
      "TipoUsuario": allForm.tipousuario,
      "idCliente": this.clienteSelect.clientes
    }

    this.ConsultServ.CreateServiceUser(this.apollo, DataCreateuser).then((Resultado) => {

      const DataAgentes = this.data.arr

      DataAgentes.clientes.push(DataCreateuser)

      this._httpClient.post('api/agentes-adm/1', DataAgentes).subscribe((response: any) => { /*console.log('update realizado')*/ }, (Error) => { console.error('User'); console.error(Error) });

      let lTranslateMSGOK;

      this.translate.get('Generico.Aceptar').subscribe(TranslateMSGOK => {
     lTranslateMSGOK = TranslateMSGOK
   })

   this.translate.get('Agent.UserAgregado').subscribe(TranslateMSG => {

     this._matSnackBar.open(TranslateMSG, lTranslateMSGOK, {
       verticalPosition: 'top',
       duration: 3500
     });

   })


      /* 
      this._matSnackBar.open('UserAgregado', 'OK', {
        verticalPosition: 'top',
        duration: 3500
      }); */

    }).catch((Error) => { console.error(Error) })

  }

  updateUser() {
    let allForm = Object.assign(this.firstFormGroup.value, this.secondFormGroup.value)
    /*console.log("Estos son los permisos de Jhonattan");
    console.log(this.data.data.Permisos)
    console.log("Segun esto")*/
    const DataRol: any = this.clienteSelect.roles.filter((r: any) => r.idRol == allForm.rol)
    //console.log(DataRol)

    const DataUpdateUser = {
      Apellido: allForm.apellido || this.data.data.Apellido,
      Avatar: this.avatar || this.data.data.Avatar,
      Canales: allForm.canales || this.data.data.Canales,
      //Clave: allForm.contrasena || this.data.data.Clave,
      Direccion: allForm.direccion || this.data.data.Direccion,
      Email: allForm.email || this.data.data.Email,
      Menu: this.data.data.Menu,
      Nombre: allForm.nombre || this.data.data.Nombre,
      Online: this.data.data.Online,
      Permisos: DataRol[0].Permisos || this.data.data.Permisos,
      Phone: allForm.telefono || this.data.data.Phone,
      TipoUsuario: allForm.tipousuario || this.data.data.TipoUsuario,
      Transferir: this.data.data.Transferir || false,
      UltimaConexion: this.data.data.UltimaConexion || new Date(),
      idCliente: this.clienteSelect.clientes || this.data.data.idCliente,
      idEmpresa: this.IdEmpresa || this.data.data.idEmpresa,
      IdHorario: allForm.horarios || this.data.data.idHorario,
      idRol: allForm.rol || this.data.data.idRol,
      idUser: this.data.data.idUser,
      idioma: this.data.data.idioma,
      isMaster: this.data.data.isMaster,
      status: this.data.data.status
    }

    this.ConsultServ.EditServiceUser(this.apollo, DataUpdateUser).then((Res) => {

      const DataAgentes = this.data.arr

      const indexCliente = DataAgentes.clientes.findIndex(d => d.idUser == this.data.data.idUser)

      DataAgentes.clientes[indexCliente] = DataUpdateUser

      this._httpClient.post('api/agentes-adm/1', DataAgentes).subscribe((response: any) => { /*console.log('update realizado')*/ }, (Error) => { console.error('User'); console.error(Error) });


      let lTranslateMSGOK;

      this.translate.get('Generico.Aceptar').subscribe(TranslateMSGOK => {
     lTranslateMSGOK = TranslateMSGOK
   })

   this.translate.get('Agent.UserEditado').subscribe(TranslateMSG => {

     this._matSnackBar.open(TranslateMSG, lTranslateMSGOK, {
       verticalPosition: 'top',
       duration: 3500
     });

   })
    /*   this._matSnackBar.open('User Editado', 'OK', {
        verticalPosition: 'top',
        duration: 3500
      }); */
        
    }).catch((Error) => { console.error(Error) });

  }

  newRol() {
    //console.log('Deberia abrir el componente de roles')
  }

  filterData() {
    let idCliente = this.clienteSelect.clientes
    this.ClientSelect = idCliente
    //console.log('ClientSelect')
    //console.log(this.ClientSelect)
   // console.log(idCliente)
    this.clienteSelect.canales = [],
      this.clienteSelect.roles = [],
      this.clienteSelect.horarios = []

    idCliente.forEach((id: String) => {
      //console.log(id)
      if (isEmpty(this.clienteSelect.canales)) {
        this.clienteSelect.canales = this.canales.filter((c: any) => c.idCliente == id)
      } else {
        this.clienteSelect.canales = this.clienteSelect.canales.concat(this.canales.filter((c: any) => c.idCliente == id))
      }
      if (isEmpty(this.clienteSelect.horarios)) {
       // console.log(this.horarios[0].idCliente, id)
        this.clienteSelect.horarios = this.horarios.filter((h: any) => h.idCliente == id)
      } else {
        this.clienteSelect.horarios = this.clienteSelect.horarios.concat(this.horarios.filter((c: any) => c.idCliente == id))
      }
      if (isEmpty(this.clienteSelect.roles)) {
        /*console.log('empty')
        console.log(this.roles)
        console.log(this.roles[0].idCliente, id)*/
        this.clienteSelect.roles = this.roles//.filter((h: any) => h.idCliente == id)
      } else {
        this.clienteSelect.roles = this.clienteSelect.roles.concat(this.roles.filter((c: any) => c.idCliente == id))
      }


    })
    //console.log(this.clienteSelect)
  }

}

export interface Task {
  name: string;
  permitir: boolean;
  subtasks?: Task[];
}



function uniqueID() {
  function chr4() {
    return Math.random().toString(16).slice(-4);
  }
  return chr4() + chr4() +
    '-' + chr4() +
    '-' + chr4() +
    '-' + chr4() +
    '-' + chr4() + chr4() + chr4();
}