import { Directive, ElementRef } from '@angular/core';
import { MassiveMessageService } from '../../../services/global/massive-message.service';

@Directive({
  selector: '[appMessageIcon]'
})
export class MessageIconDirective {

  constructor( private messageService: MassiveMessageService, el: ElementRef ) {
    this.messageService.countMessageSent.subscribe((res: number) => {
      if( res === 0 ) {
        el.nativeElement.classList.remove('message-move');
      }
    });
    this.messageService.moveIcon.subscribe((res: boolean) => {
      if(res) {
        el.nativeElement.classList.toggle('message-move');
      }
    })
  }

}
