export class HorariosFakeDb
{
    // Si se edita alguno de los campos aqui presente sobre todo menú
    // asi sea una mayúscula puede dañar el sistema, NO TOCAR MEJOR
    public static Horarios = []

    public static CargarHorarios(Token: string) {
       return this.Horarios
    }
}
 