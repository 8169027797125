import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ProjectV2Component } from './project-v2.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule, MatListModule } from '@angular/material';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatCardModule} from '@angular/material/card';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseSidebarModule } from '@fuse/components';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { MatDialogModule } from '@angular/material';
import { MatToolbarModule } from '@angular/material/toolbar';

import { TranslateModule } from '@ngx-translate/core';
import { FuseConfirmDialogModule } from '@fuse/components/confirm-dialog/confirm-dialog.module';
import { GeneralModuleModule } from '../../../pages/general-component/general-module.module'
import { SharedModule } from 'app/shared/shared.module';
import { ChartsModule } from 'ng2-charts';
import {FormsModule} from '@angular/forms';
import { ProjectV2Service } from '../project-v2.service';

const routes: Routes = [
    {
        path     : 'apps/dashboards/projectv2',
        component: ProjectV2Component,
        resolve  : {
            data:ProjectV2Service,
        }
    }
];
@NgModule({
  declarations: [
    ProjectV2Component
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatPaginatorModule,
    MatCardModule,
    MatAutocompleteModule,
    ChartsModule,
    MatButtonModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    NgxChartsModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseWidgetModule,
    MatDialogModule,
    MatSelectModule,
    FormsModule,
    MatListModule,
    FuseConfirmDialogModule,
    TranslateModule.forChild(),
    GeneralModuleModule,
    DragDropModule,
    SharedModule
  ]
})
export class ProjectV2Module { }
