 
import { EventEmitter, Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";
import {MutationService} from 'app/fake-db/Mutation/mutation.service';
import {Apollo} from 'apollo-angular';


@Injectable({
  providedIn: 'root'
})
export class LoginSessionService implements Resolve<any> 
{

  public Userologin: any;
  private Token: string;
  public ViewOtherUser: boolean = false;  
  public DataLogin: any;  
  public eDataLogin:  EventEmitter<any> = new EventEmitter();
  private TokenTemp:string;
  public block=false
  Asigna = 0;
  interval:any
  contadorAck=0
 
constructor(private apollo:Apollo, private _mutationService:MutationService, private _Router:Router) {  
  
    this.DecodeToken()
  }
   
  resolve() {
    throw new Error('Method not implemented.');
  }

  public async SetToken(token: string, decodeToken: any) {
      // valido el token

     
      this.DataLogin = { users: {
                        active: await decodeToken,
                        other: { }
                       }
      }

      //console.log('este es el decodeToken: ', decodeToken)
    let Dataupdate ={
        "idUser": decodeToken.Data.idUser,
        "idEmpresa":decodeToken.Data.idEmpresa,
        "Online": true
    }
       this._mutationService.updateOnOff(this.apollo, token, Dataupdate)
      this.eDataLogin.emit(this.DataLogin)

      this.ackOnline(token, Dataupdate)
      //console.log(this.DataLogin)   
      // Aqui tengo el nombre si sigue fallando tengo que hacer un event para emitir este dato
      // TODO: Jhonttan
     // console.log(this.DataLogin.users.active.Data.Nombre + ' ' + this.DataLogin.users.active.Data.Apellido)   
      sessionStorage.setItem('DataLogin', token);    
      sessionStorage.setItem('block','false');    
      return true;
  }

  ackOnline(token:string, Dataupdate:any){
    if (this.contadorAck==0) {
      this.contadorAck++
      this.interval=setInterval(() => {
/*         console.log("Ack") */
        this._mutationService.updateOnOff(this.apollo, token, Dataupdate)
      }, 120000);
    }else{
/*       console.log("ya pase") */
    }
    
      
    
  }

  ackStop() {
    clearInterval(this.interval);
    this.contadorAck=0
    console.log("Muerto el ack") 
  }


  DecodeToken() {
    
    try {
      this.Token = sessionStorage.getItem('DataLogin');
      //console.log("Token In Login LoginSessionService")
      //console.log(this.Token)
      if(this.Asigna==0){this.TokenTemp= this.Token; this.Asigna++}

      const helper = new  JwtHelperService();

      const decodedToken =  helper.decodeToken(this.Token);
      const expirationDate = helper.getTokenExpirationDate(this.Token);
      const isExpired = helper.isTokenExpired(this.Token);

      this.DataLogin = !isExpired ? { users: {
                                        active: decodedToken,
                                        other: { }
                                      } } : ""

      return  !isExpired ? true : false
    } catch (error) {
      return false
    }
    
    
  }

  GetToken() {      
    // Tengo que tomar en cuenta si esta consultando otro usuario
    if (this.Token == undefined) {
      this.DecodeToken
    }
    return this.Token
  }

  GetTokenTemp() {      
    return this.TokenTemp
  }

  GetOtherSession() {
    return this.DataLogin.users.other    
  }

  GetSessionActive() {
    return this.DataLogin.users.active.Data    
  }

  IniToken(token: string) {
    
  }

  GetNameUser() {      
      const helper = new  JwtHelperService();
      
      const isExpired = helper.isTokenExpired(this.Token);
      if (!isExpired) {
        if(sessionStorage.getItem('block')=="1"){
            this._Router.navigate(['/pages/auth/lock']);
        }
            const decodedToken =  helper.decodeToken(this.Token);
        /* console.log("DataLogin");
         console.log(this.DataLogin);*/
          let Dataupdate ={
        "idUser": decodedToken.Data.idUser,
        "idEmpresa":decodedToken.Data.idEmpresa,
        "Online": true
    }

      this.ackOnline(this.Token, Dataupdate)

        return !this.ViewOtherUser ? this.DataLogin.users.active.Data.Nombre : this.DataLogin.users.other.Nombre
      } else {
        // Realmente lo debo enviar a Login
        //console.log("SIN NAME")
        try {
          return this.DataLogin.users.active.Data.Nombre || "SIN NAME"  
        } catch (error) {
          return "SIN NAME"  
        }
      }
      
  }

  GetDataUser(){
    const helper = new  JwtHelperService();
      
    const isExpired = helper.isTokenExpired(this.Token);
/*     console.log(isExpired); */
    if (!isExpired) { 
       /*console.log("DataLogin");
       console.log(this.DataLogin);*/

/*         console.log(this.block) */
      if(this.block){
/*         console.log('blocked') */
       this._Router.navigate(['/pages/auth/lock']);
      }
      return !this.ViewOtherUser ? this.DataLogin.users.active.Data : this.DataLogin.users.other

    } else {
      // Realmente lo debo enviar a Login
     // console.log("SIN NAME")
      try {
        return this.DataLogin.users.active.Data.Nombre || "SIN NAME"  
      } catch (error) {
        return "SIN NAME"  
      }
      
    }

  }

  GetDataOfflineOnline(){
    const helper = new  JwtHelperService();
      
    const isExpired = helper.isTokenExpired(this.Token);
    console.log(isExpired);
    if (!isExpired) { 
       /*console.log("DataLogin");
       console.log(this.DataLogin);*/
      return !this.ViewOtherUser ? this.DataLogin.users.active.Data : this.DataLogin.users.other
    } else {
      // Realmente lo debo enviar a Login
     // console.log("SIN NAME")
      try {
        return !this.ViewOtherUser ? this.DataLogin.users.active.Data : this.DataLogin.users.other 
      } catch (error) {
        return "SIN NAME"  
      }
      
    }

  }

  GetIdUser() { 

    this.DecodeToken()
    const helper = new  JwtHelperService();      
    const isExpired = helper.isTokenExpired(this.Token);
    if (!isExpired) { 
      // console.log(this.DataLogin.users.active.Data.idUser)
      return !this.ViewOtherUser ? this.DataLogin.users.active.Data.idUser : this.DataLogin.users.other.idUser
    } else { 
      return "0"
    }
    
  }


  GetEmailUser() { 
    // console.log(this.DataLogin)
    const helper = new  JwtHelperService();
      
    const isExpired = helper.isTokenExpired(this.Token);
    if (!isExpired) { 
        return !this.ViewOtherUser ? this.DataLogin.users.active.Data.Email : this.DataLogin.users.other.Email
    } else {
      //console.log("SIN EMAIL")
      try {
        return this.DataLogin.users.active.Data.Email || "SIN EMAIL"  
      } catch (error) {
        return "SIN EMAIL" 
      }
      
    }
    
  }

  GetRolUser() { 
    const helper = new  JwtHelperService();
      
    const isExpired = helper.isTokenExpired(this.Token);
    if (!isExpired) { 
        return {Permisos:this.DataLogin.users.active.Data.Permisos, isMaster:this.DataLogin.users.active.Data.isMaster}
    } else {
      try {
        return {Permisos:this.DataLogin.users.active.Data.Permisos, isMaster:this.DataLogin.users.active.Data.isMaster} || "SIN PERMISOS"  
      } catch (error) {
        return "SIN PERMISOS" 
      }
      
    }
    
  }


  GetAvatarUser() {
    return !this.ViewOtherUser ? this.DataLogin.users.active.Data.Avatar : this.DataLogin.users.other.Avatar
  }


  ClearTokenData() {
    this.Token = ""
    this.DataLogin = { users: {
      active: {Data: ""},
      other: { }
     }
    }

    sessionStorage.clear
  }

  getAllDataUser(token?:string) {
    const helper = new  JwtHelperService();

    const decodedToken =  helper.decodeToken(this.Token);

    return decodedToken
  }

}




/*{
         users: {
              active:    { 
                          "_id" : "604ae2164521ae0a7c61831a",
                          "idUser" : "654321", 
                          "idEmpresa" : "987654",  
                          "IdHorario" : [
                              "2021"
                          ], 
                          "Avatar" : "assetsimagesavatarsprofile.jpg", 
                          "idioma" : "es", 
                          "Permisos" : [
                              "1", "2", "3",  "4", "5", 
                              "6", "7", "8", "9"
                          ], 
                          "Canales" : [
                              "584142788259"
                          ], 
                          "UltimaConexion" : "2021-03-12T03:37:00.915+0000", 
                          "status" : true, 
                          "Online" : true, 
                          "isMaster" : true, 
                          "idRol" : "147852", 
                          "Nombre" : "daniel", 
                          "Apellido" : "alfonzo", 
                          "Email" : "29dalfonzo@gmail.com", 
                          "Clave" : "$2a$10$dF87siqIwnNunniA8CRxT.Q2scnR3k3sCKV1I2Zzj.g7m1PVByBPi", 
                          "Phone" : "584241256987", 
                          "Direccion" : "2da", 
                          "TipoUsuario" : "agente"
                        }, 
                other: {

                }
        }
      }*/
