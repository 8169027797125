import { Injectable, Output, EventEmitter } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Apollo } from 'apollo-angular';
import { MutationService } from 'app/fake-db/Mutation/mutation.service';
import { GetUrlImageService } from '../../../services/global/get-url-image.service';
import { FileUrl } from '../../../services/interfaces/file-url';

@Injectable({
  providedIn: 'root'
})
export class AvatarService {

  @Output() url: EventEmitter<FileUrl> = new EventEmitter<FileUrl>();

public imgdefault='assets/images/avatars/profile.jpg'
public Token: string = ''
public localimg: string = '';
public profileimg= 'assets/images/avatars/profile.jpg'

  constructor(
    private _mutationService:MutationService,
    private _apollo:Apollo,
    private getUrlImageBaseService: GetUrlImageService
    ) { 
/*     console.log('avatar: ', this.profileimg) */
/* console.log(this.localimg) */
  try {
    if (localStorage.getItem("Avatar")) {
      if( localStorage.getItem("Avatar").length > 5 ) {
        this.profileimg = localStorage.getItem("Avatar").toString();
      }
    }
  } catch (error) {
    this.profileimg = this.imgdefault
  }

  }


  // TODO JHONATTAN no tiene una promesa de salida
  changeValue(Pic: any, fileSize: number) {
    this.Token = this.takeToken();
    const helper = new  JwtHelperService();
    const decodedToken =  helper.decodeToken(this.Token);
    let idUser=decodedToken.Data.idUser
    if( !this.getUrlImageBaseService.verifyFileSize(fileSize) ) {
      return;
    }
    if( !this.getUrlImageBaseService.getFormatValidImage(Pic) ) {
      return;
    }    
    this.getUrlImageBaseService.getUrlImageBase64({dataFileBase64: Pic}).subscribe((res) => {
      this.url.next(res);
      this.profileimg = res.url;
      localStorage.setItem('Avatar', this.profileimg);
      let Data= { idUser, "Avatar": this.profileimg };
      this._mutationService.updateImgPerfil(this._apollo, this.Token,Data)
        .then()
        .catch(() => this.getUrlImageBaseService.errorSaveFile());
    }, (err) => {
      if( err ) {
        this.getUrlImageBaseService.errorUploadSnackBar();
        this.url.next(err);
      }
    });
  }

  takeToken(): string {
    return sessionStorage.getItem('DataLogin');
  }

}
