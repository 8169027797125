import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { HomeGuard } from './services/guards/home.guard';
import { LoginGuard } from './services/guards/login.guard';

const appRoutes: Routes = [
    { path: 'apps', canActivate: [HomeGuard], loadChildren: './main/apps/apps.module#AppsModule' },
    { path: 'pages',/*canActivate: [HomeGuard],*/ loadChildren: './main/pages/pages.module#PagesModule' },
    { path: 'reportes', canActivate: [HomeGuard], loadChildren: './main/ui/ui.module#UIModule' },
    { path: '**', canActivate: [LoginGuard], redirectTo: '/pages/home' }
    /* {path      : '**',redirectTo: '/pages/auth/login' } */
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, { anchorScrolling: 'enabled' }),
        TranslateModule
    ],
    exports: [RouterModule, TranslateModule]
})
export class AppRoutingModule { }
