import { Injectable,EventEmitter,Output, OnInit } from '@angular/core';
import { of, Observable } from 'rxjs'
import { execute } from "apollo-link";
import { WebSocketLink } from "apollo-link-ws";
import { SubscriptionClient } from "subscriptions-transport-ws";
import gql from "graphql-tag";
import { environment } from 'environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';

export const SUBSCRIBE_QUERY = gql`
  subscription ($Operador: String) {
    newChat(input: {operador:$Operador}) {
      idEmpresa
      phone
      idConversacion
      idmessage
      timestamp
      me_to
      contentType
      content
      TimeRead
      status
      statusc
      readmsg
      operador
      lic
      tag
      etiqueta
    }
  }
`;

export const SUBSCRIBE_UPDATE_CHATS = gql`
  subscription ($input: String) {
    updateChat(input: $input) {
      idConversacion
      operador
      lic
      phone
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class SubscriptionGraphqlService   {
wsurl=environment.uriGraphql
wsurlsub= environment.subsGraphql

  getWsClient:any;
   client:any;
   idOperador
/*    Token = sessionStorage.getItem('DataLogin') */
   Token = ""
   public newDash: EventEmitter<any> = new EventEmitter();
   public newChat: EventEmitter<any> = new EventEmitter();
   public updateChat: EventEmitter<any> = new EventEmitter();
   public pruebitaChat: Observable<any>;


  constructor() {
    //this.pruebitaChat = of('hola');}
      if( !sessionStorage.getItem('tokenSubscription') ) {
        return;
      }
      this.setToken(sessionStorage.getItem('DataLogin'));
      this.initSubscription();
   }

   

   setToken(token: string){
/*        console.log(token) */
        const helper = new JwtHelperService();
        const decodedToken = helper.decodeToken(token);
        console.log(decodedToken)
        this.idOperador =decodedToken.Data.idUser
        sessionStorage.setItem('tokenSubscription', (true).toString());
   }

   initSubscription(){
       this.SubChats()
       this.SubUpdateChats()
   }

 createSubscriptionObservable( query:any, variables?:any){
     try {
        //Me conecto al web socket
         this.getWsClient = function(Wsocket: any) {
            if(this.client !=undefined){
                return this.client
            }else{
                this.client = new SubscriptionClient(
                    Wsocket, {reconnect: true}, 
                );
                return this.client;
            }
        }
    if(variables==undefined)
        {
            const link = new WebSocketLink(this.getWsClient(this.wsurlsub));
            return execute(link, {query: query});
        }
        else
        {
            const link = new WebSocketLink(this.getWsClient(this.wsurlsub));
            return execute(link, {query: query, variables: variables});
        }
     } catch (error) {
         console.log(error)
     }
}

  SubChats() {
      //console.log("SubChats")
    try {
      this.createSubscriptionObservable(SUBSCRIBE_QUERY, {
            Operador:this.idOperador
        })
        .subscribe(
          (eventData) => {
                let chat = eventData.data.newChat;
                //console.log("Chat en SubChats", chat)
               //this.pruebitaChat = of(chat);
                this.newChat.emit(chat)
          })
        }catch (error) {
            console.error(error);
      }
    }

   SubUpdateChats() {
      //console.log("SubUpdateChats")
    try {
      this.createSubscriptionObservable(SUBSCRIBE_UPDATE_CHATS, {
          input: this.idOperador
        })
        .subscribe(
          (eventData) => {
            console.log("eventData ============================")
            console.log(eventData)
            // Esta explotando porque no llega la data
            if ('updateChat' in eventData.data) {
                let chat = eventData.data.updateChat;
              //console.log("me llego este updatechat: ",chat) 
                  this.updateChat.emit(chat)
            }
           
          },(err) => {
            console.log("Err");
            console.log(err);
            this.SubUpdateChats();
          }
        );
    } catch (error) {
      console.log(error);
      console.error(new Date());
    }
  }

}
