
import { GetTableService } from '../main/apps/e-commerce/getTable.service';
import { HttpClient } from "@angular/common/http";
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";

export class ProjectDashboardDb {



    constructor(private getTable: GetTableService) {
    }

    static apollo: any;

    public static projects = [];

    public static widgetsV2=[
        {
            'widget': {
            'title': 'Total General',
            'data': {
                'label': 'Conversaciones',
                'count': 157,
                'extra': {
                    'label': 'Ayer',
                    'count': 43
                }
            },
            //'detail': 'Tienes 4 Mensajes en Cola y Ayer 2.'
            }
        },
        {
            'widget': {
            'title': 'En Cola',
            'data': {
                'label': 'Mensajes',
                'count': 4,
                'extra': {
                    'label': 'Ayer',
                    'count': 2
                }
            },
            //'detail': 'Tienes 4 Mensajes en Cola y Ayer 2.'
            }
        },
    ]

    public static widgets = {
        'widget1': {
            'ranges': {
                'DY': 'Ayer',
                'DT': 'Hoy'
            },
            'currentRange': 'DT',
            'data': {
                'label': 'MENSAJES',
                'count': {
                    'DY': 21,
                    'DT': 250
                },
                'extra': {
                    'label': 'Atendidos',
                    'count': {
                        'DY': 6,
                        'DT': 7
                    }

                }
            },
            'detail': 'Mensajes recibidos por día.'
        },
        'widget2': {
            'title': 'En Cola',
            'data': {
                'label': 'Mensajes',
                'count': 4,
                'extra': {
                    'label': 'Ayer',
                    'count': 2
                }
            },
            'detail': 'Tienes 4 Mensajes en Cola y Ayer 2.'
        },
        'widget3': {
            'title': 'Cientes',
            'data': {
                'label': 'Nuevos',
                'count': 32,
                'extra': {
                    'label': 'Ayer',
                    'count': 15
                }
            },
            'detail': 'Tienes 32 Clientes Nuevos y Ayer 15.'
        },
        'widget4': {
            'title': 'Casos',
            'data': {
                'label': 'Cerrados',
                'count': 42,
                'extra': {
                    'label': 'Ayer',
                    'count': 8
                }
            },
            'detail': 'Casos Cerrados hoy 42, y Ayer 8.'
        },
        'widget5': {
            'title': 'Estadistica',
            'ranges': {
                'TW': 'This Week',
                'LW': 'Last Week',
                '2W': '2 Weeks Ago'
            },
            'mainChart': {
                '2W': [
                    {
                        'name': 'Mon',
                        'series': [
                            {
                                'name': 'issues',
                                'value': 37
                            },
                            {
                                'name': 'closed issues',
                                'value': 9
                            }
                        ]
                    },
                    {
                        'name': 'Tue',
                        'series': [
                            {
                                'name': 'issues',
                                'value': 32
                            },
                            {
                                'name': 'closed issues',
                                'value': 12
                            }
                        ]
                    },
                    {
                        'name': 'Wed',
                        'series': [
                            {
                                'name': 'issues',
                                'value': 39
                            },
                            {
                                'name': 'closed issues',
                                'value': 9
                            }
                        ]
                    },
                    {
                        'name': 'Thu',
                        'series': [
                            {
                                'name': 'issues',
                                'value': 27
                            },
                            {
                                'name': 'closed issues',
                                'value': 12
                            }
                        ]
                    },
                    {
                        'name': 'Fri',
                        'series': [
                            {
                                'name': 'issues',
                                'value': 18
                            },
                            {
                                'name': 'closed issues',
                                'value': 7
                            }
                        ]
                    },
                    {
                        'name': 'Sat',
                        'series': [
                            {
                                'name': 'issues',
                                'value': 24
                            },
                            {
                                'name': 'closed issues',
                                'value': 8
                            }
                        ]
                    },
                    {
                        'name': 'Sun',
                        'series': [
                            {
                                'name': 'issues',
                                'value': 20
                            },
                            {
                                'name': 'closed issues',
                                'value': 16
                            }
                        ]
                    }
                ],
                'LW': [
                    {
                        'name': 'Mon',
                        'series': [
                            {
                                'name': 'issues',
                                'value': 37
                            },
                            {
                                'name': 'closed issues',
                                'value': 12
                            }
                        ]
                    },
                    {
                        'name': 'Tue',
                        'series': [
                            {
                                'name': 'issues',
                                'value': 24
                            },
                            {
                                'name': 'closed issues',
                                'value': 8
                            }
                        ]
                    },
                    {
                        'name': 'Wed',
                        'series': [
                            {
                                'name': 'issues',
                                'value': 51
                            },
                            {
                                'name': 'closed issues',
                                'value': 7
                            }
                        ]
                    },
                    {
                        'name': 'Thu',
                        'series': [
                            {
                                'name': 'issues',
                                'value': 31
                            },
                            {
                                'name': 'closed issues',
                                'value': 13
                            }
                        ]
                    },
                    {
                        'name': 'Fri',
                        'series': [
                            {
                                'name': 'issues',
                                'value': 29
                            },
                            {
                                'name': 'closed issues',
                                'value': 7
                            }
                        ]
                    },
                    {
                        'name': 'Sat',
                        'series': [
                            {
                                'name': 'issues',
                                'value': 17
                            },
                            {
                                'name': 'closed issues',
                                'value': 6
                            }
                        ]
                    },
                    {
                        'name': 'Sun',
                        'series': [
                            {
                                'name': 'issues',
                                'value': 31
                            },
                            {
                                'name': 'closed issues',
                                'value': 10
                            }
                        ]
                    }
                ],
                'TW': [
                    {
                        'name': 'Mon',
                        'series': [
                            {
                                'name': 'issues',
                                'value': 42
                            },
                            {
                                'name': 'closed issues',
                                'value': 11
                            }
                        ]
                    },
                    {
                        'name': 'Tue',
                        'series': [
                            {
                                'name': 'issues',
                                'value': 28
                            },
                            {
                                'name': 'closed issues',
                                'value': 10
                            }
                        ]
                    },
                    {
                        'name': 'Wed',
                        'series': [
                            {
                                'name': 'issues',
                                'value': 43
                            },
                            {
                                'name': 'closed issues',
                                'value': 8
                            }
                        ]
                    },
                    {
                        'name': 'Thu',
                        'series': [
                            {
                                'name': 'issues',
                                'value': 34
                            },
                            {
                                'name': 'closed issues',
                                'value': 11
                            }
                        ]
                    },
                    {
                        'name': 'Fri',
                        'series': [
                            {
                                'name': 'issues',
                                'value': 20
                            },
                            {
                                'name': 'closed issues',
                                'value': 8
                            }
                        ]
                    },
                    {
                        'name': 'Sat',
                        'series': [
                            {
                                'name': 'issues',
                                'value': 25
                            },
                            {
                                'name': 'closed issues',
                                'value': 10
                            }
                        ]
                    },
                    {
                        'name': 'Sun',
                        'series': [
                            {
                                'name': 'issues',
                                'value': 22
                            },
                            {
                                'name': 'closed issues',
                                'value': 17
                            }
                        ]
                    }
                ]
            },
            'supporting': {
                'created': {
                    'label': 'Mensajes',
                    'count': {
                        '2W': 48,
                        'LW': 46,
                        'TW': 55
                    },
                    'chart': {
                        '2W': [
                            {
                                'name': 'CREATED',
                                'series': [
                                    {
                                        'name': 'Mon',
                                        'value': 5
                                    },
                                    {
                                        'name': 'Tue',
                                        'value': 8
                                    },
                                    {
                                        'name': 'Wed',
                                        'value': 5
                                    },
                                    {
                                        'name': 'Thu',
                                        'value': 6
                                    },
                                    {
                                        'name': 'Fri',
                                        'value': 7
                                    },
                                    {
                                        'name': 'Sat',
                                        'value': 8
                                    },
                                    {
                                        'name': 'Sun',
                                        'value': 7
                                    }
                                ]
                            }
                        ],
                        'LW': [
                            {
                                'name': 'Created',
                                'series': [
                                    {
                                        'name': 'Mon',
                                        'value': 6
                                    },
                                    {
                                        'name': 'Tue',
                                        'value': 3
                                    },
                                    {
                                        'name': 'Wed',
                                        'value': 7
                                    },
                                    {
                                        'name': 'Thu',
                                        'value': 5
                                    },
                                    {
                                        'name': 'Fri',
                                        'value': 5
                                    },
                                    {
                                        'name': 'Sat',
                                        'value': 4
                                    },
                                    {
                                        'name': 'Sun',
                                        'value': 7
                                    }
                                ]
                            }
                        ],
                        'TW': [
                            {
                                'name': 'Created',
                                'series': [
                                    {
                                        'name': 'Mon',
                                        'value': 3
                                    },
                                    {
                                        'name': 'Tue',
                                        'value': 2
                                    },
                                    {
                                        'name': 'Wed',
                                        'value': 1
                                    },
                                    {
                                        'name': 'Thu',
                                        'value': 4
                                    },
                                    {
                                        'name': 'Fri',
                                        'value': 8
                                    },
                                    {
                                        'name': 'Sat',
                                        'value': 8
                                    },
                                    {
                                        'name': 'Sun',
                                        'value': 4
                                    }
                                ]
                            }
                        ]
                    }
                },
                'closed': {
                    'label': 'Atendidos',
                    'count': {
                        '2W': 27,
                        'LW': 31,
                        'TW': 26
                    },
                    'chart': {
                        '2W': [
                            {
                                'name': 'CLOSED',
                                'series': [
                                    {
                                        'name': 'Mon',
                                        'value': 3
                                    },
                                    {
                                        'name': 'Tue',
                                        'value': 2
                                    },
                                    {
                                        'name': 'Wed',
                                        'value': 1
                                    },
                                    {
                                        'name': 'Thu',
                                        'value': 4
                                    },
                                    {
                                        'name': 'Fri',
                                        'value': 8
                                    },
                                    {
                                        'name': 'Sat',
                                        'value': 8
                                    },
                                    {
                                        'name': 'Sun',
                                        'value': 4
                                    }
                                ]
                            }
                        ],
                        'LW': [
                            {
                                'name': 'CLOSED',
                                'series': [
                                    {
                                        'name': 'Mon',
                                        'value': 6
                                    },
                                    {
                                        'name': 'Tue',
                                        'value': 5
                                    },
                                    {
                                        'name': 'Wed',
                                        'value': 4
                                    },
                                    {
                                        'name': 'Thu',
                                        'value': 5
                                    },
                                    {
                                        'name': 'Fri',
                                        'value': 7
                                    },
                                    {
                                        'name': 'Sat',
                                        'value': 4
                                    },
                                    {
                                        'name': 'Sun',
                                        'value': 7
                                    }
                                ]
                            }
                        ],
                        'TW': [
                            {
                                'name': 'CLOSED',
                                'series': [
                                    {
                                        'name': 'Mon',
                                        'value': 6
                                    },
                                    {
                                        'name': 'Tue',
                                        'value': 3
                                    },
                                    {
                                        'name': 'Wed',
                                        'value': 7
                                    },
                                    {
                                        'name': 'Thu',
                                        'value': 5
                                    },
                                    {
                                        'name': 'Fri',
                                        'value': 5
                                    },
                                    {
                                        'name': 'Sat',
                                        'value': 4
                                    },
                                    {
                                        'name': 'Sun',
                                        'value': 7
                                    }
                                ]
                            }
                        ]
                    }
                },
                'reOpened': {
                    'label': 'Clientes',
                    'count': {
                        '2W': 4,
                        'LW': 5,
                        'TW': 2
                    },
                    'chart': {
                        '2W': [
                            {
                                'name': 'RE-OPENED',
                                'series': [
                                    {
                                        'name': 'Mon',
                                        'value': 6
                                    },
                                    {
                                        'name': 'Tue',
                                        'value': 3
                                    },
                                    {
                                        'name': 'Wed',
                                        'value': 7
                                    },
                                    {
                                        'name': 'Thu',
                                        'value': 5
                                    },
                                    {
                                        'name': 'Fri',
                                        'value': 5
                                    },
                                    {
                                        'name': 'Sat',
                                        'value': 4
                                    },
                                    {
                                        'name': 'Sun',
                                        'value': 7
                                    }
                                ]
                            }
                        ],
                        'LW': [
                            {
                                'name': 'RE-OPENED',
                                'series': [
                                    {
                                        'name': 'Mon',
                                        'value': 5
                                    },
                                    {
                                        'name': 'Tue',
                                        'value': 7
                                    },
                                    {
                                        'name': 'Wed',
                                        'value': 8
                                    },
                                    {
                                        'name': 'Thu',
                                        'value': 8
                                    },
                                    {
                                        'name': 'Fri',
                                        'value': 6
                                    },
                                    {
                                        'name': 'Sat',
                                        'value': 4
                                    },
                                    {
                                        'name': 'Sun',
                                        'value': 1
                                    }
                                ]
                            }
                        ],
                        'TW': [
                            {
                                'name': 'RE-OPENED',
                                'series': [
                                    {
                                        'name': 'Mon',
                                        'value': 3
                                    },
                                    {
                                        'name': 'Tue',
                                        'value': 2
                                    },
                                    {
                                        'name': 'Wed',
                                        'value': 1
                                    },
                                    {
                                        'name': 'Thu',
                                        'value': 4
                                    },
                                    {
                                        'name': 'Fri',
                                        'value': 8
                                    },
                                    {
                                        'name': 'Sat',
                                        'value': 8
                                    },
                                    {
                                        'name': 'Sun',
                                        'value': 4
                                    }
                                ]
                            }
                        ]
                    }
                },
                'wontFix': {
                    'label': 'Atención BOT',
                    'count': {
                        '2W': 6,
                        'LW': 3,
                        'TW': 4
                    },
                    'chart': {
                        '2W': [
                            {
                                'name': 'WON\'T FIX',
                                'series': [
                                    {
                                        'name': 'Mon',
                                        'value': 5
                                    },
                                    {
                                        'name': 'Tue',
                                        'value': 7
                                    },
                                    {
                                        'name': 'Wed',
                                        'value': 4
                                    },
                                    {
                                        'name': 'Thu',
                                        'value': 6
                                    },
                                    {
                                        'name': 'Fri',
                                        'value': 5
                                    },
                                    {
                                        'name': 'Sat',
                                        'value': 3
                                    },
                                    {
                                        'name': 'Sun',
                                        'value': 2
                                    }
                                ]
                            }
                        ],
                        'LW': [
                            {
                                'name': 'WON\'T FIX',
                                'series': [
                                    {
                                        'name': 'Mon',
                                        'value': 6
                                    },
                                    {
                                        'name': 'Tue',
                                        'value': 3
                                    },
                                    {
                                        'name': 'Wed',
                                        'value': 7
                                    },
                                    {
                                        'name': 'Thu',
                                        'value': 5
                                    },
                                    {
                                        'name': 'Fri',
                                        'value': 5
                                    },
                                    {
                                        'name': 'Sat',
                                        'value': 4
                                    },
                                    {
                                        'name': 'Sun',
                                        'value': 7
                                    }
                                ]
                            }
                        ],
                        'TW': [
                            {
                                'name': 'WON\'T FIX',
                                'series': [
                                    {
                                        'name': 'Mon',
                                        'value': 6
                                    },
                                    {
                                        'name': 'Tue',
                                        'value': 5
                                    },
                                    {
                                        'name': 'Wed',
                                        'value': 4
                                    },
                                    {
                                        'name': 'Thu',
                                        'value': 5
                                    },
                                    {
                                        'name': 'Fri',
                                        'value': 7
                                    },
                                    {
                                        'name': 'Sat',
                                        'value': 4
                                    },
                                    {
                                        'name': 'Sun',
                                        'value': 7
                                    }
                                ]
                            }
                        ]
                    }
                },
                'needsTest': {
                    'label': 'R. Fallas',
                    'count': {
                        '2W': 10,
                        'LW': 7,
                        'TW': 8
                    },
                    'chart': {
                        '2W': [
                            {
                                'name': 'NEEDS TEST',
                                'series': [
                                    {
                                        'name': 'Mon',
                                        'value': 6
                                    },
                                    {
                                        'name': 'Tue',
                                        'value': 5
                                    },
                                    {
                                        'name': 'Wed',
                                        'value': 4
                                    },
                                    {
                                        'name': 'Thu',
                                        'value': 5
                                    },
                                    {
                                        'name': 'Fri',
                                        'value': 7
                                    },
                                    {
                                        'name': 'Sat',
                                        'value': 4
                                    },
                                    {
                                        'name': 'Sun',
                                        'value': 7
                                    }
                                ]
                            }
                        ],
                        'LW': [
                            {
                                'name': 'NEEDS TEST',
                                'series': [
                                    {
                                        'name': 'Mon',
                                        'value': 5
                                    },
                                    {
                                        'name': 'Tue',
                                        'value': 7
                                    },
                                    {
                                        'name': 'Wed',
                                        'value': 8
                                    },
                                    {
                                        'name': 'Thu',
                                        'value': 8
                                    },
                                    {
                                        'name': 'Fri',
                                        'value': 6
                                    },
                                    {
                                        'name': 'Sat',
                                        'value': 4
                                    },
                                    {
                                        'name': 'Sun',
                                        'value': 1
                                    }
                                ]
                            }
                        ],
                        'TW': [
                            {
                                'name': 'NEEDS TEST',
                                'series': [
                                    {
                                        'name': 'Mon',
                                        'value': 6
                                    },
                                    {
                                        'name': 'Tue',
                                        'value': 3
                                    },
                                    {
                                        'name': 'Wed',
                                        'value': 7
                                    },
                                    {
                                        'name': 'Thu',
                                        'value': 5
                                    },
                                    {
                                        'name': 'Fri',
                                        'value': 5
                                    },
                                    {
                                        'name': 'Sat',
                                        'value': 4
                                    },
                                    {
                                        'name': 'Sun',
                                        'value': 7
                                    }
                                ]
                            }
                        ]
                    }
                },
                'fixed': {
                    'label': 'R. Pagos.',
                    'count': {
                        '2W': 21,
                        'LW': 17,
                        'TW': 14
                    },
                    'chart': {
                        '2W': [
                            {
                                'name': 'FIXED',
                                'series': [
                                    {
                                        'name': 'Mon',
                                        'value': 5
                                    },
                                    {
                                        'name': 'Tue',
                                        'value': 7
                                    },
                                    {
                                        'name': 'Wed',
                                        'value': 8
                                    },
                                    {
                                        'name': 'Thu',
                                        'value': 8
                                    },
                                    {
                                        'name': 'Fri',
                                        'value': 6
                                    },
                                    {
                                        'name': 'Sat',
                                        'value': 4
                                    },
                                    {
                                        'name': 'Sun',
                                        'value': 1
                                    }
                                ]
                            }
                        ],
                        'LW': [
                            {
                                'name': 'FIXED',
                                'series': [
                                    {
                                        'name': 'Mon',
                                        'value': 6
                                    },
                                    {
                                        'name': 'Tue',
                                        'value': 5
                                    },
                                    {
                                        'name': 'Wed',
                                        'value': 4
                                    },
                                    {
                                        'name': 'Thu',
                                        'value': 5
                                    },
                                    {
                                        'name': 'Fri',
                                        'value': 7
                                    },
                                    {
                                        'name': 'Sat',
                                        'value': 4
                                    },
                                    {
                                        'name': 'Sun',
                                        'value': 7
                                    }
                                ]
                            }
                        ],
                        'TW': [
                            {
                                'name': 'FIXED',
                                'series': [
                                    {
                                        'name': 'Mon',
                                        'value': 5
                                    },
                                    {
                                        'name': 'Tue',
                                        'value': 7
                                    },
                                    {
                                        'name': 'Wed',
                                        'value': 4
                                    },
                                    {
                                        'name': 'Thu',
                                        'value': 6
                                    },
                                    {
                                        'name': 'Fri',
                                        'value': 5
                                    },
                                    {
                                        'name': 'Sat',
                                        'value': 3
                                    },
                                    {
                                        'name': 'Sun',
                                        'value': 2
                                    }
                                ]
                            }
                        ]
                    }
                }
            }
        },
        'widget6': {
            'title': 'Task Distribution',
            'ranges': {
                'TW': 'This Week',
                'LW': 'Last Week',
                '2W': '2 Weeks Ago'
            },
            'mainChart': {
                'TW': [
                    {
                        'name': 'Frontend',
                        'value': 15
                    },
                    {
                        'name': 'Backend',
                        'value': 20
                    },
                    {
                        'name': 'API',
                        'value': 38
                    },
                    {
                        'name': 'Issues',
                        'value': 27
                    }
                ],
                'LW': [
                    {
                        'name': 'Frontend',
                        'value': 19
                    },
                    {
                        'name': 'Backend',
                        'value': 16
                    },
                    {
                        'name': 'API',
                        'value': 42
                    },
                    {
                        'name': 'Issues',
                        'value': 23
                    }
                ],
                '2W': [
                    {
                        'name': 'Frontend',
                        'value': 18
                    },
                    {
                        'name': 'Backend',
                        'value': 17
                    },
                    {
                        'name': 'API',
                        'value': 40
                    },
                    {
                        'name': 'Issues',
                        'value': 25
                    }
                ]
            },
            'footerLeft': {
                'title': 'Tasks Added',
                'count': {
                    '2W': 487,
                    'LW': 526,
                    'TW': 594
                }
            },
            'footerRight': {
                'title': 'Tasks Completed',
                'count': {
                    '2W': 193,
                    'LW': 260,
                    'TW': 287
                }
            }
        },
        'widget7': {
            'title': 'Schedule',
            'ranges': {
                'T': 'Today',
                'TM': 'Tomorrow'
            },
            'schedule': {
                'T': [
                    {
                        'title': 'Group Meeting',
                        'time': 'In 32 minutes',
                        'location': 'Room 1B'
                    },
                    {
                        'title': 'Coffee Break',
                        'time': '10:30 AM'
                    },
                    {
                        'title': 'Public Beta Release',
                        'time': '11:00 AM'
                    },
                    {
                        'title': 'Lunch',
                        'time': '12:10 PM'
                    },
                    {
                        'title': 'Dinner with David',
                        'time': '17:30 PM'
                    },
                    {
                        'title': 'Jane\'s Birthday Party',
                        'time': '19:30 PM'
                    },
                    {
                        'title': 'Overseer\'s Retirement Party',
                        'time': '21:30 PM'
                    }
                ],
                'TM': [
                    {
                        'title': 'Marketing Meeting',
                        'time': '09:00 AM'
                    },
                    {
                        'title': 'Public Announcement',
                        'time': '11:00 AM'
                    },
                    {
                        'title': 'Lunch',
                        'time': '12:10 PM'
                    },
                    {
                        'title': 'Meeting with Beta Testers',
                        'time': '15:00 AM'
                    },
                    {
                        'title': 'Live Stream',
                        'time': '17:30 PM'
                    },
                    {
                        'title': 'Release Party',
                        'time': '19:30 PM'
                    },
                    {
                        'title': 'CEO\'s Party',
                        'time': '22:30 PM'
                    }
                ]
            }
        },
        'widget8': {
            'title': 'Mensaje por Agente',
            'mainChart': [
                {
                    'name': 'Marluis',
                    'value': 12
                },
                {
                    'name': 'Mariangel',
                    'value': 17
                },
                {
                    'name': 'Paula',
                    'value': 28
                },
                {
                    'name': 'Jhonattan',
                    'value': 25
                },
                {
                    'name': 'Michael',
                    'value': 15
                }
            ]
        },
        'widget9': {
            'title': 'Spent',
            'ranges': {
                'TW': 'This Week',
                'LW': 'Last Week',
                '2W': '2 Weeks Ago'
            },
            'weeklySpent': {
                'title': 'Total Semanal',
                'count': {
                    '2W': '2,682.85',
                    'LW': '1,445.34',
                    'TW': '3,630.15'
                },
                'chart': {
                    '2W': [
                        {
                            'name': 'CREATED',
                            'series': [
                                {
                                    'name': 'Mon',
                                    'value': 6
                                },
                                {
                                    'name': 'Tue',
                                    'value': 1
                                },
                                {
                                    'name': 'Wed',
                                    'value': 3
                                },
                                {
                                    'name': 'Thu',
                                    'value': 4
                                },
                                {
                                    'name': 'Fri',
                                    'value': 5
                                },
                                {
                                    'name': 'Sat',
                                    'value': 5
                                },
                                {
                                    'name': 'Sun',
                                    'value': 2
                                }
                            ]
                        }
                    ],
                    'LW': [
                        {
                            'name': 'Created',
                            'series': [
                                {
                                    'name': 'Mon',
                                    'value': 4
                                },
                                {
                                    'name': 'Tue',
                                    'value': 6
                                },
                                {
                                    'name': 'Wed',
                                    'value': 2
                                },
                                {
                                    'name': 'Thu',
                                    'value': 2
                                },
                                {
                                    'name': 'Fri',
                                    'value': 1
                                },
                                {
                                    'name': 'Sat',
                                    'value': 3
                                },
                                {
                                    'name': 'Sun',
                                    'value': 4
                                }
                            ]
                        }
                    ],
                    'TW': [
                        {
                            'name': 'Created',
                            'series': [
                                {
                                    'name': 'Mon',
                                    'value': 2
                                },
                                {
                                    'name': 'Tue',
                                    'value': 6
                                },
                                {
                                    'name': 'Wed',
                                    'value': 5
                                },
                                {
                                    'name': 'Thu',
                                    'value': 4
                                },
                                {
                                    'name': 'Fri',
                                    'value': 5
                                },
                                {
                                    'name': 'Sat',
                                    'value': 3
                                },
                                {
                                    'name': 'Sun',
                                    'value': 6
                                }
                            ]
                        }
                    ]
                }
            },
            'totalSpent': {
                'title': 'TOTAL SPENT',
                'count': {
                    '2W': '29,682.85',
                    'LW': '31,128.19',
                    'TW': '34,758.34'
                },
                'chart': {
                    '2W': [
                        {
                            'name': 'CREATED',
                            'series': [
                                {
                                    'name': 'Mon',
                                    'value': 3
                                },
                                {
                                    'name': 'Tue',
                                    'value': 2
                                },
                                {
                                    'name': 'Wed',
                                    'value': 2
                                },
                                {
                                    'name': 'Thu',
                                    'value': 4
                                },
                                {
                                    'name': 'Fri',
                                    'value': 7
                                },
                                {
                                    'name': 'Sat',
                                    'value': 7
                                },
                                {
                                    'name': 'Sun',
                                    'value': 4
                                }
                            ]
                        }
                    ],
                    'LW': [
                        {
                            'name': 'Created',
                            'series': [
                                {
                                    'name': 'Mon',
                                    'value': 5
                                },
                                {
                                    'name': 'Tue',
                                    'value': 7
                                },
                                {
                                    'name': 'Wed',
                                    'value': 8
                                },
                                {
                                    'name': 'Thu',
                                    'value': 8
                                },
                                {
                                    'name': 'Fri',
                                    'value': 6
                                },
                                {
                                    'name': 'Sat',
                                    'value': 4
                                },
                                {
                                    'name': 'Sun',
                                    'value': 1
                                }
                            ]
                        }
                    ],
                    'TW': [
                        {
                            'name': 'Created',
                            'series': [
                                {
                                    'name': 'Mon',
                                    'value': 6
                                },
                                {
                                    'name': 'Tue',
                                    'value': 4
                                },
                                {
                                    'name': 'Wed',
                                    'value': 7
                                },
                                {
                                    'name': 'Thu',
                                    'value': 5
                                },
                                {
                                    'name': 'Fri',
                                    'value': 5
                                },
                                {
                                    'name': 'Sat',
                                    'value': 4
                                },
                                {
                                    'name': 'Sun',
                                    'value': 7
                                }
                            ]
                        }
                    ]
                }
            },
            'remaining': {
                'title': 'REMAINING',
                'count': {
                    '2W': '94.317,15',
                    'LW': '92.871,81',
                    'TW': '89.241,66'
                },
                'chart': {
                    '2W': [
                        {
                            'name': 'CREATED',
                            'series': [
                                {
                                    'name': 'Mon',
                                    'value': 1
                                },
                                {
                                    'name': 'Tue',
                                    'value': 4
                                },
                                {
                                    'name': 'Wed',
                                    'value': 5
                                },
                                {
                                    'name': 'Thu',
                                    'value': 7
                                },
                                {
                                    'name': 'Fri',
                                    'value': 8
                                },
                                {
                                    'name': 'Sat',
                                    'value': 2
                                },
                                {
                                    'name': 'Sun',
                                    'value': 4
                                }
                            ]
                        }
                    ],
                    'LW': [
                        {
                            'name': 'Created',
                            'series': [
                                {
                                    'name': 'Mon',
                                    'value': 3
                                },
                                {
                                    'name': 'Tue',
                                    'value': 2
                                },
                                {
                                    'name': 'Wed',
                                    'value': 1
                                },
                                {
                                    'name': 'Thu',
                                    'value': 4
                                },
                                {
                                    'name': 'Fri',
                                    'value': 8
                                },
                                {
                                    'name': 'Sat',
                                    'value': 8
                                },
                                {
                                    'name': 'Sun',
                                    'value': 4
                                }
                            ]
                        }
                    ],
                    'TW': [
                        {
                            'name': 'Created',
                            'series': [
                                {
                                    'name': 'Mon',
                                    'value': 2
                                },
                                {
                                    'name': 'Tue',
                                    'value': 4
                                },
                                {
                                    'name': 'Wed',
                                    'value': 8
                                },
                                {
                                    'name': 'Thu',
                                    'value': 6
                                },
                                {
                                    'name': 'Fri',
                                    'value': 2
                                },
                                {
                                    'name': 'Sat',
                                    'value': 5
                                },
                                {
                                    'name': 'Sun',
                                    'value': 1
                                }
                            ]
                        }
                    ]
                }
            },
            'totalRemaining': {
                'title': 'TOTAL BUDGET',
                'count': '124.000,00'
            },
            'totalBudget': {
                'title': 'TOTAL BUDGET',
                'count': '124.000,00'
            }
        },
        'widget10': {
            'title': 'Budget Details',
            'table': {
                'columns': [
                    {
                        'title': 'Agente'
                    },
                    {
                        'title': 'Total Chats'
                    },
                    {
                        'title': 'Cerrados'
                    },
                    {
                        'title': 'Pendientes'
                    },
                    {
                        'title': 'Pagos'
                    },
                    {
                        'title': 'Fallas'
                    }
                ],
                'rows': [
                    [
                        {
                            'value': 'Marluis',
                            'classes': 'primary',
                            'icon': ''
                        },
                        {
                            'value': '$14,880.00',
                            'classes': 'text-bold',
                            'icon': ''
                        },
                        {
                            'value': '$14,000.00',
                            'classes': '',
                            'icon': ''
                        },
                        {
                            'value': '%94.08',
                            'classes': 'text-success',
                            'icon': 'trending_up'
                        },
                        {
                            'value': '$880.00',
                            'classes': '',
                            'icon': ''
                        },
                        {
                            'value': '%5.92',
                            'classes': '',
                            'icon': ''
                        }
                    ],
                    [
                        {
                            'value': 'Mariangel',
                            'classes': 'green',
                            'icon': ''
                        },
                        {
                            'value': '$21,080.00',
                            'classes': 'text-bold',
                            'icon': ''
                        },
                        {
                            'value': '$17,240.34',
                            'classes': '',
                            'icon': ''
                        },
                        {
                            'value': '%81.78',
                            'classes': 'text-success',
                            'icon': 'trending_up'
                        },
                        {
                            'value': '$3,839.66',
                            'classes': '',
                            'icon': ''
                        },
                        {
                            'value': '%18.22',
                            'classes': '',
                            'icon': ''
                        }
                    ],
                    [
                        {
                            'value': 'Michael',
                            'classes': 'red',
                            'icon': ''
                        },
                        {
                            'value': '$34,720.00',
                            'classes': 'text-bold',
                            'icon': ''
                        },
                        {
                            'value': '$3,518.00',
                            'classes': '',
                            'icon': ''
                        },
                        {
                            'value': '%10.13',
                            'classes': 'text-danger',
                            'icon': 'trending_down'
                        },
                        {
                            'value': '$31,202.00',
                            'classes': '',
                            'icon': ''
                        },
                        {
                            'value': '%89.87',
                            'classes': '',
                            'icon': ''
                        }
                    ],
                    [
                        {
                            'value': 'Jhonattan',
                            'classes': 'accent',
                            'icon': ''
                        },
                        {
                            'value': '$34,720.00',
                            'classes': 'text-bold',
                            'icon': ''
                        },
                        {
                            'value': '$0.00',
                            'classes': '',
                            'icon': ''
                        },
                        {
                            'value': '%0.00',
                            'classes': 'text-info',
                            'icon': 'trending_flat'
                        },
                        {
                            'value': '$34,720.00',
                            'classes': '',
                            'icon': ''
                        },
                        {
                            'value': '%100.00',
                            'classes': '',
                            'icon': ''
                        }
                    ],
                    [
                        {
                            'value': 'Daniel',
                            'classes': 'orange',
                            'icon': ''
                        },
                        {
                            'value': '$18,600.00',
                            'classes': 'text-bold',
                            'icon': ''
                        },
                        {
                            'value': '$0.00',
                            'classes': '',
                            'icon': ''
                        },
                        {
                            'value': '%0.00',
                            'classes': 'text-info',
                            'icon': 'trending_flat'
                        },
                        {
                            'value': '$34,720.00',
                            'classes': '',
                            'icon': ''
                        },
                        {
                            'value': '%100.00',
                            'classes': '',
                            'icon': ''
                        }
                    ]
                ]
            }
        },
        'widget11': {
            'title': 'Agentes On-Line',
            'table': {
                'columns': ['avatar', 'name', 'phone', 'estadistica', 'status'],
                'rows': [
                    {
                        avatar: 'assets/images/avatars/james.jpg',
                        name: 'Jack Gilbert',
                        position: 'Design Manager',
                        office: 'Johor Bahru',
                        email: 'jgilbert48@mail.com',
                        phone: '+16 298 032 7774',
                        status: true,
                        estadistica: 10
                    }/*,
                    {
                        avatar: 'assets/images/avatars/katherine.jpg',
                        name: 'Kathy Anderson',
                        position: 'Recruiting Manager',
                        office: 'Solţānābād',
                        email: 'kanderson49@mail.com.br',
                        phone: '+23 572 311 1136',
                        status: true,
                        estadistica: 10
                    },
                    {
                        avatar: 'assets/images/avatars/andrew.jpg',
                        name: 'Mark Turner',
                        position: 'Recruiting Manager',
                        office: 'Neftegorsk',
                        email: 'mturner4a@mail.com',
                        phone: '+01 139 803 9263',
                        status: true,
                        estadistica: 10
                    },
                    {
                        avatar: 'assets/images/avatars/jane.jpg',
                        name: 'Kathryn Martinez',
                        position: 'Director of Sales',
                        office: 'Palekastro',
                        email: 'kmartinez4b@mail.com',
                        phone: '+25 467 022 5393',
                        status: true,
                        estadistica: 10
                    },
                    {
                        avatar: 'assets/images/avatars/alice.jpg',
                        name: 'Annie Gonzales',
                        position: 'Actuary',
                        office: 'Candon',
                        email: 'agonzales4c@mail.edu',
                        phone: '+99 891 619 7138',
                        status: true,
                        estadistica: 10
                    },
                    {
                        avatar: 'assets/images/avatars/vincent.jpg',
                        name: 'Howard King',
                        position: 'Human Resources',
                        office: 'Bergen op Zoom',
                        email: 'hking4d@mail.gov',
                        phone: '+46 984 348 1409',
                        status: true,
                        estadistica: 10
                    },
                    {
                        avatar: 'assets/images/avatars/joyce.jpg',
                        name: 'Elizabeth Dixon',
                        position: 'Electrical Engineer',
                        office: 'Písečná',
                        email: 'edixon4e@mail.gov',
                        phone: '+33 332 067 9063',
                        status: true,
                        estadistica: 10
                    },
                    {
                        avatar: 'assets/images/avatars/danielle.jpg',
                        name: 'Dorothy Morris',
                        position: 'Office Assistant',
                        office: 'Magsaysay',
                        email: 'dmorris4f@mail.com',
                        phone: '+05 490 958 6120',
                        status: true,
                        estadistica: 10
                    },
                    {
                        avatar: 'assets/images/avatars/carl.jpg',
                        name: 'Mark Gonzales',
                        position: 'Quality Control',
                        office: 'Matsue-shi',
                        email: 'mgonzales4g@mail.com',
                        phone: '+03 168 394 9935',
                        status: true,
                        estadistica: 10
                    },
                    {
                        avatar: 'assets/images/avatars/profile.jpg',
                        name: 'Catherine Rogers',
                        position: 'Programmer Analyst',
                        office: 'Kangar',
                        email: 'crogers4h@mail.com',
                        phone: '+86 235 407 5373',
                        status: true,
                        estadistica: 10
                    }*/

                ]
            }
        },
        'widget12': {
            'title': 'Cola de Mensajes',
            'table': {
                'columns': ['avatar', 'phone', 'MSG', 'Date'],
                'rows': [
                    {
                        avatar: 'assets/images/avatars/james.jpg',
                        name: 'Jack Gilbert',
                        email: 'jgilbert48@mail.com',
                        phone: '+16 298 032 7774',
                        MSG: 'Hola como estas, soy...',
                        position: 'Design Manager',
                        Date: '09:45am',
                        estadistica: 10
                    },
                    {
                        avatar: 'assets/images/avatars/katherine.jpg',
                        name: 'Kathy Anderson',
                        email: 'kanderson49@mail.com.br',
                        phone: '+23 572 311 1136',
                        MSG: 'Hola como estas, soy...',
                        position: 'Design Manager',
                        Date: '09:45am',
                        estadistica: 10
                    },
                    {
                        avatar: 'assets/images/avatars/andrew.jpg',
                        name: 'Mark Turner',
                        email: 'mturner4a@mail.com',
                        phone: '+01 139 803 9263',
                        MSG: 'Hola como estas, soy...',
                        position: 'Design Manager',
                        Date: '09:45am',
                        estadistica: 10
                    },
                    {
                        avatar: 'assets/images/avatars/jane.jpg',
                        name: 'Kathryn Martinez',
                        email: 'kmartinez4b@mail.com',
                        phone: '+25 467 022 5393',
                        MSG: 'Hola como estas, soy...',
                        position: 'Design Manager',
                        Date: '09:45am',
                        estadistica: 10
                    },
                    {
                        avatar: 'assets/images/avatars/alice.jpg',
                        name: 'Annie Gonzales',
                        email: 'agonzales4c@mail.edu',
                        phone: '+99 891 619 7138',
                        MSG: 'Hola como estas, soy...',
                        position: 'Design Manager',
                        Date: '09:45am',
                        estadistica: 10
                    },
                    {
                        avatar: 'assets/images/avatars/vincent.jpg',
                        name: 'Howard King',
                        email: 'hking4d@mail.gov',
                        phone: '+46 984 348 1409',
                        MSG: 'Hola como estas, soy...',
                        position: 'Design Manager',
                        Date: '09:45am',
                        estadistica: 10
                    },
                    {
                        avatar: 'assets/images/avatars/joyce.jpg',
                        name: 'Elizabeth Dixon',
                        email: 'edixon4e@mail.gov',
                        phone: '+33 332 067 9063',
                        MSG: 'Hola como estas, soy...',
                        position: 'Design Manager',
                        Date: '09:45am',
                        estadistica: 10
                    },
                    {
                        avatar: 'assets/images/avatars/danielle.jpg',
                        name: 'Dorothy Morris',
                        email: 'dmorris4f@mail.com',
                        phone: '+05 490 958 6120',
                        MSG: 'Hola como estas, soy...',
                        position: 'Design Manager',
                        Date: '09:45am',
                        estadistica: 10
                    },
                    {
                        avatar: 'assets/images/avatars/carl.jpg',
                        name: 'Mark Gonzales',
                        email: 'mgonzales4g@mail.com',
                        phone: '+03 168 394 9935',
                        MSG: 'Hola como estas, soy...',
                        position: 'Design Manager',
                        Date: '09:45am',
                        estadistica: 10
                    },
                    {
                        avatar: 'assets/images/avatars/profile.jpg',
                        name: 'Catherine Rogers',
                        email: 'crogers4h@mail.com',
                        phone: '+86 235 407 5373',
                        MSG: 'Hola como estas, soy...',
                        position: 'Design Manager',
                        Date: '09:45am',
                        estadistica: 10
                    }

                ]
            }
        },
        'weatherWidget': {
            'locations': {
                'NewYork': {
                    'name': 'Caracas',
                    'icon': 'icon-rainy2',
                    'temp': {
                        'C': '22',
                        'F': '72'
                    },
                    'windSpeed': {
                        'KMH': 12,
                        'MPH': 7.5
                    },
                    'windDirection': 'CS',
                    'rainProbability': '98%',
                    'next3Days': [
                        {
                            'name': 'Lunes',
                            'icon': 'icon-rainy',
                            'temp': {
                                'C': '21',
                                'F': '70'
                            }
                        },
                        {
                            'name': 'Martes',
                            'icon': 'icon-cloudy',
                            'temp': {
                                'C': '19',
                                'F': '66'
                            }
                        },
                        {
                            'name': 'Miercoles',
                            'icon': 'icon-windy3',
                            'temp': {
                                'C': '24',
                                'F': '75'
                            }
                        }
                    ]
                }
            },
            'currentLocation': 'NewYork',
            'tempUnit': 'C',
            'speedUnit': 'KMH'
        }
    };
    
    public static dashboard_totales = []

    public static async ClientesEmpresa(apollo: Apollo, Token: any, _httpClient: HttpClient) {
        return new Promise(async (resolve, reject) => {

            //Devuelve los clientes por empresa
            let Datafaq = await apollo.watchQuery<any>({
                query: ClientesEmpresa,
                variables: {
                    token: Token,
                }
            })
                .valueChanges
                .subscribe((result) => {

                    let ClientesByEmpresa = result.data.ClientesEmpresa
                    if (ClientesByEmpresa != null) {
                        try {
                            if (ClientesByEmpresa.length > 0) {
                                ClientesByEmpresa.forEach(element => {
                                    delete element.__typename;
                                });
                                this.projects = ClientesByEmpresa;
                                _httpClient.post('api/project-dashboard-projects/1', ClientesByEmpresa).subscribe((response: any) => { }, (Error) => { console.error('project-dashboard-projects'); console.error(Error) });
                                resolve(ClientesByEmpresa);
                            }else{
                                resolve([]);
                            }
                        } catch (error) {
                            console.error(error);
                            console.error(new Date());
                        }
                        
                    }

                });
        })
    }

    public static async ConsultaTotales(apollo: Apollo, Token: any, _httpClient: HttpClient) {
        return new Promise(async (resolve, reject) => {
            console.log("ConsultaTotales");
            let FechaAyer = new Date();
            FechaAyer.setDate(FechaAyer.getDate() - 1);
            FechaAyer.setMinutes(0);
            FechaAyer.setHours(0);
            FechaAyer.setSeconds(0);
            FechaAyer.setMilliseconds(0);

            let FechaHoy = new Date();
            //Debo agregar los idCliente para buscar segun los permisos
            let Datafaq = await apollo.watchQuery<any>({
                query: ConsultaTotalesDash,
                variables: {
                    token: Token,
                    fechamin: FechaAyer,
                    fechamax: FechaHoy
                }
            })
                .valueChanges
                .subscribe((result) => {

                    let DashboardControlData = result.data.TotalDashboard
                    
                    if (DashboardControlData != null) {
                        //console.log("pase voy a emitir dashboardControlData");
                        //console.log(DashboardControlData)
                        try {
                            if (DashboardControlData.length > 0) {
                                DashboardControlData.forEach((element: any, index: number) => {
                                    let tempfecha = parseInt(element.fecha, 10);
                                    element.fecha = tempfecha;
                                    element.fecha2 = new Date(tempfecha);

                                    if (index == DashboardControlData.length - 1) {
                                        //console.log("Entre al final del FOR")
                                        this.dashboard_totales = DashboardControlData;
                                        try {
                                            
                                            resolve(DashboardControlData)
                                            _httpClient.post('api/project-dashboard-totalesWidget/1', DashboardControlData).subscribe((response: any) => { }, (Error) => { console.error('Dashboard Resumen'); console.error(Error) });
                                        } catch (error) {
                                            
                                            console.error(error);
                                            console.error(new Date());
                                            resolve([])
                                        }
                                        
                                    }
                                });
                            }else{
                                resolve([]);
                            }
                        } catch (error) {
                            
                        }
                        
                    }

                });
        })
    }

}

const ConsultaTotalesDash = gql`
query totales($token:String,$fechamin:String,$fechamax:String){
    TotalDashboard(token:$token,fechamin:$fechamin,fechamax:$fechamax){
      lic
      fecha
      idCliente
      idEmpresa
      tipo
      total
      dia
      mes
      ano
      hora
    }
  }
`

const ClientesEmpresa = gql`
  query ClientesEmpresa($token:String){
    ClientesEmpresa(token:$token){
      idCliente
      lic:licencia
      name:NombreCliente
    }
  }
`
