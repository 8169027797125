import { Injectable, AfterContentInit, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { FakeDbService } from 'app/fake-db/fake-db.service';
import { ProjectKamikase } from 'app/fake-db/dashboard-project2';

import { environment } from 'environments/environment';
import gql from "graphql-tag";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { execute } from "apollo-link";
import { WebSocketLink } from "apollo-link-ws";

export const SUBSCRIBE_Kamikaze = gql`
  subscription{
  DashboardTotals{
    NameEmpresa
    idEmpresa
    NameCliente
    idCliente
    lic
    DateInsert
    Fecha
    totales{
      Tipo
      Order
      NombreTipo
      Total
    }
  }
}
`;

@Injectable({
    providedIn: 'root'
})
export class ProjectV2Service {

    wsurl = environment.uriGraphql
    wsurlsub = environment.subsGraphql
    getWsClient: any;

    public CargaProjects: EventEmitter<boolean> = new EventEmitter();
    public projects: any;
    public YaTengoTodo = false

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        public _FakeDbService: FakeDbService,
        private _httpClient: HttpClient
    ) {
        if (ProjectKamikase.YacargueDash) {
            //console.log('YacargueDash if: ', ProjectKamikase.YacargueDash);
            this.cargaProjects()
            this.YaTengoTodo = true
        } else {
            //console.log('YacargueDash else: ', ProjectKamikase.YacargueDash);
            setTimeout(() => {
                // console.log("Tengo todo en Timeout: ", this.YaTengoTodo, ProjectKamikase.YacargueDash)
                if (!this.YaTengoTodo) {
                    this.cargaProjects()
                    this.YaTengoTodo = true
                    this.SubKamikaze()
                }
            }, 30000)
        }

    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        /*       console.log("Cargue V2") */
        this.reloadDash()

    }

    reloadDash() {
        ProjectKamikase.DashReady.subscribe(() => {
            this.cargaProjects()
                .then(() => {
                    this.YaTengoTodo = true
                    /*                     console.log("Tengo todo en subscribe: ", this.YaTengoTodo,) */
                })
        })
    }

    cargaProjects() {
        return new Promise<void>((resolve, reject) => {

            Promise.all([
                this.getProjects(),
            ]).then(
                (res) => {
                    if (!res) reject("No projects found")
                    this.projects = res[0]
                    this.CargaProjects.emit(true)
                    resolve();
                },
            ).catch((error: any) => console.error(error));
        });
    }
    /**
     * Get projects
     *
     * @returns {Promise<any>}
     */
    getProjects(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get('api/project-dashboardK/')
                .subscribe((response: any) => {
                    console.log(response)
                    if (response.length <= 0) {
                        resolve(response);
                    } else {
                        resolve(response);
                    }

                }, reject);
        });
    }


    createSubscriptionObservable(query: any, variables?: any) {
        try {
            //Me conecto al web socket
            this.getWsClient = function (Wsocket: any) {
                if (this.client != undefined) {
                    return this.client
                } else {
                    this.client = new SubscriptionClient(
                        Wsocket, { reconnect: true },
                    );
                    return this.client;
                }
            }
            if (variables == undefined) {
                const link = new WebSocketLink(this.getWsClient(this.wsurlsub));
                return execute(link, { query: query });
            }
            else {
                const link = new WebSocketLink(this.getWsClient(this.wsurlsub));
                return execute(link, { query: query, variables: variables });
            }
        } catch (error) {
            console.log(error)
        }
    }

    SubKamikaze() {
        try {

            this.createSubscriptionObservable(SUBSCRIBE_Kamikaze).subscribe((eventData) => {
                console.log("eventData ============================")
                console.log(eventData)
            })

        } catch (error) {
            console.error(error)
        }
    }

}


/* subscription{
  DashboardTotals{
    NameEmpresa
    idEmpresa
    NameCliente
    idCliente
    lic
    DateInsert
    Fecha
    totales{
      Tipo
      Order
      NombreTipo
      Total
    }
  }
} */