import { EventEmitter, Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";
import {MutationService} from 'app/fake-db/Mutation/mutation.service';
import {Apollo} from 'apollo-angular';
import {LoginSessionService} from './LoginSession.service'

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(private apollo:Apollo, private _mutationService:MutationService, private Token:LoginSessionService) { }
  
    

    public RegistrarLog(Accion:any){
        let TokenSesion =this.Token.GetToken()
        this._mutationService.CreateLog(this.apollo,TokenSesion,Accion)
        .then((r:any)=>console.log(r))
        .catch((err:any)=>console.error(err));
    }
   
}