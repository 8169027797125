import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { HttpClient } from "@angular/common/http";
import { EventEmitter } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

export class AgentesAdmFakeDb {

  public static Counting: EventEmitter<Number> = new EventEmitter();
  constructor(
    private apollo: Apollo
  ) {

  }
  // Si se edita alguno de los campos aqui presente sobre todo menú
  // asi sea una mayúscula puede dañar el sistema, NO TOCAR MEJOR
  public static agentesAdm = []

  public static CargarAgentes(Token: string) {
    // Aqui se debe hacer la consulta de graphql
    return this.agentesAdm
  }

  public static UserByIdEmpresa(apollo: Apollo, Token: any, _httpClient: HttpClient) {
    let data = apollo.watchQuery<any>({
      query: UserByidEmpresa,
      variables: {
        token: Token
      }
    })
      .valueChanges
      .subscribe((result) => {
        try {

          let AllUsers = result.data.UsersByidEmpresa
          console.log('Entre en UserByIdEmpresa')


          const Token = sessionStorage.getItem('DataLogin')
          const helper = new JwtHelperService();
          const decodedToken = helper.decodeToken(Token);

          let Operador = {
            id: decodedToken.Data.idUser,
            name: decodedToken.Data.Nombre,
            idCliente: decodedToken.Data.idCliente,
          }

          let agentes: any = []
          Operador.idCliente.forEach(id => {
            AllUsers.filter((agent) => {
              let validacion = agent.idCliente.includes(id)
              if (validacion) {
                agentes.push(agent)
              }
            })
          });

        } catch (error) {
          console.error(error)
        }

      });

    return this.agentesAdm
  }

  public static UserByCliente(apollo: Apollo, token: any, _httpClient: HttpClient) {
    let data = apollo.watchQuery<any>({
      query: UserByCliente,
      variables: {
        token: token
      }
    })
      .valueChanges
      .subscribe((result) => {
        try {

          let AllUsers = result.data.UserByClient

          const DataUsers = {
            item: { id: 1 },
            colum: [
              { colum: 'Avatar', type: 'avatar', head: 'Avatar', visible: true, isModelProperty: true },
              { colum: 'Nombre', type: 'string', head: 'Nombre', visible: true, isModelProperty: true },
              { colum: 'Transferir', type: 'boolean', head: 'Transferir', visible: true, isModelProperty: true },
              { colum: 'Canales', type: 'string', head: 'Canales', visible: false, isModelProperty: true },
              { colum: 'Horario', type: 'string', head: 'Horario', visible: false, isModelProperty: true },
              { colum: 'UltimaConexion', type: 'date', head: 'Ultima conexion', visible: true, isModelProperty: true },
              { colum: 'Online', type: 'boolean', head: 'Online', visible: true, isModelProperty: true },
              //{ colum: 'Online', type: 'icon', head: 'Online', visible: true, isModelProperty: true },
              { colum: 'status', type: 'boolean', head: 'Status', visible: false, isModelProperty: true },
              { colum: 'Menu', type: 'menu', head: 'Menú', visible: true }
            ],
            menu: ["Editar/edit", "CambiarClave/vpn_key", "Eliminar/delete"],
            clientes: AllUsers
          }
          _httpClient.post('api/agentes-adm/1', DataUsers).subscribe((response: any) => { }, (Error) => { console.error('Users'); console.error(Error) });

          try {
            this.Counting.emit(AllUsers.length)
          } catch (error) {
            this.Counting.emit(0)
          }

        } catch (error) {
          console.error(error)
        }

      });

    return this.agentesAdm
  }

}





export const UserByidEmpresa = gql`
query UsersByidEmpresa($token:String) {
  UsersByidEmpresa(
    token:$token
    ){
      idUser
      idEmpresa
      idRol
      idHorario
      idCliente
      Avatar
      Nombre
      Apellido
      Email
      Clave
      Phone
      Direccion
      TipoUsuario
      Transferir
      idioma
      Permisos
      Canales
      UltimaConexion
      status
      isMaster
      Online
      transferir
    }
  }
`;


export const updateUser = gql`
              mutation UpdateUser($idUser:String,$idEmpresa:String,$idRol:String,$idHorario:[String],$Avatar:String,$Nombre:String,$Apellido:String,$Email:String,$Clave:String,$Phone:String,$Direccion:String,$TipoUsuario:String,$idioma:String,$Canales:[String],$status:Boolean,$isMaster:Boolean,$Online:Boolean){
                  UpdateUser(
                  idUser:$idUser
                  Data:{
                    idUser:$idUser
                    idEmpresa:$idEmpresa
                    idRol:$idRol
                    idHorario:$idHorario
                    Avatar:$Avatar
                    Nombre:$Nombre
                    Apellido:$Apellido
                    Email:$Email
                    Clave:$Clave
                    Phone:$Phone
                    Direccion:$Direccion
                    TipoUsuario:$TipoUsuario
                    idioma:$idioma
                    Canales:$Canales
                    status:$status
                    isMaster:$isMaster
                    Online:$Online
                      }){
                    idUser
                    idEmpresa
                    Nombre
                    Apellido
                      }
                      }
                      `

export const createUser = gql`
              mutation createUser($idUser:String,$idEmpresa:String,$idRol:String,$idHorario:[String],$Avatar:String,$Nombre:String,$Apellido:String,$Email:String,$Clave:String,$Phone:String,$Direccion:String,$TipoUsuario:String,$idioma:String,$Canales:[String],$status:Boolean,$isMaster:Boolean,$Online:Boolean){
                  createUser(input:{
                    idUser:$idUser
                    idEmpresa:$idEmpresa
                    idRol:$idRol
                    idHorario:$idHorario
                    Avatar:$Avatar
                    Nombre:$Nombre
                    Apellido:$Apellido
                    Email:$Email
                    Clave:$Clave
                    Phone:$Phone
                    Direccion:$Direccion
                    TipoUsuario:$TipoUsuario
                    idioma:$idioma
                    Canales:$Canales
                    status:$status
                    isMaster:$isMaster
                    Online:$Online
                      }){
                    idUser
                    idEmpresa
                    Nombre
                    Apellido
                      }
                      }
                      `

export const UserByCliente = gql`
query UserByClient($token:String) {
  UserByClient(token:$token){
    idUser
    idEmpresa
    idRol
    idHorario
    idCliente
    Avatar
    Nombre
    Apellido
    Email
    Clave
    Phone
    Direccion
    TipoUsuario
    idioma
    Permisos
    Canales
    UltimaConexion
    status
    isMaster
    Online
    transferir
    }
  }
`;