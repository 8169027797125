import { HttpClient } from "@angular/common/http";
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";

export class ReportesFakeDb {
    static apollo: any;

    columnsTableREportesMatser: any = {
        columns: [
            { colum: 'phone', type: 'phone', head: 'Móvil', visible: false, isModelProperty: true, position: 0 },
            { colum: 'lic', type: 'phone', head: 'Canal', visible: true, isModelProperty: true, position: 1 },
            { colum: 'contentType', type: 'contentType', head: 'TipoContenido', visible: true, isModelProperty: true, position: 2 },
            { colum: 'content', type: 'content', head: 'Contenido', visible: true, isModelProperty: true, position: 3 },
            { colum: 'TimeRead', type: 'string', head: 'Fecha', visible: true, isModelProperty: true, position: 4 },
            { colum: 'operador', type: 'string', head: 'Agentes', visible: true, isModelProperty: true, position: 5 },
            { colum: 'countMsg', type: 'string', head: 'Totalmensajes', visible: false, isModelProperty: true, position: 6 },
            { colum: 'menu', type: 'menu', head: 'Menú', visible: true, isModelProperty: true, position: 7 },
        ],
        type: ['phone', 'phone', 'contentType', 'content', 'date', 'string','menu'],
        TitleColumShow: ['phone', 'phone', 'contentType', 'content', 'date', 'menu'],
        menu: ["Ver conversacion/edit", "Chat/chrome_reader_mode"],
    }

    // ["Ver conversacion/edit", "Ver contenido/edit","Chat/chrome_reader_mode"]

    async ConsultaReportes(apollo: Apollo, Token: any, Data: any) {
        return new Promise(async (resolve, reject) => {
            try {

                Object.keys(Data).forEach(function (key) {
                    if (Data[key] === null || Data[key] === Number.NaN) {
                        delete Data[key];
                    }
                });

                let Datafaq = await apollo.watchQuery<any>({
                    query: SearchReport,
                    variables: {
                        "token": Token,
                        "Data": { ...Data }
                        
                    },
                    fetchPolicy: "no-cache"
                })
                    .valueChanges
                    .subscribe((result) => {
                        resolve(result.data.SearchReport)
                    });

            } catch (error) {
                console.error(error)
                reject(error)
            }
        })

    }

    async ConsultaEncuesta(apollo: Apollo, Token: any, Data: any) {
        return new Promise(async (resolve, reject) => {
            try {

                let DataE = await apollo.watchQuery<any>({
                    query: SearchEncuesta,
                    variables: {
                        token: Token,
                        lic: Data.lic,
                        tipo: Data.tipo,
                        fechamin: Data.fechamin,
                        fechamax: Data.fechamax
                    },
                    fetchPolicy: "no-cache"
                })
                    .valueChanges
                    .subscribe((result) => {
                        // console.log('Data que llego de la consulta de la encuesta')
                        // console.log(result.data.FindEncuesta)
                        resolve(result.data.FindEncuesta)
                    });

            } catch (error) {
                console.error(error)
                reject(error)
            }
        })

    }

    async ConsultaPreguntasEncuesta(apollo: Apollo, Token: any, Data: any) {
        return new Promise(async (resolve, reject) => {
            try {

                let DataE = await apollo.watchQuery<any>({
                    query: SearchPreguntasEncuesta,
                    variables: {
                        token: Token,
                        lic: Data.lic
                    },
                    fetchPolicy: "no-cache"
                })
                    .valueChanges
                    .subscribe((result) => {
                        // console.log('Data que llego de la consulta de la encuesta')
                        // console.log(result.data.FindEncuesta)
                        resolve(result.data.FindPreguntasEncuesta)
                    });

            } catch (error) {
                console.error(error)
                reject(error)
            }
        })

    }

    async TipoEncuesta(apollo: Apollo, Token: any) {
        Token = Token.replace(/["']/g, "")
        return new Promise(async (resolve, reject) => {
            try {

                let DataE = await apollo.watchQuery<any>({
                    query: TipoEncuesta,
                    variables: {
                        token: Token,
                    },
                    fetchPolicy: "no-cache"
                })
                    .valueChanges
                    .subscribe((result) => {
                        console.log(result.data)
                        resolve(result.data.TipoEncuesta);
                    });

            } catch (error) {
                console.error(error)
                reject(error)
            }
        })

    }

    async ConsultaIndicadores(apollo: Apollo, Token: any, Data: any) {
        return new Promise(async (resolve, reject) => {
            try {

                console.log("Data que me llego")
                console.log(Data)

                Object.keys(Data).forEach(function (key) {
                    if (Data[key] === null || Data[key] === Number.NaN || Data[key].length === 0 || Data[key] === undefined) {
                        delete Data[key];
                    }
                });

                console.log("Data final")
                console.log(Data)

                let DataB = await apollo.watchQuery<any>({
                    query: SearchIndicadores,
                    variables: {
                        "token": Token,
                        "Data": { ...Data }
                    },
                    fetchPolicy: "no-cache"
                })
                    .valueChanges
                    .subscribe((result) => {
                        resolve(result.data.SearchIndicadores)
                        // console.log("Data Obtenida de lso indicadores ")
                        // console.log(result.data.SearchIndicadores)
                    });

            } catch (error) {
                console.error(error)
                reject(error)
            }
        })

    }

}

const SearchReport = gql`
    query SearchReport ($token:String, $Data:ReportesInput){
  SearchReport(token:$token, Data:$Data){
    idEmpresa
    phone
    idConversacion
    idmessage
    timestamp
    me_to
    contentType
    content
    TimeRead
    status
    statusc
    readmsg
    operador
    lic
    tag
    etiqueta
    priority
  }
}
`

const SearchEncuesta = gql`
query FindEncuesta($token:String, $lic:String, $tipo:String,$fechamin:Int,$fechamax:Int) {
    FindEncuesta2(
        token:$token
        Data:{
            lic:$lic
            tipo:$tipo
            fechamin:$fechamin
            fechamax:$fechamax
        }
    ){
        lic
        phone
        DataEncuestas{
        phone
        lic
        answers
        date
        tipo
        status
        nota
        procesado
        }
        msj{
        idEmpresa
        phone
        idConversacion
        idmessage
        timestamp
        me_to
        contentType
        content
        TimeRead
        status
        statusc
        readmsg
        operador
        lic
        tag
        etiqueta
        priority
        DatePc
        }
    }
  }
`;

const SearchPreguntasEncuesta = gql`
query FindPreguntasEncuesta($token:String, $lic:String) {
    FindPreguntasEncuesta(token:$token
  Data:{
    lic:$lic
  }){
    campos
    tipoDatos
    tipo
    lic
  }
  }
`;

const TipoEncuesta = gql`
query TipoEncuesta($token:String){
    TipoEncuesta(token:$token){
        lic
        idEmpresa
        TipoEncuestas{
            idEmpresa
            Lic
            Tipo_encuesta
        }
    }
}
`;


const SearchIndicadores = gql`
    query SearchIndicadores($token:String, $Data:SearchIndicadoresInput){
        SearchIndicadores(token:$token, Data:$Data){
            IdEmpresa
            IdOperador
            IdEtiqueta
            lic
            Total
            Day
            Month
            year
            FechaCreate
        }
    }
`