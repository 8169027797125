 
export class EtiquetasFakeDb  
{
    loading: boolean;
    res2: any;
    data

 
    
    
 

    // Si se edita alguno de los campos aqui presente sobre todo menú
    // asi sea una mayúscula puede dañar el sistema, NO TOCAR MEJOR
    public static etiquetas = []  /*{

        colum:  [
            { colum: 'etiquetas', type: 'string', head: 'Etiquetas', visible: true, isModelProperty: true },
            { colum: 'Canal', type: 'string', head: 'Canal', visible: true, isModelProperty: true },            
            { colum: 'Menu', type: 'menu', head: 'Menú', visible: true }
        ],
        type: ['string', 'string', 'menu'],
        TitleColumShow: ['Etiquetas', 'Canal', 'Menú'],        
        menu: ["Editar/edit", "Eliminar/delete"],
        clientes:[
            {
                id:1,
                etiquetas:[
                    {
                        id: "xxx01",
                        word:"Reportando Falla",
                        Lic: ["*"]
                    },
                    {
                        id: "xxx02",
                        word:"En seguimiento",
                        Lic: ["04142788259","555542597"]
                    },
                    {
                        id: "xxx03",
                        word:"Reportando Pago",
                        Lic: ["04142788259"]
                    },
                    {
                        id: "xxx04",
                        word:"Nuevo Prospecto",
                        Lic: ["04142788259"]
                    }
                ]
            },
            {
                id:2,
                etiquetas:[
                    {
                        id: "xxx11",
                        word:"Reportando Falla",
                        Lic: ["04142788259","555542597"]
                    },
                    {
                        id: "xxx12",
                        word:"En seguimiento",
                        Lic: ["04142788259","555542597"]
                    },
                    {
                        id: "xxx13",
                        word:"Reportando Pago",
                        Lic: ["04142788259"]
                    },
                    {
                        id: "xxx14",
                        word:"Nuevo Prospecto",
                        Lic: ["04142788259"]
                    }
                ]
            }
        ]
    } */


    public static etiquetasbyCanal = {  
        colum: ['etiquetas', 'Canal', 'menú'],
        type: ['string', 'string', 'menu'],
        TitleColumShow: ['Etiquetas', 'Canal', 'Menú'],        
        menu: ["Editar/edit", "Eliminar/delete"],
        clientes:[
            {
                id:1,
                Lic: '04142788259',
                etiquetas:[
                    {
                        id: "xxx01",
                        word:"Reportando Falla"
                    },
                    {
                        id: "xxx02",
                        word:"En seguimiento"
                    },
                    {
                        id: "xxx03",
                        word:"Reportando Pago"
                    },
                    {
                        id: "xxx04",
                        word:"Nuevo Prospecto"                        
                    }
                ]
            },
            {
                id:2,
                Lic: '04141363700',
                etiquetas:[
                    {
                        id: "xxx11",
                        word:"Reportando Falla"
                    },
                    {
                        id: "xxx12",
                        word:"En seguimiento"
                    },
                    {
                        id: "xxx13",
                        word:"Reportando Pago"
                    },
                    {
                        id: "xxx14",
                        word:"Nuevo Prospecto"                        
                    }
                ]
            }
        ]
    }


    public static CargarEtiquetas(Token: string) {
        // Aqui se debe hacer la consulta de graphql
        return this.etiquetas
    }

}