import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser'


@Pipe({
  name: 'bypass'
})
export class BypassPipe implements PipeTransform {

  constructor(
    private dom: DomSanitizer
  ) {

  }


  transform(value: any): any {
    return this.dom.bypassSecurityTrustResourceUrl(value);  
  }

}
