import { Apollo } from "apollo-angular";
import gql from "graphql-tag";

export class CamposFakeDb
{

    static apollo:Apollo
    // Si se edita alguno de los campos aqui presente sobre todo menú
    // asi sea una mayúscula puede dañar el sistema, NO TOCAR MEJOR
    public static campos = [] 

    public static async CargarCampos(Token: string) {
        // Aqui se debe hacer la consulta de graphql
        // this.campos.clientes = {"Resultado Query"}
        //return this.campos
        return this.campos 
    }
 
 
}
 