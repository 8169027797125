import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[CtrlKeys]'
})
export class CtrlKeysDirective {

  /*Captura CRLT + ?
  Jhonttan Ramirez
  */
  @Output() CtrlB : EventEmitter<boolean>=new EventEmitter();
  @Output() CtrlY : EventEmitter<boolean>=new EventEmitter();
  @Output() CtrlI : EventEmitter<boolean>=new EventEmitter();

  @HostListener('keydown.control.b') onCtrlB() {
    this.CtrlB.emit(true);
  }

  @HostListener('keydown.control.y') onCtrlY() {
    this.CtrlY.emit(true);
  }

  @HostListener('keydown.control.i') onCtrlI() {
    this.CtrlI.emit(true);
  }

}
