import { LoginSessionService } from "app/services/global/LoginSession.service"
 
export class LoginSessionFakeDb
{
    public static NameUser: string

    constructor(private _LoginSessionService: LoginSessionService )
    {
            LoginSessionFakeDb.NameUser = _LoginSessionService.GetNameUser()
    }
    // Si se edita alguno de los campos aqui presente sobre todo menú
    // asi sea una mayúscula puede dañar el sistema, NO TOCAR MEJOR
    public static LoginSession = {
            idUser: 1,
            UserName: LoginSessionFakeDb.NameUser,
            Clientes: [{
                        id: 'XXCFSFS',
                        Name: 'Cable Hogar'
                    },
                    {
                        id: 'XXCFSF2',
                        Name: 'Fibex Telecom'
                    },
                    {
                        id: 'XXCFSF2',
                        Name: 'ConexTelecom'
                    }],
            Canales: [{
                idCanal: 'CCSCSDF',
                Name: 'Cable Hogar'
            }]
      }
}