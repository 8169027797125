import { Pipe, PipeTransform } from '@angular/core';
import imageUrl from 'image-url-validator';

@Pipe({
  name: 'imageNotFound'
})
export class ImageNotFoundPipe implements PipeTransform {

  transform(url: string): string {
    if( !url || url.length < 1 ) {
      return 'assets/images/avatars/profile.jpg';
    }
    return  url;
  }
}
