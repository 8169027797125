import { Injectable, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import {GetTableV2Service} from 'app/services/global/getTableV2.service';
import { ShowDataService } from 'app/services/global/show-data.service';
import { ContactsFakeDb } from '../../../fake-db/contacts';
  // import { GetTableService } from '../e-commerce/getTable.service';
@Injectable({
  providedIn: 'root'
})
export class ContactosService  implements Resolve<any>, OnInit{

  contactos:any=[]

  constructor(  private _GetTableV2Service:GetTableV2Service,
                private showData: ShowDataService
    ) { 

  }

ngOnInit() {
  ContactsFakeDb.Counting.subscribe(res => {
    console.log('llegue')
    this.showData.showData.emit(true);
  })
}

resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
     
}

  reloadContactos(): Promise<any> {
   
      return new Promise<any>((resolve, reject) => {
      this._GetTableV2Service.TableURL = "contactos"
      this._GetTableV2Service.getData().then(DataIn => {
        this.contactos = DataIn
        resolve(this.contactos)
      })
      .catch((err) => reject(err))
    })
  }

  getContactos() : Promise<any>{
    return new Promise<any>((resolve, reject) => {
      if( this.contactos.length < 1 ) {
          this.reloadContactos().then((res) => {
            resolve(res);
          }).catch((err) => reject(err));
      } else {
        resolve(this.contactos);

      }
    });
  }

}
