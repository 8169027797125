import { HttpClient } from "@angular/common/http";
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";

import { LoginSessionService } from 'app/services/global/LoginSession.service';
import { EventEmitter } from '@angular/core';

export class RolesFakeDb {

    public static Counting: EventEmitter<Number> = new EventEmitter();

    static _LoginSessionService: LoginSessionService;

    static TokenUsuario = sessionStorage.getItem('DataLogin');
    
    constructor(
        private apollo: Apollo,
    ) {
    }
    // Si se edita alguno de los campos aqui presente sobre todo menú
    // asi sea una mayúscula puede dañar el sistema, NO TOCAR MEJOR

    public static usersRoles = [] 

    public static roles = [] 

    public static permisos = [] 

    public static CargarUserRoles(apollo: Apollo, Token: string, _httpClient: HttpClient) {
        let data = apollo.watchQuery<any>({
            query: UsersRoles,
            variables: {
                token: Token
            }
        })
            .valueChanges
            .subscribe((result) => {
                let DataUserRoles = result.data.UsersRoles //Guardo la data obtenida

                _httpClient.post('api/roles-users/1', DataUserRoles)
                .subscribe((response: any) => { }, (Error) => console.error('roles-users' + Error));

            });
        return this.usersRoles
    }

    public static CargarRoles(apollo: Apollo, Token: string, _httpClient: HttpClient) {
        let data = apollo.watchQuery<any>({
            query: CRoles,
            variables: {
                token: Token
            }
        })
            .valueChanges
            .subscribe((result) => {
                let DataRoles = result.data.Roles //Guardo la data obtenida
                const DataR={
                    item: { id: 1 },
                    idEmpresa: DataRoles[0].IdEmpresa,
                    title: 'Roles',
                    TitleColumShow: ['Rol', 'Descripción', 'Acción'],
                    columns: ['Rol', 'Descripción', 'Acción'],
                    type: ['string', 'string', 'menu'],
                    menu: ["Editar/edit", "Eliminar/delete"],
                    'rows': DataRoles
                }
                _httpClient.post('api/roles/1', DataR)
                .subscribe((response: any) => { }, (Error) => console.error('roles' + Error));
                try {
                    this.Counting.emit(DataRoles.length)
                } catch (error) {
                    this.Counting.emit(0)
                }


                // Voy a cargar los permisos.
                    let dataPermiso = apollo.watchQuery<any>({
                    query: CPermisos,
                    variables: {
                        token: Token
                    } })
                    .valueChanges
                    .subscribe((result2) => {
                        let DataPermisos = result2.data.Permisos //Guardo la data obtenida


                        DataPermisos.forEach(element => {

                            const DataP = {
                                item: { id: 1 },
                                id: element.id,
                                permiso: element.permiso,
                                url:element.url
                            }


                            _httpClient.post('api/roles-permisos/', DataP)
                            .subscribe((response: any) => { }, (Error) => console.error('roles-permisos' + Error));
                        });
                       // _httpClient.post('api/roles-permisos/', DataP)
                       // .subscribe((response: any) => { }, (Error) => console.error('roles-permisos' + Error));
                    })

                //Voy a cargar los usuario
                this.CargarUserRoles(apollo, Token, _httpClient);
            });
        return this.roles
    }

    public static CargarPermisos() {
        //  graphql
        return this.permisos
    }

    public static CreateRol(apollo: Apollo, Data: any, _httpClient: HttpClient) {

        const DataUsers={
            IdUsers:Data.Usuarios,
            IdRol:Data.IdRol,
            Permisos:Data.Idpermisos,
        }

        apollo.mutate({
            mutation: CreateRoles,
            variables: {
                Token:this.TokenUsuario,
                IdRol:Data.IdRol,
                IdEmpresa:Data.IdEmpresa,
                NameRol:Data.NameRol,
                Idpermisos:Data.Idpermisos,
                Descripcion:Data.Descripcion
            }
          }).subscribe(({ data }) => {
            
            if(Data.Usuarios){
                this.UpdateUserRol(apollo, this.TokenUsuario, DataUsers, _httpClient)
            }

          },(error) => {
            console.error('error In Create Roles', error);
          });
    }

    public static UpdateRol(apollo: Apollo, Data: any, _httpClient: HttpClient) {
        
        const DataUsers={
            IdUsers:Data.Usuarios,
            IdRol:Data.IdRol,
            Permisos:Data.Idpermisos,
        }

        apollo.mutate({
            mutation: UpdateRoles,
            variables: {
                Token:this.TokenUsuario,
                IdRol:Data.IdRol,
                IdEmpresa:Data.IdEmpresa,
                NameRol:Data.NameRol,
                Idpermisos:Data.Idpermisos,
                Descripcion:Data.Descripcion
            }
          }).subscribe(({ data }) => {
            if(Data.Usuarios){
                this.UpdateUserRol(apollo, this.TokenUsuario, DataUsers, _httpClient)
            }
          },(error) => {
            console.error('Error In Update Roles', error);
          });
    }

    public UpdatePermisos(apollo: Apollo, Token:String,  Data: any, _httpClient: HttpClient) {
        apollo.mutate({
            mutation: UpdatePermisos,
            variables: {
                Token:Token,
                id:Data.id,
                permiso:Data.permiso,
                url:Data.url
            }
          }).subscribe(({ data }) => {

          },(error) => {
            console.error('Error In Update Permisos', error);
          });
    }

    public static UpdateUserRol(apollo: Apollo, token:any, DataE:any, _httpClient: HttpClient) {
        /* Estructura De Data
        Data:{
            IdUsers:["3053","3054","3055"]
            IdRol:"2"
            Permisos:["4","9","11"]
        }*/

        apollo.mutate({
            mutation: UpdateRolUsers,
            variables: {
                Token:token,
                IdUsers:DataE.IdUsers,
                IdRol:DataE.IdRol,
                Permisos:DataE.Permisos
            }
          }).subscribe(({ data }) => {
            
          },(error) => {
            console.error('Error In Update User Rol', error);
          });

    }

}

export const UsersRoles = gql`
  query UsersRoles($token:String) {
    UsersRoles(token:$token) {
    idEmpresa
    users{
      iduser
      name
      rolpermit
      idRol
    }
  }
  }
`;

export const CRoles = gql`
  query Roles($token:String) {
    Roles(token:$token) {
        id:IdRol
        IdEmpresa
        Rol:NameRol
        Idpermisos
        Descripcion
    }
  }`

export const CPermisos = gql`
  query Permisos($token:String) {
    Permisos(token:$token){
        id
        permiso
        url
    }
  }`

export const CreateRoles = gql`
mutation CreateRoles($Token:String,$IdRol:String,$IdEmpresa:String,$NameRol:String,$Idpermisos:[String],$Descripcion:String){
    CreateRoles(
        token:$Token
        DRoles:{
            IdRol:$IdRol
            IdEmpresa:$IdEmpresa
            NameRol:$NameRol
            Idpermisos:$Idpermisos
            Descripcion:$Descripcion
        }
    ){
        IdRol
    }
}
`

export const CreatePermiso = gql`
mutation CreatePermiso($Token:String,$id:String,$permiso:String,$url:String){
    CreatePermiso(
        token:$Token,
        Data:{
            id:$id
            permiso:$permiso
            url:$url
        }
    ){
        id
        permiso
        url
    }
}
`

export const UpdateRoles = gql`
mutation UpdateRol($Token:String,$IdRol:String,$IdEmpresa:String,$NameRol:String,$Idpermisos:[String],$Descripcion:String){
    UpdateRol(
        token:$Token
        Data:{
            IdRol:$IdRol
            IdEmpresa:$IdEmpresa
            NameRol:$NameRol
            Idpermisos:$Idpermisos
            Descripcion:$Descripcion
        }
    ){
        IdRol
        NameRol
    }
}
`

export const UpdatePermisos = gql`
mutation UpdatePermiso($Token:String,$id:String,$permiso:String,$url:String){
    UpdatePermiso(
        token:$Token,
        Data:{
            id:$id
            permiso:$permiso
            url:$url
            }
    ){
        id
        permiso
        url
    }
}
`

/*export const DeleteRoles = gql`
mutation DeleteRol($Token:String,$IdRol:String){
    DeleteRol(
        token:$Token
        IdRol:$IdRol
    ){
        IdRol
    }
}
`*/

export const UpdateRolUsers = gql`
mutation UpdateRolUser($Token:String,$IdUsers:[String],$IdRol:String,$Permisos:[String]){
    UpdateRolUser(token:$Token
    Data:{
    IdUsers:$IdUsers
    IdRol:$IdRol
    Permisos:$Permisos
  })
    }
`
