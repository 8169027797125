import { environment } from "environments/environment";

export class QuickPanelFakeDb
{
    public static notes = [
        {
            'title' : 'Version / Servidor',
            'detail':  environment.version +'/ Conectado'
        },
        {
            'title' : 'Fecha de Corte:',
            'detail': '25/01/2020'
        }
    ];

    public static events = [
        {
            'title' : 'Clientes por Atender',
            'detail': '(25)'
        },
        {
            'title' : 'Casos Cerrados',
            'detail': '(8)'
        },
        {
            'title' : 'Cola de Mensajes',
            'detail': '(250)'
        },
        {
            'title' : 'Operadores On-Line',
            'detail': '(4)'
        }
    ];
}
